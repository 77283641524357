// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Button, Card, Container, Spinner } from "react-bootstrap";

// function JobStatusPage() {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [email, setEmail] = useState(null); // Get this from the URL after login
//   const [statusData, setStatusData] = useState([]);
//   const [loading, setLoading] = useState(false);

//   // Extract token and email from URL
//   useEffect(() => {
//     const hash = window.location.hash;
//     const queryParams = new URLSearchParams(hash.substring(1));
//     const token = queryParams.get("id_token");
//     const expiresIn = queryParams.get("expires_in");
//     const emailFromToken = "pragathign16@gmail.com"; // Extract email from token using a method
//     if (token && expiresIn) {
//       setIsLoggedIn(true);
//       setEmail(emailFromToken); // For demonstration, use a dummy email
//     }
//   }, []);

//   const handleLogin = () => {
//     window.location.href =
//     //   "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://prathik.d2m2wtcivnya67.amplifyapp.com/careers/view-status";
//            "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/careers/view-status";

//   };

//   // Fetch job status after login
//   useEffect(() => {
//     if (email) {
//       setLoading(true);
//       const apiUrl = `https://34msli0en0.execute-api.ap-south-1.amazonaws.com/prod/getSpecificdata?email=${email}&type=get`;
//       axios
//         .get(apiUrl, {
//           headers: {
//             "x-api-key": "abTLSGqgK19iPy8i5kI0Y3DK9vgoAVoD4qiK6nmw",
//           },
//         })
//         .then((response) => {
//           setStatusData(response.data["body-json"].body);
//           setLoading(false);
//         })
//         .catch((error) => {
//           console.error("Error fetching job status", error);
//           setLoading(false);
//         });
//     }
//   }, [email]);

//   return (
//     <Container style={{ marginTop: "100px" }}>
//       {!isLoggedIn ? (
//         <Card style={{ padding: "20px" }}>
//           <h3>Please log in to view your job application status</h3>
//           <Button variant="primary" onClick={handleLogin}>
//             Login
//           </Button>
//         </Card>
//       ) : (
//         <Card style={{ padding: "20px" }}>
//           <h3>Job Application Status</h3>
//           {loading ? (
//             <Spinner animation="border" />
//           ) : (
//             statusData.map((application, index) => (
//               <div key={index}>
//                 <p>
//                   <strong>Role:</strong> {application.role}
//                 </p>
//                 <p>
//                   <strong>Status:</strong> {application.status}
//                 </p>
//                 <p>
//                   <strong>Date Applied:</strong> {new Date(application.date).toLocaleDateString()}
//                 </p>
//                 <hr />
//               </div>
//             ))
//           )}
//         </Card>
//       )}
//     </Container>
//   );
// }

// export default JobStatusPage;

import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { Button, Card, Container, Spinner, Row, Col } from "react-bootstrap";
import "./JobStatusPage.css"; // Optional: create a separate CSS file for custom styles

function JobStatusPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState(null);
  const [statusData, setStatusData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    const queryParams = new URLSearchParams(hash.substring(1));
    const idToken = queryParams.get("id_token");

    if (idToken) {
      const decodedToken = jwtDecode(idToken);
      setEmail(decodedToken.email);
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    window.location.href =
      "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://dhruthzucitech.solutions/careers/view-status";
      // "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/careers/view-status";
  };

  useEffect(() => {
    if (email) {
      setLoading(true);
      const apiUrl = `https://34msli0en0.execute-api.ap-south-1.amazonaws.com/prod/getSpecificdata?email=${email}&type=get`;
      axios
        .get(apiUrl, {
          headers: {
            "x-api-key": "abTLSGqgK19iPy8i5kI0Y3DK9vgoAVoD4qiK6nmw",
          },
        })
        .then((response) => {
          setStatusData(response.data["body-json"].body);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching job status", error);
          setLoading(false);
        });
    }
  }, [email]);

  return (
    <Container style={{ marginTop: "150px" }}>
      {!isLoggedIn ? (
        <Card
          style={{
            padding: "15px",
            textAlign: "center",
            maxWidth: "400px",
            margin: "0 auto",
          }}
        >
          <h5>Please log in to view your job application status</h5>
          <Button variant="primary" onClick={handleLogin}>
            Login
          </Button>
        </Card>
      ) : (
        <>
          <h3 style={{ marginBottom: "30px", textAlign: "center" }}>
            Job Application Status
          </h3>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <Row xs={1} md={2} lg={3} className="g-4">
              {statusData.map((application, index) => (
                <Col key={index}>
                  <Card
                    className="application-card shadow-lg"
                    style={{ borderRadius: "15px", overflow: "hidden" }}
                  >
                    <Card.Body>
                      <Card.Title
                        className="text-primary"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {application.role}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        Status:{" "}
                        <span className="text-success">
                          {application.status}
                        </span>
                      </Card.Subtitle>
                      <Card.Text>
                        <strong>Name:</strong> {application.name}
                      </Card.Text>
                      <Card.Text>
                        <strong>Date Applied:</strong>{" "}
                        {new Date(application.date).toLocaleDateString()}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
      <div style={{ marginTop: "50px" }}></div>
    </Container>
  );
}

export default JobStatusPage;