export const PASSWORDLESS_HERO = require("../../Assets/images/passwordless_hero.png");
export const PASSWORDLESS_CUSTOMER = require("../../Assets/images/CustomerEngagement.png");
export const PASSWORDLESS1 = require("../../Assets/images/passwordless_1.png");
export const DBLUE_RECTANGLE = require("../../Assets/images/Rectangle1.png");
export const LBLUE_RECTANGLE = require("../../Assets/images/Rectangle2.png");
export const SQUARE1 = require("../../Assets/images/square1.png");
export const SQUARE2 = require("../../Assets/images/square2.png");
export const SQUARE3 = require("../../Assets/images/square3.png");
export const SQUARE4 = require("../../Assets/images/square4.png");
export const PWD_ARCHI = require("../../Assets/images/passwordless_architecture.png");


export const PASSWORDLESS2 = require("../../Assets/images/passwordless2.png");
export const PASSWORDLESS3 = require("../../Assets/images/passwordless3.png");
export const PASSWORDLESS4 = require("../../Assets/images/passwordless4.png");
export const PASSWORDLESS5 = require("../../Assets/images/passwordless5.png");

export const solutionsData = {
  heroSection: {
    title: "Welcome! to Seamless Identity and Access Management",
    backgroundImage: PASSWORDLESS_HERO,
  },
  introductionSection: {
    title:
      "Unlock Secure, Frictionless Access at Scale with Our Passwordless Authentication Solution",
    description:
      "In today's digitally driven world, user security and experience are paramount. Traditional password-based systems often introduce complexity and security vulnerabilities. At Dhruthzuci Tech, we've harnessed the power of passwordless authentication to provide you with a secure, frictionless, and scalable solution.",
    image: PASSWORDLESS1,
  },
  serviceDetailsSection: {
    title: "Service Details",
    description:
      "A Closer Look In this cutting-edge solution, we introduce a passwordless authentication system powered by Amazon Cognito, Lambda functions, and Amazon SNS. Here's how it works:",
    features: [
      {
        title: "Amazon Cognito",
        description: " A scalable and secure platform for user authentication.",
      },
      {
        title: "Lambda Functions",
        description: "Custom authentication flows tailored to your needs.",
      },
      {
        title: "Amazon SNS",
        description:
          " Seamless delivery of one-time login codes (OTP) for a secure and user-friendly login experience.",
      },
    ],
  },
  useCasesSection: {
    title: "Use Cases",
    description: "Our solution caters to a variety of use cases, including:",
    useCases: [
      {
        title: "Secure User Authentication",
        description:
          "Elevate your security measures and enhance the user experience.",
      },
      {
        title: "Custom Authentication Flows",
        description:
          "Tailor authentication processes to meet the unique requirements of your application.",
      },
    ],
    backgroundImage: DBLUE_RECTANGLE,
  },
  awsValuePropositionSection: {
    title: "AWS Value Proposition",
    description:
      "Our passwordless authentication solution offers a host of advantages:",
    features: [
      { heading: "Heading 1", text: "Text 1", image: SQUARE1 },
      { heading: "Heading 2", text: "Text 2", image: SQUARE2 },
      { heading: "Heading 3", text: "Text 3", image: SQUARE3 },
      { heading: "Heading 4", text: "Text 4", image: SQUARE4 },
    ],
    backgroundImage: LBLUE_RECTANGLE,
  },
  targetCustomersSection: {
    title: "Who benefits from this solution?",
    description:
      "Our solution is a perfect fit for a wide array of organizations, including:",
    targetCustomers: [
      {
        image: PASSWORDLESS2,
        text: [
          {
            title: "Startups and Small Businesses:",
            description:
              "Secure and user-friendly authentication for smaller enterprises.",
          },
        ],
      },
      {
              text: [
          {
            title: "Enterprises:",
            description: "Robust security measures for large organizations.",
          },
        ],
        image: PASSWORDLESS3,

      },
      {
        image: PASSWORDLESS4,
        text: [
          {
            title: "User-Centric Applications:",
            description:
              "Ideal for apps where user experience and security are paramount.",
          },
        ],
      },
      {
        image: PASSWORDLESS5,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
    ],
  },
  customerEngagementSection: {
    title: "Customer Engagement",
    description:
      "To learn more and implement this solution, you can engage with us through various channels:",
    features: [
      {
        title: "Our Sales Representatives",
        description: "Receive personalized guidance from our dedicated team.",
      },
      {
        title: "AWS Marketplace",
        description:
          "Find our passwordless authentication solution on the AWS marketplace.",
      },
      {
        title: "Our Website and Documentation",
        description:
          "Access resources, documentation, and best practice guides on Dhruthzuci Tech website.",
      },
    ],

    backgroundImage: DBLUE_RECTANGLE,
  },
  deliveryMechanismsSection: {
    title: "Delivery Mechanisms",
    description: "Choose the approach that suits your needs:",
    deliveryApproaches: [
      {
        title: "Self-Service",
        description:
          "Follow our comprehensive documentation and best practices for a DIY setup.",
      },
      {
        title: "Engage with Us",
        description:
          "Collaborate with our professionals for hands-on assistance, customization, and solution implementation.",
      },
    ],
  },
  getStartedSection: {
    title: "Get Started Today",
    line1:
      "Elevate your identity and access management to new heights. Say goodbye to traditional passwords and hello to a secure, frictionless, and scalable solution. Reach out to us to learn more and embark on a journey towards a more secure, user-friendly future ",
    line2: 
      "to learn more and embark on a journey towards a more secure, user-friendly future.",
    contactUsLink: "/contactus",
  },
};