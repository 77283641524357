
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
import {
  accordionData1,
  accordionData2,
  accordionData3,
  accordionData4,
  accordionData5,
  accordionData6,
  accordionData7,
  accordionData8,
} from "./config";

export default function Table() {
  const renderAccordionItems = (accordionData) => {
    return accordionData.map((item, index) => (
      <Col key={index}  >
        <Accordion
          // defaultActiveKey="0"
          flush
          alwaysClose
          style={{
            fontFamily: "impacted",
            background: BACKGROUNDCOLOR,
           
            marginBottom: "15px", 
          }}
        >
          <Accordion.Item
            style={{
              fontFamily: "impacted",
              background: BACKGROUNDCOLOR,
            }}
            eventKey={index.toString()}
          >
            <Accordion.Header
              style={{
                fontFamily: "impacted",
                background: BACKGROUNDCOLOR,
              }}
            >
              {item.title}
            </Accordion.Header>
            <Accordion.Body
              style={{
                fontFamily: "impacted",
                background: BACKGROUNDCOLOR,
              }}
            >
              {item.content}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>{" "}
      </Col>
    ));
  };

  return (
    <div style={{ alignItems: "center", justifyContent: "center",backgroundColor:"lightgrey" }}>
      <Container style={{ alignItems: "center", justifyContent: "center"}}>
        <Row>{renderAccordionItems(accordionData1)}</Row>
        <br />
        <Row>{renderAccordionItems(accordionData2)}</Row>
        <br />
        <Row>{renderAccordionItems(accordionData3)}</Row>
        <br />
        <Row>{renderAccordionItems(accordionData4)}</Row>
        <br />
        <Row>{renderAccordionItems(accordionData5)}</Row>
        <br />
        <Row>{renderAccordionItems(accordionData6)}</Row>
        <br />
        <Row>{renderAccordionItems(accordionData7)}</Row>
        <br />
        <Row>{renderAccordionItems(accordionData8)}</Row>
      </Container>
    </div>
  );
}
