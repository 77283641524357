import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import { GROWTHAVEN_IMG, PRESIDIO_IMG, GREENPAGES_IMG } from "../../Assets";

const Clients = () => {
  const clientsData = [
    "Client A",
    "Client B",
    "Client C",
    "Client D",
    "Client E",
  ];

  return (
    <section className="clients-section">
    <Container>
      <div className="item" >
        <p id="gallery-text">
          <b style={{ fontSize: '25px' }}>Our Customers:  </b><br /><br />
          <Row style={{ justifyContent:"space-evenly" }}>
            <Col xs={8} md={2} className="text-center">
            <img src={PRESIDIO_IMG} width="105%" height="150px" style={{ paddingRight: '1%', marginRight: '10%' }} id="awsimg" alt="Presidio" />
            </Col>
            <Col xs={8} md={2} className="text-center">
              <img src={GREENPAGES_IMG} width="105%" height="150px" style={{ paddingRight: '1%', marginRight: '10%' }} id="awsimg" alt="GreenPages" />
            </Col>
            <Col xs={8} md={2} className="text-center">
            <img src={GROWTHAVEN_IMG} width="105%" height="150px" style={{ paddingRight: '1%', marginRight: '10%' }} id="awsimg" alt="Growth Heaven" />
            </Col>
          </Row>
        </p>
      </div>
    </Container>
    </section>
  );
};

export default Clients;
