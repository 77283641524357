import React, { useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Button,
  Image,
} from "react-bootstrap";
import Clients from "../Clients";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  IMG1,
  backgroundImage,
  LOGO,
  linkedin,
  whatsapp,
  call,
  mail,
} from "../../../Assets";
import AboutSection from "../../helpers/Aboutsection";
import Portfolio from "../../Testimonials/Portfolio";
import HomeAccordian from "./HomeTabs";
import Hometabs from "./HomeTabs";
import NavBar from "../../Navbar/Navbar";

const Home = () => {
  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.2, duration: 1 } },
  };
  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
  };
  const buttonVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { delay: 0.3, duration: 1 } },
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const aboutContainerRef = useRef(null);

  const handleGetStartedClick = () => {
    const aboutContainerOffset = aboutContainerRef.current.offsetTop - 120; // Subtract the height of your navigation bar
    window.scrollTo({
      top: aboutContainerOffset,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <NavBar />
      <section
        className="hero-section"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "100vh",
          display: "flex",
          flexDirection: "column", // Stack content vertically
          alignItems: "center", // Center content horizontally
          justifyContent: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
            color: "white",
          }}
        >
          <Container>
            <motion.h1
              className="text-center mt-4"
              initial="hidden"
              animate="visible"
              variants={headerVariants}
              ref={ref}
              style={{
                fontFamily: "impact",
                fontSize: "2.5rem",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                color: "#FFFFFF",
              }}
            >
              Welcome to Dhruthzuci Tech Solutions
            </motion.h1>
            <motion.p
              className="text-center mt-3"
              initial="hidden"
              animate="visible"
              variants={contentVariants}
              style={{
                fontFamily: "impacted",
                fontSize: "1.2rem",
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                color: "#FFFFFF",
              }}
            >
              Digital Transformation Journey Begins With Dhruthzuci Tech
            </motion.p>
            <Button
              onClick={handleGetStartedClick}
              style={{
                // backgroundColor: "#FFA500",
                background: "linear-gradient(to top, #387FD3, #7B3EAC)",
                color: "white",
                border: "none",
                padding: "10px 20px",
                borderRadius: "15px",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                cursor: "pointer",
                fontSize: "1.2rem",
                marginTop: "20px",
              }}
            >
              Get Started
            </Button>
          </Container>
        </div>
      </section>

      <div style={{ marginTop: "120px" }}>
        <Container ref={aboutContainerRef} id="aboutContainer">
          <Hometabs />
          <AboutSection />
        </Container>
        {/* <Clients /> */}
      </div>
    
    </div>
  );
};

export default Home;