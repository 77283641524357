import React, { useState } from "react";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { SUCESS_IMG } from "../../Assets";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

const FormCom = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    message: "",
    subject: "Select Services",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const subjects = [
    "Select Services",
    "Product Support",
    "Cloud Migration",
    "Cost Optimization",
    "Web Development",
    "Mobile Development",
    "Modern Workspace Management",
    "Other",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (formData.from_name.trim() === "") {
      setErrors({ ...errors, from_name: "Please enter your name" });
    } else if (formData.from_email.trim() === "") {
      setErrors({ ...errors, from_email: "Please enter your email" });
    } else if (formData.subject === "Select Services") {
      setErrors({ ...errors, subject: "Please select a subject" });
    } else {
      setErrors({});
      setIsSubmitting(true);

      emailjs
        .sendForm(
          "service_5wnc29d",
          "template_7nxm4b7",
          e.target,
          "wh96Lfe5CzF3yBm7U"
        )
        .then((result) => {
          console.log(result.text);
          setIsSubmitting(false);
          setIsSubmitted(true);
        })
        .catch((error) => {
          console.log(error.text);
          setIsSubmitting(false);
          setIsSubmitted(false);
        });
    }
  };

  return (

      <Container>
        <Row className="justify-content-center">
          <Col  md={8} sm={12}>
            {isSubmitted ? (
              <div>
                <Alert variant="success">
                  Successfully submitted! Our team will get back to you.
                </Alert>
                <img
                  className="img-fluid mb-4"
                  src={SUCESS_IMG}
                  alt="Success"
                  style={{ maxWidth: "300px" }}
                />
              </div>
            ) : (
              <Form onSubmit={sendEmail} className="p-4 shadow rounded bg-white">
                {/* Name Field */}
                <Form.Group controlId="from_name" className="mb-4">
                  <Form.Label className="fw-bold">Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="from_name"
                    placeholder="Enter your name"
                    isInvalid={!!errors.from_name}
                    onChange={handleInputChange}
                    className="rounded-pill"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.from_name}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Email Field */}
                <Form.Group controlId="from_email" className="mb-4">
                  <Form.Label className="fw-bold">Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="from_email"
                    placeholder="Enter your email"
                    isInvalid={!!errors.from_email}
                    onChange={handleInputChange}
                    className="rounded-pill"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.from_email}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Services Field */}
                <Form.Group controlId="subject" className="mb-4">
                  <Form.Label className="fw-bold">Services</Form.Label>
                  <Form.Control
                    as="select"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    isInvalid={!!errors.subject}
                    className="rounded-pill"
                  >
                    <option value="">Select a service</option>
                    {subjects.map((subject) => (
                      <option key={subject} value={subject}>
                        {subject}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.subject}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Message Field */}
                <Form.Group controlId="message" className="mb-4">
                  <Form.Label className="fw-bold">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="message"
                    placeholder="Hi there, I would like to..."
                    className="rounded"
                  />
                </Form.Group>

                {/* Submit Button */}
                <Button
                  size="lg"
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary w-100 rounded-pill"
                  style={{
                    backgroundColor: BACKGROUNDCOLOR,
                    borderColor: "#F2D7D5",
                    color: "#17202A",
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Send"}
                </Button>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    
  );
};

export default FormCom;
