import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  CLOUDLANDING1,
  CLOUDLANDING2,
  CLOUDLANDING3,
  CLOUDLANDING4,
  CLOUDLANDING5,
  CLOUDLANDING6,
  CloudlandingData,
} from "./config";
import "./CloudLanding.css"

export default function CloudLanding() {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };

  return (
    <div
      style={{
        background: "#fff",
        overflowX: "hidden",
        marginTop: "78px",
      }}
    >
      
      <section
        className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "50px",
            marginBottom: "50px",
            marginTop: "450px",
            color: "#fff",
          }}
        >
          {CloudlandingData.heroSection.title}
        </h1>
      </section>

      <Container>
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "35px",
            marginBottom: "50px",
            marginTop: "20px",
            fontFamily: "impact",
          }}
        >
          {CloudlandingData.introductionSection.title}
        </h1>
        <Row className="align-items-center justify-content-center">
          <Col>
            <p style={{ textAlign: "center", fontFamily: "impacted" }}>
              {CloudlandingData.introductionSection.description}
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>{CloudlandingData.targetCustomersSection.title}</b>
            </h1>
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={CLOUDLANDING1} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Explore the benefits of AWS Control Tower
Discover how AWS Control Tower can revolutionise your AWS landscape. Bid farewell to the complexities of account setup and configuration, and say hello to a centralised, well-architected, and ultra-secure multi-account AWS environment. Whether you're a dynamic startup or a well-established enterprise, AWS Control Tower is designed with you in mind.
              </p>
            </Col>
          </Row>

          <Col md={12} style={centerAlignedText}>
          <h1><b>Your Path to Success</b></h1>  
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Embark on a guided journey with AWS Control Tower
take a step-by-step journey through the AWS Control Tower implementation project. Let us lead you through every phase, from initial planning to execution and handover. Explore our comprehensive project timeline and witness how we empower your AWS environment, making it a well-structured and fully compliant powerhouse.
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={CLOUDLANDING2} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>

          <Col md={12} style={centerAlignedText}>
            <h1><b>Technical Excellence</b></h1>
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={CLOUDLANDING3} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" ,textAlign:"justify"}}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Delve into the technical marvels of AWS Control Tower
learn the intricacies of setting up AWS Control Tower using CloudFormation. Unearth the magic of CloudFormation templates and understand the pivotal role of AWS Single Sign-On (SSO) and Multi-Factor Authentication (MFA) in fortifying your AWS environment.

              </p>
            </Col>
          </Row>

          <Col md={12} style={centerAlignedText}>
            <h1><b>Compliance and Security</b></h1>
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Uncompromising security and compliance with AWS Control Tower. Security and compliance are paramount and AWS Control Tower takes it seriously. Learn how compliance checks are executed using AWS Config rules and how continuous monitoring and cost optimisation are achieved through AWS trusted advisor and AWS CloudWatch.
              </p>
            </Col>

            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={CLOUDLANDING4} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>
          <Col md={12} style={centerAlignedText}>
            <h1><b>Tangible Outcomes</b></h1>
          </Col>
          <Row className="align-items-center justify-content-center" style={{marginBottom:"20px"}}>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={CLOUDLANDING5} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Witness tangible results of AWS Control Tower Implementation. Reap the benefits of AWS Landing Zones, fortified root user password configurations and centralised user management with AWS SSO. Expect to receive Cloud Formation templates, comprehensive documentation, compliance reports and robust security configurations.
              </p>
            </Col>
          </Row>
        </Row>
      </Container>

      <section
        // className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.customerEngagementSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "30vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Container>
          <Col
            // md={9}
            style={{
              color: "white",
              fontFamily: "impacted",
              textAlign: "center",
            }}
          >
            <h1 className="large-font" style={{fontSize:"26px"}}>
              <b>{CloudlandingData.customerEngagementSection.title}</b>
            </h1>
            <br/>
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                marginTop:"15px"
              }}
            >
              <b>{CloudlandingData.customerEngagementSection.description}</b>
            </p>
          </Col>
        </Container>
      </section>

      <Container
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          fontFamily: "impacted",
        }}
      >
        <Row style={{marginBottom:"50px"}}>
          <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{CloudlandingData.deliveryMechanismsSection.title}</b>
          </h1>
        </Col>
        <Col>
          <p> {CloudlandingData.deliveryMechanismsSection.description}</p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px"
            }}
          >
            <img src={CLOUDLANDING6} alt="" />
          </div>
        </Col></Row>
        

        <Row style={{marginBottom:"50px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{CloudlandingData.UnlockPotentialSection.title}</b>
            </h1>
          </Col>
          <Col>
            <p> {CloudlandingData.UnlockPotentialSection.description}</p>
          </Col>
        </Row>

        <Row style={{marginBottom:"50px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{CloudlandingData.WelcomeToFutureSection.title}</b>
            </h1>
          </Col>
          <Col>
            <p> {CloudlandingData.WelcomeToFutureSection.description}</p>
          </Col>
        </Row>

<Row style={{marginBottom:"50px"}}>
  <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{CloudlandingData.getStartedSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12}>
          <a
            style={{ fontFamily: "impacted", fontSize: "23px" }}
            href={CloudlandingData.getStartedSection.contactUsLink}
          >
            <b>Contact us</b>
          </a>{" "}
          {CloudlandingData.getStartedSection.description}
        </Col>
  </Row>

      </Container>
    </div>
  );
}