export const PASSWORDLESS_HERO = require("../../../Assets/images/passwordless_hero.png");
export const PASSWORDLESS_CUSTOMER = require("../../../Assets/images/CustomerEngagement.png");
export const PASSWORDLESS1 = require("../../../Assets/images/passwordless_1.png");
export const DBLUE_RECTANGLE = require("../../../Assets/images/Rectangle1.png");
export const LBLUE_RECTANGLE = require("../../../Assets/images/Rectangle2.png");
export const SQUARE1 = require("../../../Assets/images/square1.png");
export const SQUARE2 = require("../../../Assets/images/square2.png");
export const SQUARE3 = require("../../../Assets/images/square3.png");
export const SQUARE4 = require("../../../Assets/images/square4.png");
export const KEYFEATURES = require("../../../Assets/images/astuto/key-features.png");
export const USERCASESTUDY = require("../../../Assets/images/astuto/user-case-study.png");
export const PWD_ARCHI = require("../../../Assets/images/passwordless_architecture.png");


export const PASSWORDLESS2 = require("../../../Assets/images/passwordless2.png");
export const PASSWORDLESS3 = require("../../../Assets/images/passwordless3.png");
export const PASSWORDLESS4 = require("../../../Assets/images/passwordless4.png");
export const PASSWORDLESS5 = require("../../../Assets/images/passwordless5.png");

export const solutionsData = {
  heroSection: {
    title: "Welcome to VittaOps: Your Ultimate FinOps Solution ",
    backgroundImage: PASSWORDLESS_HERO,
  },
  introductionSection: {
    title:
      "Unveiling VittaOps: A FinOps Marvel ",
    description:
      "In the realm of cloud computing, leaks in resources drain budgets silently, and careless spending lurks in silos. Complexity breeds surprise bills, while vulnerabilities compound the challenges. As cloud spend priority overtakes security, the need for effective financial operations management becomes paramount.",
    image: PASSWORDLESS1,
  },
  serviceDetailsSection: {
    title: "Introducing FinOps: A Collaborative Approach ",
    description:
      "FinOps stands as the beacon guiding businesses through the fog of cloud spending. It champions collaboration in managing cloud financial operations for optimal business value. From awareness to continuous improvement, FinOps embodies a holistic strategy to rein in cloud costs.",
    title2: "The VittaOps Advantage",
    description2:
      "VittaOps emerges as the ultimate ally in the battle against cloud cost inefficiencies. With its advanced features and intuitive interface, VittaOps empowers businesses to navigate the complexities of cloud spending with ease.",
  },
  useCasesSection: {
    title: "Key Features of VittaOps",
    useCases: [
      {
        title: "Transparent Insights",
        description:
          "VittaOps lays bare the intricacies of cloud spending, providing transparent insights into resource pools across multi-cloud environments.",
      },
      {
        title: "AI/ML Integration",
        description:
          " Leveraging cutting-edge AI and ML technologies, VittaOps sifts through data, surfacing actionable insights and real-world savings.",
      },
      {
        title: "Customizable Solutions",
        description:
          " From blind budgets to budget bliss, VittaOps offers highly customizable solutions tailored to meet the diverse needs of businesses.",
      },
    ],
    image: KEYFEATURES,
    backgroundImage: DBLUE_RECTANGLE,
  },
  userCaseStudy: {
    title: "User Case Study: From Bleeding Costs to Budget Bliss",
    description:
      "Astuto achieved a remarkable 25% reduction in cloud spending within just 3 months of implementing VittaOps.",

      useCases: [
        {
          title: "Quantifiable Cost Savings",
          description:
            "Astuto achieved a remarkable 25% reduction in cloud spending within just 3 months of implementing VittaOps.",
        },
        {
          title: "Improved Resource Utilization",
          description:
            "With VittaOps, Astuto boosted resource utilization by 18%, ensuring every cloud dollar was maximized.",
        },
        {
          title: "Empowered Healthcare Delivery",
          description:
            "By freeing up budget for patient care and innovation, VittaOps empowered Astuto to focus on what truly matters: healthcare delivery.",
        },
      ],
    image: USERCASESTUDY,
    backgroundImage: LBLUE_RECTANGLE,
  },
  targetCustomersSection: {
    title: "VittaOps Setup for Astuto Demo Environment",
    description:
      "For Astuto Solutions, VittaOps served as the catalyst for cloud cost optimization, VM rightsizing, and PaaS instrumentation. With VittaOps's seamless deployment and robust functionality, Astuto unlocked new realms of efficiency and agility in their cloud operations.",
    targetCustomers: [
      {
        image: PASSWORDLESS2,
        text: [
          {
            title: "Join the VittaOps Revolution",
            description:
              "Secure and user-friendly authentication for smaller enterprises.",
          },
        ],
      },
      {
              text: [
          {
            title: "Enterprises:",
            description: "Robust security measures for large organizations.",
          },
        ],
        image: PASSWORDLESS3,

      },
      {
        image: PASSWORDLESS4,
        text: [
          {
            title: "User-Centric Applications:",
            description:
              "Ideal for apps where user experience and security are paramount.",
          },
        ],
      },
      {
        image: PASSWORDLESS5,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
    ],
  },
  customerEngagementSection: {
    title: "Customer Engagement",
    description:
      "To learn more and implement this solution, you can engage with us through various channels:",
    features: [
      {
        title: "Our Sales Representatives",
        description: "Receive personalized guidance from our dedicated team.",
      },
      {
        title: "AWS Marketplace",
        description:
          "Find our passwordless authentication solution on the AWS marketplace.",
      },
      {
        title: "Our Website and Documentation",
        description:
          "Access resources, documentation, and best practice guides on Dhruthzuci Tech website.",
      },
    ],

    backgroundImage: DBLUE_RECTANGLE,
  },
  deliveryMechanismsSection: {
    title: "Delivery Mechanisms",
    description: "Choose the approach that suits your needs:",
    deliveryApproaches: [
      {
        title: "Self-Service",
        description:
          "Follow our comprehensive documentation and best practices for a DIY setup.",
      },
      {
        title: "Engage with Us",
        description:
          "Collaborate with our professionals for hands-on assistance, customization, and solution implementation.",
      },
    ],
  },
  getStartedSection: {
    title: "Get Started Today",
    line1:
      "Ready to revolutionize your cloud operations? Get in touch with our team at VittaOps and embark on a journey towards optimized cloud spending and unparalleled efficiency. ",
    line2: 
      "to learn more.",
    contactUsLink: "/contactus",
    line3: 
      "VittaOps - Empowering Businesses to Dominate the Cloud ",
  },
};