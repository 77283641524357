import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import './css/style.css'; // Make sure to adjust the path to your custom styles
import { Container, Row, Col } from "react-bootstrap";
import {
  FULLSTACKSERVICES_IMG2,
  FULLSTACKSERVICES_IMG3,
  FULLSTACKSERVICES_IMG4,
} from "../../Assets";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

function FullStack() {
  return (
    <div style={{ overflowX: "hidden", background: BACKGROUNDCOLOR }}>
      <Container style={{marginTop:"160px"}}>
        <Row>
          <Col md={8}>
            <h1
              className="xlarge-font"
              style={{ color: "black", fontFamily: "impacted" }}
            >
              <b>
                Dhruthzuci Tech - Empowering Businesses with Full Stack
                Development Solutions
              </b>
            </h1>

            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              Your trusted partner for Full Stack Development services. In
              today's digital era, it is crucial for businesses to have a strong
              online presence and high-performing applications that cater to the
              evolving needs of their audience. We specialize in developing
              innovative and user-centric applications that drive digital
              transformation and propel your business towards success.
            </p>
          </Col>
          <Col md={4}>
            <img
              src={FULLSTACKSERVICES_IMG2}
              alt=""
              style={{ maxWidth: "100%", borderRadius: "10%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Mobile App Development</b>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              <span ></span>Our Mobile App
              Development services encompass the entire app development
              lifecycle, ensuring that your business has a powerful and engaging
              presence on mobile platforms. We offer:
            </p>
            <ol style={{ fontFamily: "impacted" }} type="1">
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>App Prototyping and Strategy </u>
                </span>
                <br /> We collaborate with you to define your app's vision,
                conduct market research, and create a solid strategy that aligns
                with your business goals.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>DCross-platform App Development: </u>
                </span>
                <br /> We develop apps that seamlessly run on multiple
                platforms, such as iOS and Android, utilizing frameworks like
                React Native and Flutter. This approach ensures wider reach and
                cost-effectiveness.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Enterprise Application Management:</u>
                </span>
                <br /> Our experts build robust and scalable enterprise
                applications tailored to your specific business needs, enabling
                streamlined processes, enhanced productivity, and improved
                efficiency.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Android Application UI/UX Design: </u>
                </span>
                <br /> We create intuitive and visually appealing user
                interfaces, focusing on delivering a seamless user experience
                that captivates your target audience.
              </li>

              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>End-to-end Support and Maintenance: </u>
                </span>
                <br /> Our commitment doesn't end with the app launch. We
                provide ongoing support and maintenance services to ensure your
                app remains up-to-date, secure, and optimized for optimal
                performance.
              </li>
            </ol>
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col md={4}>
            <img
              src={FULLSTACKSERVICES_IMG3}
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col md={8}>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Digital Transformation & Product Development</b>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              We help businesses embrace digital transformation and achieve
              their product development goals. Our services include:
            </p>
            <ol style={{ fontFamily: "impacted" }} type="1">
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Asp.Net Development: </u>
                </span>
                <br /> We leverage the power of Asp.Net to develop scalable and
                feature-rich web applications that meet your unique business
                requirements.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Digital Transformation Consulting: </u>
                </span>
                <br />
                Our experienced consultants guide you through the process of
                digital transformation, helping you leverage emerging
                technologies and modernize your business operations.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Agile Scalability Solutions:</u>
                </span>
                <br /> We implement agile methodologies and scalable
                architectures to ensure your applications can adapt and grow
                with your business needs.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Blockchain Development: </u>
                </span>
                <br /> Harnessing the potential of blockchain technology, we
                develop secure and transparent solutions that streamline
                processes and enhance data integrity.
              </li>

              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>DevOps: </u>
                </span>
                <br /> We embrace DevOps practices to facilitate collaboration,
                automation, and continuous delivery, enabling faster and more
                reliable software development and deployment.
              </li>
            </ol>
          </Col>
        </Row>
  
        <Row>
          <Col className=" pt-2 pt-lg-0">
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Complete Web-Stack Development</b>
            </h1>
            <p>
              Our Full Stack Development expertise covers the entire web
              development spectrum, including:
            </p>
            <ol type="1" style={{ fontFamily: "impacted" }}>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Agile QA, Automation, and DevOps: </u>
                </span>
                <br /> Align your cloud strategy with your business objectives,
                leveraging cloud technologies to gain a competitive edge.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Custom Web API and Integration: </u>
                </span>
                <br />
                Embrace agility and productivity through cloud-native
                technologies, enabling faster response to market demands.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Robust Front-end Architecture:</u>
                </span>
                <br /> Achieve flexibility by leveraging scalable cloud
                resources that can adapt to changing business requirements.
              </li>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>WordPress Development: </u>
                </span>
                <br /> Develop a comprehensive adoption strategy encompassing
                technology, processes, and organizational considerations.
              </li>
            </ol>
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col className=" pt-2 pt-lg-0">
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>RAS (Resource as a Service)</b>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              Our RAS offering provides access to a pool of skilled developers
              who can augment your existing team, ensuring efficient and timely
              project delivery.
            </p>
            <ol type="1" style={{ fontFamily: "impacted" }}>
              <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Technologies We Excel In: </u>
                </span>
                <br /> Our team of skilled professionals excels in a wide range
                of technologies, including:
              </li >
              <ol style={{ fontFamily: "impacted" }} type="i">
                <li> Angular </li>
                <li>React </li>
                <li>.Net </li>
                <li>Node.js </li>
                <li>MongoDB </li>
              </ol>
            </ol>
          </Col>
          {/* <Col md={4}>
            <img
              src={FULLSTACKSERVICES_IMG4}
              alt=""
              style={{ maxWidth: "100%", borderRadius: "20%" }}
            />
          </Col> */}
        </Row>
        <Row>
          <Col>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              <span style={{ }}>
                Unlock the Power of Full Stack Development with Dhruthzuci Tech:
                Experience the transformative potential of Full Stack
                Development with Dhruthzuci.
                <b>
                  <a
                    style={{ fontFamily: "impacted", textAlign: "justify", }}
                    href="/contactus"
                  >
                    Contact us today
                  </a>{" "}
                </b>
                to schedule a consultation and explore how our expertise can
                help your business thrive in the digital landscape. Let us be
                your trusted partner in delivering innovative, high-quality
                applications that elevate your brand, engage your audience, and
                drive success.
              </span>
            </p>
          </Col>
        </Row>
      </Container>
      <div id="footer"></div>
    </div>
  );
}

export default FullStack;