export const Homeaccordion1 = [
    {
      title1: "Infrastructure Services",
      content1:
        "Dhruthzuci Tech is your trustworthy partner for cutting-edge infrastructure services. We understand the critical role that infrastructure plays in the success of your IT systems and applications. Our complete infrastructure services are designed to give your business the robust foundation it needs to prosper in the digital era. We provide a range of dependable and scalable infrastructure services, including reliable hardware solutions, lightning-fast networking, and secure storage. Our expert team of specialists will collaborate with you to understand your specific needs and develop solutions that are precisely aligned with your business objectives.",
      img1:require('../../../Assets/images/Infrastructure-Services.png'),
  

        title2: "Application and Software Services",
        content2:
          "Dhruthzuci Tech Solutions is experienced in offering an extensive catalog of services and features that enable businesses to easily design, launch, manage, and maintain software applications. Our tailored approaches are designed to improve efficiency, boost performance, assure scalability, and maximize uptime whether you operate in traditional IT environments or use the power of cloud computing platforms. Whether you're a startup, SME, or large enterprise, we've got you covered! From seamless application development to efficient deployment and ongoing support, we ensure your software dreams become a reality. Experience unparalleled innovation and scalability with Dhruthzuci Tech Solutions, and elevate your business to the cloud-powered future.",
        img2:require('../../../Assets/images/Application&SoftwareServices.png'),


          title3: "Sustainability Services",
          content3:
            "Welcome to our cloud plus sustainable IT services! Our core idea is to bring environmental sustainability into all aspects of our solutions. We recognize that technology has a huge impact on our future, and with that power comes responsibility. Our dedication is to reduce the environmental impact of IT infrastructure, optimize energy usage, and promote sustainable business practices in the cloud environment. By prioritizing these activities, we hope to create a greener, more sustainable world for future generations. Join us on this path to a brighter future, where innovation and sustainability coexist. We can have a long-term good impact if we work together! Consumption, and promoting sustainable business practices within the cloud environment.",
          img3:require('../../../Assets/images/SustainabilityServices.png'),

    },
];
