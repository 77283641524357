
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { Homeaccordion1 } from "./config";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./hometab.css"
export default function Hometabs() {
  const tabStyles = {
    background: "linear-gradient(to top, #387FD3, #7B3EAC)",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 0px 20px rgba(0, 0, 255, 0.2)",
    borderRadius: "20px",
    padding: "20px",
  };

  const imageStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    
  };

  const pageStyle = {
    boxShadow: "0px 0px 20px rgba(0, 0, 255, 0.2)",
    borderRadius: "20px",
    padding: "20px",
  };

  const renderTabItems = (accordionData) => {
    return accordionData.map((item, index) => (
      <Col key={index} xs={12}  style={{
          borderRadius: "20px",
          padding: "20px",}}>
        <Tabs
          defaultActiveKey="home"
          id={`justify-tab-example-${index}`}
          className="mb-3"
          justify
          style={tabStyles}
        >
          <Tab
         
            eventKey="home"
            title={
              <strong style={{ fontSize: "1.5rem", fontFamily: "impacted",color:"black" }}>
                {item.title1}
              </strong>
            }
          >
            <Row>
              <Col xs={12} md={6}>
                <div className="content" style={{ textAlign: "justify", fontFamily: "impacted",marginTop: "40px" }}>
                  {item.content1}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div
                  className="image"
                  style={{
                    textAlign: "center",
                    marginTop: "40px",
                    height: "400px",
                    // border: "2px solid #0C1A4A", // Add border
                    backgroundColor: "#fff", // Add background color
                  }}
                >
                  <img
                    src={item.img1}
                    alt="Image"
                    style={imageStyle}
                  />
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="profile"
            title={
              <strong style={{ fontSize: "1.5rem", fontFamily: "impacted",color:"black" }}>
                {item.title2}
              </strong>
            }
          >
            <Row>
              <Col xs={12} md={6}>
                <div className="content" style={{ textAlign: "justify",fontFamily: "impacted", marginTop: "40px" }}>
                  {item.content2}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div
                  className="image"
                  style={{
                    textAlign: "center",
                    marginTop: "40px",
                    height: "400px",
               
                    backgroundColor: "#fff", 
                  }}
                >
                  <img
                    src={item.img2}
                    alt="Image"
                    style={imageStyle}
                  />
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="longer-tab"
            title={
              <strong style={{ fontSize: "1.5rem", fontFamily: "impacted",color:"black" }}>
                {item.title3}
              </strong>
            }
          >
            <Row>
              <Col xs={12} md={6}>
                <div className="content" style={{ textAlign: "justify",fontFamily: "impacted", marginTop: "40px" }}>
                  {item.content3}
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div
                  className="image"
                  style={{
                    textAlign: "center",
                    marginTop: "40px",
                    height: "400px",
                 //   border: "2px solid #0C1A4A", // Add border
                    backgroundColor: "#fff", // Add background color
                  }}
                >
                  <img
                    src={item.img3}
                    alt="Image"
                    style={imageStyle}
                  />
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
      </Col>
    ));
  };

  return (
    <div style={pageStyle}>
      <Container style={{ flex: "1" }}>{renderTabItems(Homeaccordion1)}</Container>
    </div>
  );
}