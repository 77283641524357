import React, { useEffect, useState } from 'react';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { AWS_API_KEY } from './config';
 
// Component to render individual candidate details in a table row
const CandidateRow = ({ data }) => {
  const handleResumeDownload = () => {
    const link = document.createElement('a');
    link.href = data.resumeurl;
    link.setAttribute('download', 'Resume'); // Set download attribute to the resume URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <tr>
      <td>{data.name}</td>
      <td>{data.email}</td>
      <td>{data.phone}</td>
      <td>{data.city}</td>
      <td>{data.state}</td>
      <td>{data.country}</td>
      <td>{data.gender}</td>
      <td>{data.role}</td>
      <td>{data.joiningInterest}</td>
      <td>
        <Button
          onClick={handleResumeDownload}
          variant="primary"
        >
          Download Resume
        </Button>
      </td>
    </tr>
  );
};
 
// Main Admin Panel component
export default function AdminPanel() {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    fetch('https://34msli0en0.execute-api.ap-south-1.amazonaws.com/prod/getdata', {
        headers: {
            "Content-Type": "application/json",
            "x-api-key": AWS_API_KEY,
          },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data["body-json"]);
 
        // Check if data is in the correct format
        if (data["body-json"] && Array.isArray(data["body-json"].body)) {
          setCandidates(data["body-json"].body); // Access the nested array
        } else {
          throw new Error('Invalid data format');
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);
 
  if (loading) {
    return <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>;
  }
 
  if (error) {
    return <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>Error: {error}</div>;
  }
 
  return (
    <Container style={{marginTop:40}}>
      <Row className="mb-4">
        <Col>
          <h1 className="text-center">All Candidates</h1>
        </Col>
      </Row>
      <Table striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>Gender</th>
            <th>Role</th>
            <th>Joining Interest</th>
            <th>Resume</th>
          </tr>
        </thead>
        <tbody>
          {candidates.length > 0 ? (
            candidates.map((candidate, index) => (
              <CandidateRow key={index} data={candidate} />
            ))
          ) : (
            <tr>
              <td colSpan="10" className="text-center">
                No candidates available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}
