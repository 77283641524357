// import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";

import { linkedin, mail } from "../../Assets";

const Footer = () => {
  return (
    <footer
      className="footer mt-auto py-3"
      style={{
        backgroundColor: "#0C1A4A",
      }}
    >
      <Container style={{ marginTop: "40px" }}>
        <Row>
          <Col md={3}>
            <div className="quick-links">
              <h4 style={{ fontFamily: "impact", color: "white" }}>
                Quick Links
              </h4>
              <ul>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/services"
                  >
                    Services
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/solutions"
                  >
                    Solutions
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/blogs"
                  >
                    Blogs
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/aboutus"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/contactus"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/privacy"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={3}>
            <div className="quick-links">
              <h4 style={{ fontFamily: "impact", color: "white" }}>Services</h4>
              <ul>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/cloudAdvisory"
                  >
                    Cloud Advisory
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/professionalServices"
                  >
                    Professional Services
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/blockChain"
                  >
                    BlockChain
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/microSoft"
                  >
                    Microsoft Office 365 Automation
                  </a>
                </li>
                <li>
                  <a
                    style={{ fontFamily: "impacted", color: "white" }}
                    href="/fullStack"
                  >
                    Full Stack Development
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={3}>
            <div className="social-links">
              <h4 style={{ fontFamily: "impact", color: "white" }}>
                Social Links
              </h4>
              <a
                href="https://www.linkedin.com/company/druthzuchi-tech-solutions/mycompany/"
                style={{ padding: "10px 0" }}
              >
                <img
                  src={linkedin}
                  alt="LinkedIn"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <a
                href="https://outlook.office.com/mail/your-outlook-email"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mail}
                  alt="Outlook"
                  style={{ width: "30px", height: "30px", marginLeft: "20px" }}
                />
              </a>
            </div>
          </Col>
          <Col md={3}>
            <div className="contact-info">
              <h4 style={{ fontFamily: "impact", color: "white" }}>Reach Us</h4>
              <p style={{ fontFamily: "impacted", color: "white" }}>
             <FaMapMarkerAlt className="icon" color="white" />  Head office:  <a href="/contactus">

               
                
                <br/>Corporate
                Office 2nd floor, 208 ramya nivasa, next to Soven apartment, 7th
                cross Shakthi nagar, Horamavu, Bangalore - 560043 </a>
              </p>
              <p style={{ fontFamily: "impacted", color: "white" }}>
              <a href="/contactus">
              <FaMapMarkerAlt className="icon" color="white" />
                    16192 Coastal Highway
                    <br />
                    Lewes, DE 19958
                    <br />
                    Sussex County
                    <br />
                    United States</a>
              </p>
              <p style={{ fontFamily: "impacted", color: "white" }}>
                <FaEnvelope className="icon" color="white" />{" "}
                <a
                  href="mailto:info@eshatechgroup.com"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  info@eshatechgroup.com
                </a>
              </p>
              <p style={{ fontFamily: "impacted", color: "white" }}>
                <FaPhone className="icon" color="white" />{" "}
                <a
                  href="tel:+918035001542"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  +91 80 3500 1542/43
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ marginTop: "20px" }} className="justify-content-between">
          <div className="col-md-12 text-center">
            <p
              style={{
                fontFamily: "impacted",
                color: "white",
                marginTop: "20px",
              }}
            >
              {" "}
              Copyright © 2022. Dhruthzuci Technology Solutions. All rights
              reserved.
            </p>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
