import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import './css/style.css'; // Make sure to adjust the path to your custom styles
import { Container, Row, Col } from "react-bootstrap";
import {
  PROFESSIONALSERVICES_IMG2,
  PROFESSIONALSERVICES_IMG3,
  PROFESSIONALSERVICES_IMG4,
} from "../../Assets";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
function ProfessionalServices() {
  return (
    <div style={{ overflowX: "hidden", background: BACKGROUNDCOLOR }}>
      <div id="header"></div>
      <Container style={{ marginTop: "160px" }}>
        <Row>
          <Col md={8}>
            <h1
              className="xlarge-font"
              style={{ color: "black", fontFamily: "impacted" }}
            >
              <b>
                Dhruthzuci Tech - Your Trusted Cloud Professional Services
                Provider
              </b>
            </h1>
            <br />
            <p
              style={{
                color: "black",
                fontFamily: "impacted",
                textAlign: "justify",
              }}
            >
              At Dhruthzuci Tech, we are committed to delivering exceptional
              Cloud Professional Services that empower businesses to harness the
              full potential of cloud computing. Our expert team specializes in
              creating scalable, secure, and efficient cloud-based technology
              infrastructures that adapt to the evolving needs of your
              organization. With a focus on client satisfaction, we tailor our
              solutions to align with your unique requirements and drive your
              business forward.
            </p>
          </Col>
          <Col md={4}>
            <img
              src={PROFESSIONALSERVICES_IMG2}
              alt=""
              style={{ width: "90%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Cloud Migration Services:</b>
              <span style={{ fontSize: "large" }}>
                {" "}
                (Seamlessly Transition to the Cloud){" "}
              </span>
            </h1>
            <p style={{ fontFamily: "impacted", textAlign: "justify" }}>
              <span style={{ fontSize: "24px", textAlign: "justify" }}></span>
              Our Cloud Migration Services ensure a smooth and successful
              transition to the cloud. We begin with a comprehensive assessment
              of your existing infrastructure, applications, and data, allowing
              us to develop a customized migration strategy. Our meticulous
              planning, workload prioritization, and application re-architecture
              minimize downtime and optimize efficiency throughout the migration
              process. Trust us to handle your cloud migration with expertise
              and care.
            </p>
            {/* Continue adding content */}
            {/* Adjust content placement and styling as needed */}
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
                textAlign: "justify",
              }}
            >
              <b style={{ fontFamily: "impacted", textAlign: "justify" }}>
                Cloud Optimization Services:
              </b>
              <span style={{ fontSize: "large", textAlign: "justify" }}>
                {" "}
                (Unlock the Full Potential of Your Cloud Infrastructure){" "}
              </span>
            </h1>
            <ol
              type="1"
              style={{ fontFamily: "impacted", textAlign: "justify" }}
            >
              <li>
                Maximizing the benefits of your cloud infrastructure is crucial
                for success. Our Cloud Optimization Services offer a range of
                strategies to enhance performance, cost efficiency, and resource
                utilization{" "}
              </li>
              <li>
                Network Optimization: Enhance connectivity, security, and
                performance within your cloud environment through efficient
                network configurations.{" "}
              </li>
              <li>
                Storage Optimization: Optimize your data storage solutions for
                maximum availability, durability, and cost-effectiveness.{" "}
              </li>
              <li>
              Cost Optimization: Analyze resource utilization, identify cost-saving opportunities, and implement strategies to optimize your cloud costs.
              </li>
              <li>
              Compute Optimization: Recommend the right instance types, auto-scaling configurations, and load balancing strategies to ensure efficient resource allocation.
              </li>
              <li>
              Operations Optimization: Streamline your cloud operations with automation, DevOps practices, and monitoring solutions.
              </li>
              {/* Continue adding list items */}
              {/* Adjust content placement and styling as needed */}
            </ol>
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col md={4}>
            <img
              src={PROFESSIONALSERVICES_IMG3}
              alt=""
              style={{
                maxWidth: "100%",
                borderRadius: "10%",
                marginTop: "20%",
              }}
            />
          </Col>
          <Col className=" pt-2 pt-lg-0">
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Cloud Technical Architecture:</b>
              <span style={{ fontSize: "large" }}>
                {" "}
                (Building the Foundation for Success){" "}
              </span>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              A well-designed cloud technical architecture is fundamental to
              unlocking the true potential of cloud computing. Our Cloud
              Technical Architecture services encompass:
            </p>
            <ol
              type="1"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            >
              <li>
                <u>Visualization</u>: Design intuitive and user-friendly
                dashboards and interfaces for real-time insights and efficient
                cloud management.{" "}
              </li>
              <li>
                <u>Infrastructure Architecture</u>: Create scalable and
                resilient cloud infrastructure that ensures high availability,
                fault tolerance, and disaster recovery.
              </li>
              <li>
                <u>Middleware Architecture</u>: Architect middleware solutions
                that facilitate seamless integration between your applications
                and databases within the cloud environment.
              </li>
              <li>
                <u>Management Architecture</u>: Develop comprehensive management
                architectures encompassing identity and access management,
                security policies, and governance frameworks.
              </li>
              <li>
                <u>Automation</u>: Implement automation solutions to streamline
                deployment, configuration management, and scaling of your cloud
                resources.
              </li>

              {/* Continue adding list items */}
              {/* Adjust content placement and styling as needed */}
            </ol>
          </Col>
        </Row>

        <Row>
          <Col>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Dev-Sec-Ops:</b>
              <span style={{ fontSize: "large" }}>
                {" "}
                (Prioritizing Security and Compliance){" "}
              </span>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              At Dhruthzuci Tech, we prioritize the security and compliance of
              your cloud environment. Our Dev-Sec-Ops services include:
            </p>
            <ol
              type="i"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            >
              <li>
                <u>Code Analysis</u>: Align your cloud strategy with your business
                objectives, leveraging cloud technologies to gain a competitive
                edge.
                equirements.
              </li>
              <li>
              <u>Investigations</u>: Embrace agility and productivity through cloud-native technologies, enabling faster response to market demands.
                edge.
              </li>
              <li>                <u>Change Management</u>: Achieve flexibility by leveraging scalable cloud resources that can adapt to changing business requirements.</li>
              <li><u>Vulnerability Assessment</u>: Develop a comprehensive adoption strategy encompassing technology, processes, and organizational considerations.</li>
              <li><u>Compliance Monitoring</u>: Optimize your cloud architecture, ensuring operational excellence, security, compliance, and cost optimization.</li>
              <li><u>Training</u>: Implement robust security measures and compliance frameworks to protect your data and meet regulatory requirements</li>
                
              {/* Continue adding list items */}
              {/* Adjust content placement and styling as needed */}
            </ol>
          </Col>
          <Col md={4}>
            <img
              src={PROFESSIONALSERVICES_IMG4}
              alt=""
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              We believe in knowledge transfer and offer comprehensive training
              programs tailored to your organization's needs. Our training
              covers cloud fundamentals, best practices, security awareness, and
              hands-on workshops, equipping your team with the skills required
              to effectively manage and optimize your cloud environment.
            </p>
            <p style={{ fontFamily: "impacted" }}>
              Partner with Dhruthzuci Tech for Exceptional Cloud Professional
              Services
            </p>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              <span style={{}}>
                At Dhruthzuci Tech, we are dedicated to delivering top-notch
                Cloud Professional Services that propel your business forward.
                Trust our expertise, experience, and commitment to provide you
                with tailored solutions that meet your unique requirements.{" "}
                <a style={{ fontFamily: "impacted" }} href="/contactus">
                  Contact us today
                </a>{" "}
                to explore how we can help you leverage the power of the cloud
                and achieve your business objectives.
              </span>
            </p>
          </Col>
        </Row>{" "}
      </Container>
    </div>
  );
}
export default ProfessionalServices;