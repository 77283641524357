import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import './App.css'; // You can create a CSS file for additional styling

import { Container, Row, Col } from "react-bootstrap";
import { V1, VAPT } from "../../Assets";

class VAT extends React.Component {
  render() {
    return (
      <div>
        <div id="header"></div>
        <br />
        <br />
        <br />
        <br />
        <Container
          fluid
          className="w3-main w3-padding-large"
          style={{ marginLeft: "0%" }}
        >
          {/* Header */}
          <header
            className="w3-container w3-center"
            style={{ padding: "0px 10px" }}
            id="home"
          >
            <h1 className="w3-jumbo" style={{ color: "#55ACEE" }}>
              <b>Cloud Security Assessment</b>
            </h1>
          </header>

          {/* About Section */}
          <Container
            className="w3-content w3-justify w3-text-grey w3-padding-32"
            id="about"
          >
            <p>
              Vulnerability Assessment and Penetration Testing (VAPT) is a
              comprehensive approach to evaluating and enhancing the security of
              a computer system, network, or application. It combines two
              activities: vulnerability assessment and penetration testing.
            </p>
            <ol type="1">
              <li>
                <b>Vulnerability Assessment:</b> The vulnerability assessment
                process involves scanning and identifying potential
                vulnerabilities in a system, network, or application...
              </li>
              <li>
                <b>Cost Efficiency:</b> Penetration testing, also known as
                ethical hacking, simulates real-world attacks to identify
                vulnerabilities and test the system's ability to withstand them.
              </li>
            </ol>
            {/* Additional content */}
          </Container>

          {/* Portfolio Section */}
          <Container className="w3-padding-32 w3-content" id="portfolio">
            <video style={{ width: "100%" }} controls autoPlay muted>
              <source src={V1}/>
            </video>
            {/* Additional content */}
            <div>
              <img className="vpt" src={VAPT} alt="VAPT" style={{width:"90%",height:"50%"}}/>
            </div>
            {/* Additional content */}
          </Container>
        </Container>

        <div id="footer"></div>
      </div>
    );
  }
}

export default VAT;