import { PASSWORDLESS1 } from "../PasswordlessAuth/config";

export const IAC_HERO = require("../../Assets/images/iac_hero.png");
export const DBLUE_RECTANGLE = require("../../Assets/images/Rectangle1.png");

export const IAC_INTRO = require("../../Assets/images/iac_intro.png");


export const IAC_PATH = require("../../Assets/images/iac_path.png");
export const IAC_TECHNICAL = require("../../Assets/images/iac_tech.png");
export const IAC_OPTIMIZED = require("../../Assets/images/iac_optimized.png");
export const IAC_DISASTER = require("../../Assets/images/iac_disaster.png");
export const IAC_DESIGN = require("../../Assets/images/iac_design.png");
export const IAC1 = require("../../Assets/images/iac1.png");
export const IAC2 = require("../../Assets/images/iac3.png");
export const IAC3 = require("../../Assets/images/iac4.png");
export const IAC4 = require("../../Assets/images/iac5.png");
export const IAC_LAST = require("../../Assets/images/iac_last.png");
export const IAC_ARCHI = require("../../Assets/images/Architecture_iac.png");




export const IaC_Data = {
  heroSection: {
    title: "Master AWS CloudFormation: Unleashing Infrastructure as Code (IaC) Potential",
    backgroundImage: IAC_HERO,
  },
  introductionSection: {
    title:
      "Introduction",
    description:
      "Step into the world of AWS CloudFormation, where we demystify the wonders of Infrastructure as Code (IaC) and uncover its remarkable capabilities. Our solution serves as your gateway to revolutionize the management of AWS resources, emphasizing efficiency, security, and cost optimization. ",
    // image: CLOUDLANDING1,

  },


  targetCustomersSection: {
    title: "Why AWS CloudFormation? ",
    description:
      "Embark on a transformative journey with us to explore the vast potential of AWS CloudFormation. Bid farewell to manual resource configuration and embrace the elegance of IaC. Whether you're a startup streamlining AWS operations or an enterprise maximizing efficiency, AWS CloudFormation is your solution.",
    targetCustomers: [
      {
        // image: CLOUDLANDING1,
        text: [
          {
            title: "Startups and Small Businesses:",
            description:
              "Explore the Benefits of AWS Control Tower Discover how AWS Control Tower can revolutionise your AWS landscape. Bid farewell to the complexities of account setup and configuration, and say hello to a centralised, well-architected, and ultra-secure multi-account AWS environment. Whether you're a dynamic startup or a well-established enterprise, AWS Control Tower is designed with you in mind.",
          },
        ],
      },
      {
              text: [
          {
            title: "Enterprises:",
            description: "Robust security measures for large organizations.",
          },
        ],
        // image: CLOUDLANDING2,

      },
      {
        // image: CLOUDLANDING3,
        text: [
          {
            title: "User-Centric Applications:",
            description:
              "Ideal for apps where user experience and security are paramount.",
          },
        ],
      },
      {
        // image: CLOUDLANDING5,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
      {
        // image: CLOUDLANDING6,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
    ],
  },
  customerEngagementSection: {
    title: "The Excellence of AWS Value",
    description:
      "Embark on a journey where AWS CloudFormation becomes your tool for excellence, offering: ",


    backgroundImage: DBLUE_RECTANGLE,
  },
  deliveryMechanismsSection: {
    title: "IaC Design Principles",
    description: "Explore the core design principles guiding our AWS CloudFormation project. Discover the simplicity of declarative code, version control, modularity, parameterization, dependency management, and stack orchestration – guaranteeing a smooth deployment.",
    image: IAC_DESIGN,

  },

  UnlockPotentialSection: {
    title: "Project Milestones and Feedback",
    description: "Explore our project milestones, each serving as a crucial phase. The collection of feedback throughout the project ensures continuous improvement, customer satisfaction, and unmatched service quality.",

  },

  WelcomeToFutureSection: {
    title: "Elevate Your Cloud with Excellence",
    description: "Embark on a transformative journey with DhruthZuciTech's AWS CloudFormation excellence. Unleash the true potential of the cloud, effortlessly deploying and managing your AWS infrastructure. Optimize, scale, and govern with the excellence your business deserves for an outstanding business landscape.",

  },
  getStartedSection: {
    title: "Ready to get started?",
    line1:
      " Ready to embark on your AWS CloudFormation journey?",
    contactUsLink: "/contactus",
    line2:
        "to transform your infrastructure today"
  },
};