import React from "react";
import { Container, Card } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

const PrivacyPolicyPage = () => {
  const privacyPolicyData = {
    introduction:
      "Welcome to Dhruthzuci Tech Solutions. We respect your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, and share information from or about our users and your choices regarding this information.",
    informationCollection: {
      typesOfInformation: {
        personalInformation:
          "This includes your name, email address, phone number, and any other information you provide when you fill out contact forms, subscribe to our newsletters, or interact with our services.",
        usageData: "Information on how you use our website and services.",
        cookiesAndTrackingData:
          "We use cookies and similar tracking technologies to track activity on our website.",
      },
    },
    purposeOfCollection:
      "Your data is collected for the following purposes: To provide and maintain our service, to notify you about changes to our service, to provide customer support, to gather analysis or valuable information so that we can improve our service, to monitor the usage of our service, and to detect, prevent and address technical issues.",
    legalBasisForProcessing:
      "We process your data in accordance with the Information Technology Act, 2000, and the SPDI Rules. Processing is necessary for the performance of a contract with you, to comply with our legal obligations, and for our legitimate interests (such as improving our services).",
    dataStorageAndSecurity:
      "We take the security of your data seriously and implement reasonable security practices and procedures as per Indian law. Your data is stored on secure servers and protected from unauthorized access, disclosure, alteration, and destruction.",
    thirdPartySharing:
      "We may share your information with third-party service providers who perform services on our behalf, such as analytics, hosting, and email delivery. These third parties are bound by confidentiality agreements and are only allowed to use your data as specified in these agreements.",
    userRights:
      "You have the right to access, correct, or delete your personal information that we hold. You can also object to the processing of your data, request data portability, and withdraw consent at any time.",
    cookiesAndTrackingTechnologies:
      "We use cookies and similar tracking technologies to track activity on our website and hold certain information. You have the option to accept or refuse these cookies and know when a cookie is being sent to your computer.",
    internationalDataTransfers:
      "Your information may be transferred to — and maintained on — computers located outside of your state or country where data protection laws may differ from those in your jurisdiction.",
    dataRetention:
      "We retain your personal data only for as long as is necessary for the purposes set out in this policy, in accordance with Indian law.",
    policyUpdates:
      "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
    contactInformation:
      "For any questions or concerns about this policy or your data protection, please contact us at",
  };

  return (
    <Container
      style={{
        marginTop: "120px",
        background: BACKGROUNDCOLOR,
        fontFamily: "impacted",
        textAlign: "justify",
      }}
    >
      <h1
           
            style={{
                textAlign: "center",
                fontFamily: "impact",
            }}
          >
            Privacy Policy
          </h1>
      <Card style={{ background: BACKGROUNDCOLOR }}>
        <Card.Body>
          <Card.Text>
            <h4>Introduction</h4>
            <p>{privacyPolicyData.introduction}</p>

            <h4>Information Collection</h4>
            <p>
              <strong>Types of Information:</strong>
            </p>
            <ul>
              <li>
                <strong>Personal Information:</strong>{" "}
                {
                  privacyPolicyData.informationCollection.typesOfInformation
                    .personalInformation
                }
              </li>
              <li>
                <strong>Usage Data:</strong>{" "}
                {
                  privacyPolicyData.informationCollection.typesOfInformation
                    .usageData
                }
              </li>
              <li>
                <strong>Cookies and Tracking Data:</strong>{" "}
                {
                  privacyPolicyData.informationCollection.typesOfInformation
                    .cookiesAndTrackingData
                }
              </li>
            </ul>

            <h4>Purpose of Collection</h4>
            <p>{privacyPolicyData.purposeOfCollection}</p>

            <h4>Legal Basis for Processing</h4>
            <p>{privacyPolicyData.legalBasisForProcessing}</p>

            <h4>Data Storage and Security</h4>
            <p>{privacyPolicyData.dataStorageAndSecurity}</p>

            <h4>Third-Party Sharing</h4>
            <p>{privacyPolicyData.thirdPartySharing}</p>

            <h4>User Rights</h4>
            <p>{privacyPolicyData.userRights}</p>

            <h4>Cookies and Tracking Technologies</h4>
            <p>{privacyPolicyData.cookiesAndTrackingTechnologies}</p>

            <h4>International Data Transfers</h4>
            <p>{privacyPolicyData.internationalDataTransfers}</p>

            <h4>Data Retention</h4>
            <p>{privacyPolicyData.dataRetention}</p>

            <h4>Policy Updates</h4>
            <p>{privacyPolicyData.policyUpdates}</p>

            <h4>Contact Information</h4>
            <p>
              {privacyPolicyData.contactInformation}{" "}
              <a style={{ fontFamily: "impacted" }} href="/contactus">
                Contact us today
              </a>{" "}
            </p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PrivacyPolicyPage;
