import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {

  CloudlandingData,
  DIGI1,
  DIGI2,
  DIGI3,
  DIGI4,
  DIGI5,
  DIGI6,
  DIGI7,

} from "./config";
import "./DiGI.css"

export default function DiGI() {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };

  return (
    <div
      style={{
        background: "#fff",
        overflowX: "hidden",
        marginTop: "100px",
      }}
    >
      <section
        className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
<Container>    
      <h1
          style={{
            ...centerAlignedText,
            fontSize: "50px",
            marginBottom: "300px",
            marginTop: "250px",
            color: "#fff",
          }}
        >
          {CloudlandingData.heroSection.title}
        </h1></Container>

      </section>

      <Container>
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "35px",
            marginBottom: "50px",
            marginTop: "20px",
            fontFamily: "impact",
          }}
        >
          {CloudlandingData.introductionSection.title}
        </h1>
        <Row className="align-items-center justify-content-center">
          <Col>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              {CloudlandingData.introductionSection.description}
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>{CloudlandingData.targetCustomersSection.title}</b>
            </h1>
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI1} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Instant Rice Variety Identification:</b>
              <br/>
              Our advanced AI system delivers real-time results. Simply upload an image of a rice seed, and our platform will instantly classify the variety, eliminating the need for time-consuming manual identification.
              </p>
            </Col>
          </Row>

  
          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Efficient Crop Management:</b>
              <br/>
              Digital Grain Identifier empowers farmers and agricultural experts to efficiently manage rice crops. Easily monitor and identify specific rice varieties, track growth stages, and detect diseases and pests with precision.
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI2} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>



          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI3} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" ,textAlign:"justify"}}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Yield Prediction:</b>
              <br/>

              Plan your harvest and anticipate income accurately. Our system aids in predicting yields, enabling informed decisions and optimized crop production. </p>
            </Col>
          </Row>

          

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Quality Control:</b>
              <br/>

              Ensure top-quality rice products with our system's ability to detect impurities, assess grain size and shape, and maintain top-notch grain quality.
              </p>
            </Col>

            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI4} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI5} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Labor Efficiency:</b>
              <br/>
              Reduce the need for manual labour by automating sorting processes using AI/ML, making your rice mill operations more efficient.</p>
            </Col>
          </Row>
        </Row>
      </Container>

      <section
        // className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.customerEngagementSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "35vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Container>
          <Col
            // md={9}
            style={{
              color: "white",
              fontFamily: "impacted",
              textAlign: "center",
            }}
          >
            <h1 className="large-font" style={{fontSize:"26px"}}>
              {CloudlandingData.customerEngagementSection.title}
            </h1>
            <br/>
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "left",
                marginTop:"15px",
              }}
            >
              {CloudlandingData.customerEngagementSection.description}

            </p>
          </Col>
        </Container>
      </section>
      <Container>
      <Row style={{marginBottom:"20px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{CloudlandingData.AWSValueProposition.title}</b>
            </h1>
          </Col>

          <Col>
            <p> {CloudlandingData.AWSValueProposition.description}</p>
          </Col>
        </Row>
    </Container>
        <Container>
        <Row style={{marginBottom:"20px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{CloudlandingData.WhoCanBenefit.title}</b>
            </h1>
          </Col>
          <Col>
            <p> {CloudlandingData.WhoCanBenefit.description}</p>
          </Col>
        </Row>
        </Container>
      <Container
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          fontFamily: "impacted",
        }}
      >

        <Col>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <img src={DIGI6} alt="" />
          </div>
        </Col>

        <Container>
        <Row style={{marginBottom:"20px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{CloudlandingData.EngageWithTechnology.title}</b>
            </h1>
          </Col>
          <Col>
            <p> {CloudlandingData.EngageWithTechnology.description}</p>
          </Col>
        </Row>
        </Container>
        <Col>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <img src={DIGI7} alt="" />
        </div>
        </Col>



        <Row style={{marginBottom:"20px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{CloudlandingData.CostOptimization.title}</b>
            </h1>
          </Col>
          <Col>
            <p> {CloudlandingData.CostOptimization.line1}</p>
            <p> {CloudlandingData.CostOptimization.line2}</p>
            <p> {CloudlandingData.CostOptimization.line3}</p> 

          </Col>
        </Row>

        <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{CloudlandingData.getStartedSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12}>
          
          {CloudlandingData.getStartedSection.description}
          <a
            style={{ fontFamily: "impacted", fontSize: "23px" }}
            href={CloudlandingData.getStartedSection.contactUsLink}
          >
            <b>Contact us</b>
          </a>{" "}
          today
        </Col>
      </Container>
    </div>
  );
}