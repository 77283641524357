import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { O365SERVICES_IMG2, CHATBOT, CLOUDMIGRATION, VAPT2 } from '../../Assets';
import './BlogSelect.css';


function BlogSelect() {
  return (
    <Container style={{ marginTop: "130px" }}>
   <h1
          data-aos="fade-up"
          style={{
            fontFamily: "impact",
            textAlign: "center",
            marginBottom: "5%",
            
          }}
        >
          Explore 
        </h1>
      <Row>
        {blogs.map((blog, index) => (
          <Col key={index} sm={12} md={6}>
            <Card className="premium-card text-center"> {/* Added 'text-center' class to center content */}
              <Card.Img
                src={blog.img}
                style={{ maxWidth: "100%", maxHeight: "180px", objectFit: "contain", marginTop:"3px" }}  
              />
              <Card.Body>
                <Card.Text>
                  <div className="category"><a href="">{blog.category}</a></div>
                  <Card.Title>
                    <a href={blog.link}>{blog.title}</a>
                  </Card.Title>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

const blogs = [
  {
    img: VAPT2,
    category: "VAPT",
    link: "/blogs/vapt",
    title: " Vulnerability Assessment and Penetration Testing (VAPT)",
  },
  {
    img: O365SERVICES_IMG2,
    category: "WORKSPACE MODERNIZATION",
    link: "/blogs/office",
    title: " Workspace Modernization that works 24/7 for your company",
  },
  {
    img: CHATBOT,
    category: "CONVERSATIONAL CHATBOT",
    link: "/blogs/cb",
    title: "A chatbot is an AI-powered software program designed to simulate human-like conversations with users",
  },
  {
    img: CLOUDMIGRATION,
    category: "CLOUD MIGRATION",
    link: "/blogs/cm",
    title: " cloud migration and its benefits",
  },
];

export default BlogSelect;