import React from "react";
import {
  Container,
  Row,
  Col,

} from "react-bootstrap";
import { CB_IMG } from "../../Assets";
import { CB } from "../../BlogPosts/CONFIG.js";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

export const ChatBot = () => {
  const contentStyle = {
    fontFamily: "impacted",
    textAlign:"justify",
  };

  return (
    <div style={{ background: BACKGROUNDCOLOR }}>
      <Container  style={{ marginTop: "120px" }}>
        <header
          className="w3-container w3-center"
          style={{ padding: "0px 10px", top: "20" }}
          id="home"
        >
          <h1 className="w3-jumbo" style={{ color: "#55ACEE" , textAlign: "center", fontFamily: "impacted"}}>
            {CB.title}
          </h1>
        </header>
        <Row>
          <Col  >
            <article>
              <div className="post-content" style={contentStyle}>
               
                <Container
                  className="w3-content w3-justify w3-text-grey w3-padding-32"
                  id="about"
                >
                  

                  <Container fluid id="portfolio">
                    <Row>
                      <Col>
                      <p>
                    A chatbot is an AI-powered software program designed to
                    simulate human-like conversations with users via text or
                    voice-based interactions. It utilizes natural language
                    processing (NLP) and machine learning algorithms to
                    understand and respond to user queries and requests.
                  </p>
                        <p style={{ fontSize: "25px", ...contentStyle }}>
                          <b>
                            Organizations can benefit from implementing a
                            chatbot for several reasons
                          </b>
                        </p>
                        <ol type="1">
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Improved Customer Service</u>: 
                            </span>
                            <br /> Chatbots can provide instant and consistent
                            customer support, addressing common queries and
                            providing quick responses. They can handle a high
                            volume of inquiries simultaneously, reducing wait
                            times and enhancing customer satisfaction.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>24/7 Availability</u>:
                            </span>
                            <br /> Unlike human agents who have limited working
                            hours, chatbots can operate round the clock,
                            providing assistance to users at any time.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Cost and Time Efficiency</u>:
                            </span>
                            <br /> Chatbots can automate routine and repetitive
                            tasks, reducing the workload on customer service
                            agents. By handling common inquiries and providing
                            self-service options, organizations can optimize
                            their resources and allocate human agents to more
                            complex or specialized tasks.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Scalability</u>:
                            </span>
                            <br /> Chatbots can scale effortlessly to
                            accommodate a growing number of users and
                            interactions without significant infrastructure or
                            staffing changes. This scalability makes them
                            suitable for businesses experiencing rapid growth or
                            seasonal fluctuations in customer demands.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Lead Generation and Conversion</u>:
                            </span>
                            <br /> Chatbots can act as virtual sales assistants,
                            engaging with users, providing product information,
                            and guiding them through the purchasing process.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Enhanced User Engagement</u>:
                            </span>
                            <br /> Chatbots create interactive and engaging
                            experiences for users. By simulating human-like
                            conversations, they can make interactions more
                            conversational, entertaining, and interactive,
                            leading to improved user engagement and
                            satisfaction.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Data Collection and Analysis</u>:
                            </span>
                            <br /> Chatbots can collect valuable user data
                            during interactions, such as preferences, feedback,
                            and behavioral patterns. This data can be analyzed
                            to gain insights into customer behavior, improve
                            products and services, and personalize future
                            interactions.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Multichannel Support</u>:
                            </span>
                            <br /> Chatbots can be deployed across various
                            platforms and channels, including websites,
                            messaging apps, social media platforms, and voice
                            assistants. This multichannel support enables
                            organizations to reach customers on their preferred
                            platforms, providing consistent and seamless
                            experiences across channels.
                          </li>
                        </ol>
                        <p style={{ fontSize: "25px", ...contentStyle }}>
                          <b>Key components of a chatbot system include:</b>
                        </p>
                        <ol type="i">
                          <li style={contentStyle}>
                            Natural Language Understanding (NLU)
                          </li>
                          <li style={contentStyle}>Dialogue Management</li>
                          <li style={contentStyle}>
                            Natural Language Generation (NLG)
                          </li>
                          <li style={contentStyle}>Integration</li>
                        </ol>
                        <p style={{ ...contentStyle }}>
                          Amazon Lex is a service provided by Amazon Web
                          Services (AWS) that enables the development of
                          conversational chatbots. It leverages the same
                          technology that powers Amazon Alexa, allowing
                          developers to build chatbot applications with natural
                          language understanding (NLU) capabilities.
                        </p>

                        <Row>
                          <Col >
                            <img
                              className="vpt"
                              src={CB_IMG}
                              style={{ width: "90%" }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>

                  <ol type="i">
                    <li style={contentStyle}>
                      <span style={{ fontSize: "larger" }}>
                        <u>Natural Language Understanding (NLU)</u>:
                      </span>
                      <br /> Amazon Lex uses machine learning algorithms to
                      understand and interpret user input, including text and
                      voice-based queries. It can identify user intents (the
                      user's goal or purpose) and extract relevant information
                      from the input using techniques like entity recognition.
                    </li>
                    <li style={contentStyle}>
                      <span style={{ fontSize: "larger" }}>
                        <u>Dialog Management</u>:
                      </span>
                      <br /> With Amazon Lex, you can define and manage
                      conversational flows and responses. You can create
                      dialogue prompts, define slot types (to gather specific
                      information from users), and design the conversational
                      flow using conditions, prompts, and validation rules.
                    </li>
                    <li style={contentStyle}>
                      <span style={{ fontSize: "larger" }}>
                        <u>Integration with AWS Services</u>:
                      </span>
                      <br /> Amazon Lex integrates seamlessly with other AWS
                      services, enabling you to leverage their capabilities in
                      your chatbot applications. For example, you can use AWS
                      Lambda to perform custom business logic, AWS Polly for
                      text-to-speech functionality, or AWS Lambda to perform
                      backend processing.
                    </li>
                    <li style={contentStyle}>
                      <span style={{ fontSize: "larger" }}>
                        <u>Voice Interaction</u>:
                      </span>
                      <br /> Amazon Lex supports voice interactions by
                      integrating with services like Amazon Polly for speech
                      synthesis and Amazon Transcribe for speech recognition.
                      This enables you to build voice-based chatbot
                      applications, similar to Amazon Alexa skills.
                    </li>
                    <li style={contentStyle}>
                      <span style={{ fontSize: "larger" }}>
                        <u>Scalability and Management</u>:
                      </span>
                      <br /> Amazon Lex is a fully managed service, which means
                      that AWS takes care of the infrastructure, scaling, and
                      maintenance. This allows you to focus on developing and
                      enhancing your chatbot application without worrying about
                      the underlying infrastructure.
                    </li>
                  </ol>
                  <p style={contentStyle}>
                    Amazon Lex provides a robust and scalable platform for
                    developing conversational chatbots, enabling businesses to
                    deliver interactive and personalized user experiences across
                    multiple channels.
                  </p>

                  <p style={{ fontSize: "25px", ...contentStyle }}>
                    <b>AWS QnA chatbot uses the following AWS services.</b>
                  </p>

                  <ol type="i">
                    <li style={contentStyle}>Amazon Lex</li>
                    <li style={contentStyle}>Amazon Cognito</li>
                    <li style={contentStyle}>Amazon CloudFront</li>
                    <li style={contentStyle}>Amazon Lambda</li>
                    <li style={contentStyle}>Amazon OpenSearch</li>
                    <li style={contentStyle}>Amazon Comprehend</li>
                    <li style={contentStyle}>AmazonS3</li>
                    <li style={contentStyle}>Amazon Translate</li>
                    <li style={contentStyle}>Amazon Kendra - Optional</li>
                    <li style={contentStyle}>
                      Amazon Kinesis Data Firehose - Optional
                    </li>
                  </ol>
                </Container>
              </div>
            </article>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
