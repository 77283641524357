import React from "react";
import { EVENTS_BACKGROUND } from "../Tabs/Events/config";


const bannerStyle = {
    background: `url(${EVENTS_BACKGROUND})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",  // Set width to cover the entire screen
      height: "100vh", // Set height to cover the entire screen height
      marginTop: "10vh"
  };

  export default function EventsHero() {
    return (
        <div style={{ marginTop: "0" }}>
          <section
            className="py-5"
            style={{
              ...bannerStyle,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start", // Align left
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                fontFamily: "impacted",
                fontSize: "100px",
                marginBottom: "50px",
                color: "#fff",
                marginLeft: "500px", // Adjust the left margin as needed
              }}
            >
              Events
            </h1>
          </section>
        </div>

        );

  }