import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { AWSCLOUD_IMG, AZURE_IMG, GCP_IMG, E2E_IMG } from "../../Assets";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
import { productsData} from "../../Config/config";
import "./solution.css"; // Use the same CSS as Services
const Products = () => {
  const imageStyle = {
    width: "86%",
    height: "100%",
    objectFit: "contain",
  };
  return (
    <div
    style={{
      background: BACKGROUNDCOLOR,
    }}
  >
    <Container style={{ marginTop: "120px" }}>
      <div
        className="section-title"
        data-aos="fade-up"
        style={{
          textAlign: "center",
          padding: "10px",
          borderRadius: "20px",
        }}
      >
        <h1
          style={{
            fontFamily: "impact",
            margin: "0",
          }}
        >
          Our Products
        </h1>
        </div>
  
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col
              lg={12}
              style={{
                textAlign: "center",
                paddingBottom: "20px",
                fontFamily: "impacted",
              }}
            >
              <h3 style={{ color: "black" }}>
              Products that fit all business sizes to achieve the goals of modern
          digitalization.
              </h3>
            </Col>
          </Row>
        <Row>
          {productsData.map((solution, index) => (
            <Col lg={4} key={index} className="mb-4" id="cards">
              <Card
                className="h-100 text-center"
                id="cloud"
                style={{
                  boxShadow: "0px 0px 20px rgba(0, 0, 255, 0.2)",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <Card.Header
                  align="center"
                  style={{
                    fontFamily: "impact",
                    // background: BACKGROUNDCOLOR,
                    color: "#fff",
                    fontSize: "20px",
                    background: "#0C1A4A",
                  }}
                >
                  {solution.title}
                </Card.Header>
                <Card.Body>
                  <div
                    className="image-zoom"
                    style={{ textAlign: "center", height: "60%" }}
                  >
                    <img
                      className="img-fluid mb-4"
                      src={solution.image}
                      alt="Cloud Advisory"
                      style={imageStyle}
                    />
                  </div>
                  <p
                    className="card-text"
                    style={{ paddingTop: "4%", fontFamily: "impacted" }}
                  >
                    {solution.description}
                  </p>
                </Card.Body>
                <Button
                    href='/MWMPage'
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "white",
                      border: "0",
                      background: "#ffff",
                    }}
                  >
                    <button
                      className="rounded"
                      style={{
                        padding: "10px",
                        background: "linear-gradient(to top, #387FD3, #7B3EAC)",
                        fontFamily: "impacted",
                        color: "#fff",
                      }}
                    >
                      Know More
                    </button>
                  </Button>
              </Card>
            </Col>
          ))}
        </Row>
        </div>
        <div className="container">
          <div
            className="item"
            style={{ paddingLeft: "6%", textAlign: "center",marginTop:"40px" }}
          >
            <br/>
            <br/>
            <br/>
            <h1 style={{ fontSize: "25px", fontFamily: "impact" }}>
              Our Trusted Cloud Platforms:
            </h1>
            <img src={AWSCLOUD_IMG} width="17%" id="awsimg" alt="AWS" />
            <img
              src={AZURE_IMG}
              width="17%"
              style={{ paddingRight: "1%" }}
              id="awsimg"
              alt="Azure"
            />
            <img src={GCP_IMG} width="17%" id="awsimg" alt="GCP" />
            <img src={E2E_IMG} width="17%" id="awsimg" alt="E2E Cloud" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Products;