export const BACKGROUNDCOLOR  ="#ffff"
export const HEADERBACKGROUND="#000000"


export const BACKGROUNDIMAGE= "https://wallpapers.com/images/high/all-black-background-naarm5379b1njo8i.webp"
export const TRANSPERENTIMAGE1="https://img.freepik.com/free-vector/abstract-colorful-waves-transparent-background_1055-2093.jpg?size=626&ext=jpg"



export const HEADERBACKGROUNDIMAGE="https://images.unsplash.com/32/Mc8kW4x9Q3aRR3RkP5Im_IMG_4417.jpg?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dHJhbnNwZXJlbnQlMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww&auto=format&fit=crop&w=700&q=60"
