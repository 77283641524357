import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import './css/style.css'; // Make sure to adjust the path to your custom styles
import { Container, Row, Col } from "react-bootstrap";
import {
  BLOCKCHAINSERVICES_IMG2,
  BLOCKCHAINSERVICES_IMG3,
  BLOCKCHAINSERVICES_IMG4,
} from "../../Assets";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

function BlockChain() {
  return (
    <div style={{ background: BACKGROUNDCOLOR, overflowX: "hidden" }}>

  <Container style={{marginTop:"160px"}}>
        <Row>
          <Col md={8}>
            <h1 className="xlarge-font" style={{ color: "black",fontFamily:"impacted" }}>
              <b style={{}}>
                Dhruthzuci Tech - Your Trusted Blockchain Professional Services
                Provider
              </b>
            </h1>
            <br />
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px",fontFamily:"impacted" }}
            >
              <b>Experience and Knowledge:</b>
            </h1>
            <p style={{ textAlign: "justify",fontFamily:"impacted" }}>
              <span style={{  textAlign: "justify",fontFamily:"impacted" }}></span>At
              Dhruthzuci Tech, we pride ourselves on being a trusted provider of
              Blockchain Professional Services, empowering businesses to unlock
              the full potential of blockchain technology. Our expert team
              specializes in designing, implementing, and optimizing blockchain
              solutions that drive innovation, enhance security, and
              revolutionize business processes.
            </p>
            {/* Additional content */}
          </Col>
          <Col md={4}>
            <img
              src={BLOCKCHAINSERVICES_IMG2}
              alt=""
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          {/* <Col md={4}>
          <img src={require('../../Assets/images/blockchain.webp')} alt="" style={{ maxWidth: '100%', borderRadius: '10%' }} />
          </Col> */}
          <Col>
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px" }}
            >
              <b style={{fontFamily:"impacted"}}>
                Blockchain Integration Services: Seamlessly Integrate Blockchain
                into Your Operations
              </b>
            </h1>
            <p style={{ textAlign: "justify",fontFamily:"impacted" }}>
              Our Blockchain Integration Services enable you to seamlessly
              integrate blockchain technology into your existing systems and
              processes. We understand that every business is unique, which is
              why our experts work closely with you to identify the most
              suitable blockchain solution for your specific needs. By
              integrating blockchain technology, you can enhance transparency,
              improve data integrity, and establish trust with your
              stakeholders.
            </p>
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px",fontFamily:"impacted" }}
            >
              <b>
                Custom Blockchain Development: Tailored Solutions for Your
                Unique Requirements
              </b>
            </h1>
            <p style={{ textAlign: "justify",fontFamily:"impacted" }}>
              We offer custom blockchain development services to cater to your
              unique business requirements. Our team of experienced blockchain
              developers leverages their in-depth knowledge of various
              blockchain platforms to build tailored solutions that align with
              your goals. Whether you need a permissioned private blockchain or
              a public blockchain solution, we have the expertise to deliver
              secure, scalable, and efficient systems.
            </p>
          </Col>
        </Row>
        <Row>
          {/* <Col md={4}>
          <img src={require('../../Assets/images/blockchain-1.jpg')} alt="" style={{ maxWidth: '100%', borderRadius: '10%' }} />
          </Col> */}
          {/* <Col md={4}>
            <img
              src={BLOCKCHAINSERVICES_IMG3}
              alt=""
              style={{ maxWidth: "100%", borderRadius: "10%" }}
            />
          </Col> */}
          <Col className=" pt-2 pt-lg-0">
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px" }}
            >
              <b style={{fontFamily:"impacted"}}>
                Smart Contract Development: Automate and Streamline Business
                Processes
              </b>
            </h1>
            <p style={{ textAlign: "justify",fontFamily:"impacted" }}>
              Our team specializes in smart contract development, enabling you
              to automate and streamline complex business processes. Smart
              contracts execute predefined actions based on predetermined
              conditions, reducing the need for intermediaries and increasing
              operational efficiency. We develop smart contracts on platforms
              such as Ethereum, enabling you to leverage the power of
              decentralized applications (DApps) and unlock new possibilities
              for your business.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px",fontFamily:"impacted" }}
            >
              <b>
                Blockchain Security Services: Protect Your Data and Ensure Trust
              </b>
            </h1>
            <p style={{ textAlign: "justify",fontFamily:"impacted" }}>
              Our team of blockchain experts provides comprehensive consulting
              and training services to guide you through the entire blockchain
              adoption journey. We help you understand the potential benefits
              and challenges of blockchain technology, assess its suitability
              for your business, and develop a roadmap for successful
              implementation. Our training programs equip your team with the
              necessary skills and knowledge to maximize the potential of
              blockchain technology within your organization.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px",fontFamily:"impacted" }}
            >
              <b>
                Stay Ahead with Blockchain Innovation: Continuous Support and
                Upgrades
              </b>
            </h1>
            <p style={{ textAlign: "justify",fontFamily:"impacted" }}>
              Blockchain technology is continuously evolving, and we are
              committed to keeping you at the forefront of innovation. Our team
              provides ongoing support, maintenance, and upgrades to ensure your
              blockchain systems remain secure and up to date. We stay updated
              with the latest advancements in blockchain technology, enabling us
              to recommend and implement upgrades that enhance performance,
              scalability, and functionality.
            </p>
          </Col>
          <Col md={4}>
            <img
              src={BLOCKCHAINSERVICES_IMG4}
              alt=""
              style={{ maxWidth: "100%", borderRadius: "10%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={{ textAlign: "justify",fontFamily:"impacted" }}>
              <span style={{ fontSize: "larger" }}>
                <b style={{fontFamily:"impacted",}}>
                <a
                  style={{ fontFamily: "impacted", }}
                  href="/contactus"
                >
                  Contact us today
                </a>{" "}
                </b>
              </span>
              &nbsp;to learn how your business might be transformed by our Blockchain
              Professional Services. Let's work together to unleash the
              blockchain technology's revolutionary potential and alter your
              sector. Together, we can improve security, promote innovation, and
              build a future-proof infrastructure that will help your business
              achieve success like never before.
            </p>
          </Col>
        </Row>
      </Container>
      
    </div>
  );
}

export default BlockChain;