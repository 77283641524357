export const accordionData1 = [
  {
    title: "Industry Agnostic  ",
    content:
      "Our services are industry-agnostic, meaning that we cater to a wide range of businesses, regardless of their field of expertise.",
  },
  {
    title: "Company Size",
    content:
      "We work with small startups looking to establish a secure foundation, as well as large enterprises with complex security needs.",
  },

];

export const accordionData2 = [
    {
        title: "Cloud Adoption Stage",
        content:
          "Whether you're considering cloud migration, in the midst of the transition, or already fully cloud-native, our assessments align with your cloud adoption stage.",
      },
  {
    title: "Regulatory Environment",
    content:
      "For organizations bound by strict regulatory frameworks such as HIPAA, PCI DSS, GDPR, or industry-specific compliance standards, our assessments are tailored to meet your compliance needs.",
  },
];
export const accordionData3 = [

  {
    title: "Data Sensitivity",
    content:
      "If your business handles sensitive data, such as customer information, financial records, intellectual property, or proprietary data, our assessments are critical for safeguarding your assets.",
  },

];

export const accordionData4 = [
  {
    title: "IT Complexity",
    content:
      "Our assessments are equipped to handle complex IT infrastructures, including multi-cloud environments, hybrid setups, and extensive use of containers and microservices.",
  },
  {
    title: "Budget for Security",
    content:
      "If you allocate a significant portion of your IT budget to security, our services can help you maximize the return on your investment.",
  },
];

export const accordionData5 = [
  {
    title: "Security Maturity",
    content:
      "We work with organizations at various levels of security maturity, whether you're proactive or reactive in addressing security concerns.",
  },
  {
    title: "Geographic Location",
    content:
      "We understand the regional variations in regulations and security priorities, ensuring that our assessments align with your geographic location.",
  },
];

export const accordionData6 = [
  
  {
    title: "In-House Expertise",
    content:
      "If your organization lacks specialized in-house security expertise, our assessments bridge the skills gap to enhance your security posture",
  },
  {
    title: "Cloud Service Providers",
    content:
      "Our assessments are tailored to the specific cloud service providers (e.g., AWS, Azure, Google Cloud) you use, ensuring a precise evaluation of your environment.",
  },

];

export const accordionData7 = [
 
    {
      title: "Management Buy-In",
      content:
        "We partner with organizations where senior management recognizes the importance of cloud security and is committed to addressing vulnerabilities.",
    },
    {
        title: "Long-Term Partnership Potential",
        content:
          "For those who value long-term security partnerships, we offer ongoing support and comprehensive assessments to protect your investments.",
      },
  ];

  export const accordionData8 = [

    {
      title: "Technology Stack",
      content:
        "We take into account your unique technology stack and tools, ensuring our assessments align with your existing infrastructure.",
    },
  
  ];


export const awsValueProposition = [
    {
      title: "Enhanced Security",
      content:
        "AWS Vulnerability and Penetration Testing helps customers improve the security and compliance of their infrastructure deployed on AWS by identifying and mitigating vulnerabilities and exposures.",
    },
    {
      title: "Simplified Compliance",
      content:
        "By leveraging AWS native security services like AWS Config, Security Hub, Guard Duty, and AWS Inspector, customers can streamline compliance efforts by continuously monitoring and assessing their AWS resources against best practices and predefined rules.",
    },
    {
      title: "Proactive Risk Mitigation",
      content:
        "The service enables customers to proactively identify vulnerabilities emerging from unintended network accessibility, misconfigurations, user accounts, permissions, and access management, reducing the risk of security breaches.",
    },
    {
      title: "Actionable Insights",
      content:
        "Customers receive a comprehensive report that prioritizes vulnerabilities based on their security threat level, providing detailed steps to remediate and strengthen their AWS environment.",
    },
  ];
  
  export const engagementModel = [
    "A phased approach ensures a thorough and systematic assessment of your cloud security.",
    "Flexible timeline (6 to 12 weeks) allows for customization based on the complexity of your cloud environment.",
    "Collaboration and communication throughout the engagement keep you informed and involved in the process.",
    "Actionable recommendations provide a clear path to strengthening your cloud security posture.",
    "Ongoing support options are available to maintain and enhance your security over time.",
  ];

  export const VAPT_HERO = require('../../Assets/images/Group625.png')
  export const VAPT1 = require('../../Assets/images/vapt1.png')
  export const VAPT2 = require('../../Assets/images/vapt2.png')
  export const VAPT3 = require('../../Assets/images/vapt3.png')
  export const VAPT4 = require('../../Assets/images/vapt4.png')
  export const VAPT5 = require('../../Assets/images/vapt5.png')
  export const VAPT6 = require('../../Assets/images/dzvpat.png')
  export const VAPT7 = require('../../Assets/images/vpat6.png')









  



