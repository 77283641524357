import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {
 
  CloudlandingData,
  DIGI1,
  DIGI2,
  DIGI3,
  DIGI4,
  DIGI7,

} from "./config";
import "./Amplify.css"

export default function Amplify() {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };

  return (
    <div
      style={{
        background: "#fff",
        overflowX: "hidden",
        marginTop: "100px",
      }}
    >
      <section
        className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
<Container>    
      <h1
          style={{
            ...centerAlignedText,
            fontSize: "50px",
            marginBottom: "300px",
            marginTop: "250px",
            color: "#fff",
          }}
        >
          {CloudlandingData.heroSection.title}
        </h1></Container>

      </section>

      <Container>
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "35px",
            marginBottom: "50px",
            marginTop: "20px",
            fontFamily: "impact",
          }}
        >
          {CloudlandingData.introductionSection.title}
        </h1>
        <Row className="align-items-center justify-content-center">
          <Col>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              {CloudlandingData.introductionSection.description}
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>{CloudlandingData.targetCustomersSection.title}</b>
            </h1>
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI1} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Seamless Deployment with AWS Amplify</b>
              <br/>
              With our solution, deploying your static website becomes a seamless process. We leverage the power of AWS Amplify to host your website, ensuring lightning-fast performance and reliability. No more manual configurations or tedious setups—just effortless deployment at your fingertips.  
              </p>
            </Col>
          </Row>

  
          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Streamlined Code Management with GitHub Integration</b>
              <br/>
              We understand the importance of efficient code management and version control. That's why our solution seamlessly integrates with GitHub, allowing you to manage your website's code with ease. With GitHub, you can collaborate with your team, track changes, and ensure a smooth development process, all while enjoying the benefits of AWS Amplify hosting. 
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI2} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>



          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI3} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" ,textAlign:"justify"}}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Automated Scaling for Global Reach</b>
              <br/>

              Reach audiences worldwide without compromising on performance. Our Amplify-powered hosting solution automatically scales to meet the demands of your growing user base, ensuring optimal performance regardless of geographical location. Say hello to global reach without the hassle.  </p>
            </Col>
          </Row>

          

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              <b>Enhanced Security and Reliability</b>
              <br/>

              Rest easy knowing your website is in safe hands. With AWS Amplify, we prioritize security and reliability, implementing industry-leading measures to protect your data and ensure uptime. Focus on growing your online presence with the peace of mind that your website is secure and dependable.
              </p>
            </Col>

            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DIGI4} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>


        </Row>
      </Container>

      <section
        // className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.customerEngagementSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "35vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Container>
          <Col
            // md={9}
            style={{
              color: "white",
              fontFamily: "impacted",
              textAlign: "center",
            }}
          >
            <h1 className="large-font" style={{fontSize:"26px"}}>
              {CloudlandingData.customerEngagementSection.title}
            </h1>
            <br/>
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "left",
                marginTop:"15px",
              }}
            >
              {CloudlandingData.customerEngagementSection.description}

            </p>
          </Col>
        </Container>
      </section>
      <Container>

    </Container>

      <Container
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          fontFamily: "impacted",
        }}
      >




        <Col>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <img src={DIGI7} alt="" />
        </div>
        </Col>



        <Row style={{marginBottom:"20px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{CloudlandingData.CostOptimization.title}</b>
            </h1>
          </Col>
          <Col>
            <p
              style={{
                color: "black",
                fontFamily: "impacted",
                textAlign: "left",
                marginTop:"15px",
              }}
            >
              {CloudlandingData.CostOptimization.description}

            </p>

          </Col>
        </Row>

        <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{CloudlandingData.getStartedSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12}>
          
          {CloudlandingData.getStartedSection.description}
          <a
            style={{ fontFamily: "impacted", fontSize: "23px" }}
            href={CloudlandingData.getStartedSection.contactUsLink}
          >
            <b>Contact us</b>
          </a>{" "}
          today
        </Col>
      </Container>
    </div>
  );
}