import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  AUTOMATED_EFFICIENCY,
  AWS_SERVICES,
  CENTRALIZED_AUDITING,
  CloudlandingData,
  DETAILED_REPORTING,
  DIGI1,
  DIGI2,
  DIGI3,
  DIGI4,
  DIGI5,
  DIGI6,
  DIGI7,
  PLACEHOLDER,
  REALTIME_NOTIFICATIONS,
  RESOURCE_AUDITING,
  SCALABILITY_FLEXIBILITY,
  SEAMLESS_INTEGRATION,
} from "./config";
import "./AccountAudit.css";

export default function AccountAudit() {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };

  return (
    <div
      style={{
        background: "#fff",
        overflowX: "hidden",
        marginTop: "100px",
      }}
    >
      <section
        className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <h1
            style={{
              ...centerAlignedText,
              fontSize: "60px",
              marginBottom: "300px",
              marginTop: "250px",
              color: "black",
            }}
          >
            {CloudlandingData.heroSection.title}
          </h1>
        </Container>
      </section>

      <Container>
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "35px",
            marginBottom: "50px",
            marginTop: "20px",
            fontFamily: "impact",
          }}
        >
          {CloudlandingData.introductionSection.title}
        </h1>
        <Row className="align-items-center justify-content-center">
          <Col>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              {CloudlandingData.introductionSection.description}
            </p>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>{CloudlandingData.targetCustomersSection.title}</b>
            </h1>
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={CENTRALIZED_AUDITING} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: "16px",
                }}
              >
                <b>Centralized Auditing:</b>
                <br />
                Our solution offers a centralized approach to auditing multiple
                AWS accounts, ensuring you have a complete and consistent view
                of all your resources, configurations, creation dates, and usage
                statistics.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "justify",
              }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: "16px",
                }}
              >
                <b>Automated Efficiency</b>
                <br />
                Say goodbye to manual, error-prone audits. Our automated system
                streamlines the entire process, from resource detail collection
                to report generation, saving you time and reducing the risk of
                human error.
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={AUTOMATED_EFFICIENCY} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={SCALABILITY_FLEXIBILITY} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "justify",
              }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: "16px",
                }}
              >
                <b>Scalability and Flexibility</b>
                <br />
                Designed to grow with your organization, our solution scales
                effortlessly to accommodate any number of AWS accounts, ensuring
                you remain compliant and secure as your cloud infrastructure
                expands.{" "}
              </p>
            </Col>
          </Row>
        </Row>
      </Container>

      <section
        // className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.customerEngagementSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "35vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Container>
          <Col
            // md={9}
            style={{
              color: "white",
              fontFamily: "impacted",
              textAlign: "center",
            }}
          >
            <h1 className="large-font" style={{ fontSize: "26px" }}>
              {CloudlandingData.customerEngagementSection.title}
            </h1>
            <br />
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "left",
                marginTop: "15px",
              }}
            >
              {CloudlandingData.customerEngagementSection.description}
            </p>
          </Col>
        </Container>
      </section>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>Key Features</b>
            </h1>
          </Col>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={RESOURCE_AUDITING} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: "16px",
                }}
              >
                <b>Comprehensive Resource Auditing:</b>
                <br />
                Collect detailed information about your AWS resources, including ARNs, service names, resource types, regions, and more, across all your accounts.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "justify",
              }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: "16px",
                }}
              >
                <b>Detailed Reporting</b>
                <br />
                Generate comprehensive CSV reports for each account, providing in-depth insights into your resource configurations, tags, creation dates, and last modified dates.
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={DETAILED_REPORTING} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={SEAMLESS_INTEGRATION} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "justify",
              }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: "16px",
                }}
              >
                <b>Seamless Integration</b>
                <br />
                Our solution integrates seamlessly with your existing AWS infrastructure, utilizing AWS CloudFormation for consistent and repeatable deployment across both management and member accounts.{" "}
              </p>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "justify",
              }}
            >
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: "16px",
                }}
              >
                <b>Real-time Notifications</b>
                <br />
                Stay informed with real-time email notifications for both successful audits and any issues that arise, ensuring you are always aware of the state of your resources.
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={REALTIME_NOTIFICATIONS} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>
        </Row>
      </Container>



      <Container
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          fontFamily: "impacted",
        }}
      >


        <Container>
          <Row style={{ marginBottom: "20px" }}>
            <Col
              md={12}
              style={{
                marginBottom: "20px",
                textAlign: "center",
                fontFamily: "impacted",
              }}
            >
              <h1>
                <b>{CloudlandingData.ServicesUtilized.title}</b>
              </h1>
            </Col>
            <Col>
              <ul>
                <li><b>AWS CloudFormation:</b>Ensures consistent infrastructure deployment.</li>
                <li><b>AWS Lambda:</b>Executes serverless functions for data collection and processing. </li>
                <li><b>AWS Step Functions:</b>Orchestrates the auditing workflow.</li>
                <li><b>Amazon DynamoDB:</b>Stores resource details securely and efficiently.</li>
                <li><b>Amazon S3:</b>Centralizes audit data storage and provides easy access. </li>
                <li><b>Amazon SNS:</b>Delivers real-time notifications for audit results.</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px",
            }}
          >
            <img src={AWS_SERVICES} alt="" />
          </div>
        </Col>

        <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{CloudlandingData.getStartedSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12}>
          {CloudlandingData.getStartedSection.description}{" "}
          <a
            style={{ fontFamily: "impacted", fontSize: "23px" }}
            href={CloudlandingData.getStartedSection.contactUsLink}
          >
            <b>Contact us</b>
          </a>{" "}
          now to learn more and schedule a demo.
        </Col>
      </Container>
    </div>
  );
}