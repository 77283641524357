import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AWSCLOUD_IMG,
  AZURE_IMG,
  E2E_IMG,
  GCP_IMG,
  SERVICEIMG1,
  backgroundImage2,
} from "../../Assets";
import { SERVICEIMG2 } from "../../Assets";
import { SERVICEIMG3, DZLOGO, DZT_SOLUTIONS } from "../../Assets";
import { Container, Row } from "react-bootstrap";
import "./Aboutstyles.css";
import "../../App.css";
import Portfolio from "../Testimonials/Portfolio";
const AboutSection = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <br />
        <br />
        <div className="row">
          <div className="col-md-12">
            <h2
              data-aos="fade-up"
              style={{
                fontFamily: "impact",
                textAlign: "center",
                fontSize: "34px",
                marginTop:"20px"
              }}
            >
              Our Trusted Cloud Platforms
            </h2>
            <div className="icon-box" data-aos="fade-up">
              <i className="bx bx-receipt"></i>
              <p
                data-aos="fade-up"
                style={{
                  textAlign: "justify",
                  fontFamily: "impacted",
                  fontSize: 18,
                  marginTop:"20px"
                }}
              >
                We are pleased to work with prominent cloud platforms such as
                AWS (Amazon Web Services), Azure (Microsoft Azure), and GCP
                (Google Cloud Platform). These platforms are at the forefront of
                cloud computing, providing safe and scalable solutions to
                corporations and individuals alike. We capitalize on the
                world-class infrastructure, strong services, and cutting-edge
                technology provided by these platforms to bring excellent value
                to our clients through our relationship. We ensure stability,
                performance, and flexibility using AWS, Azure, and GCP, allowing
                us to streamline our content development processes and offer
                excellent outcomes. We can easily scale our operations,
                collaborate smoothly, and manage our content workflows by
                leveraging the power of these cloud platforms. Our collaboration
                with these reputable cloud platforms allows us to concentrate on
                what we do best: delivering engaging, inventive, and powerful
                content for our clients while leveraging the greatest cloud
                technology available.
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="container">
          <div
            className="item"
            style={{
              paddingLeft: "6%",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            <img src={AWSCLOUD_IMG} width="17%" id="awsimg" alt="AWS" />
            <img
              src={AZURE_IMG}
              width="17%"
              style={{ paddingRight: "1%" }}
              id="awsimg"
              alt="Azure"
            />
            <img src={GCP_IMG} width="17%" id="awsimg" alt="GCP" />
            <img src={E2E_IMG} width="17%" id="awsimg" alt="E2E Cloud" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
