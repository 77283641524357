import React, { useRef, useState } from "react";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
import FormCom from "../Form/Form";

const Contact = () => {
  const form = useRef();
  const [showMap, setShowMap] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0m1g1yd",
        "template_7nxm4b7",
        form.current,
        "wh96Lfe5CzF3yBm7U"
      )
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section
      className="contact-section"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "100vh",
        paddingTop: "130px",
        backgroundColor: BACKGROUNDCOLOR,
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontFamily: "Impact, sans-serif",
          background: "linear-gradient(to right, #0C1A4A, #000000)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          marginBottom: "50px",
          fontSize: "3rem",
        }}
      >
        Contact Us
      </h1>
      <Container>
        <div
         
        >
          <Row>
            {/* Form Section */}
            <Col lg={6} md={12}>
              <FormCom />
            </Col>

            {/* Contact Info Section */}
            <Col lg={6} md={12} style={{ paddingLeft: "20px", marginTop: "20px" }}>
              <div
                style={{
                  backgroundColor: "#0C1A4A",
                  borderRadius: "20px",
                  padding: "20px",
                  color: "#fff",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                }}
              >
                <h2 style={{ fontFamily: "Impact, sans-serif", color: "#FFD700" }}>
                  Get in Touch
                </h2>
                <div style={{ marginBottom: "20px" }}>
                  <h5 style={{ color: "#FFD700", fontSize: "1.2rem" }}>
                    <FaMapMarkerAlt style={{ marginRight: "10px" }} />
                    Location
                  </h5>
                  <p>
                    Head Office:
                    <br /> 2nd floor, 208 Ramya Nivasa, 7th Cross Shakthi Nagar,
                    <br /> Near Cloud Nine Hospital,
                    <br /> Horamavu, Bangalore - 560043
                  </p>
                  <p>
                    16192 Coastal Highway
                    <br />
                    Lewes, DE 19958, Sussex County
                    <br /> United States
                  </p>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h5 style={{ color: "#FFD700", fontSize: "1.2rem" }}>
                    <FaPhone style={{ marginRight: "10px" }} />
                    Phone
                  </h5>
                  <p>+91 80 35001542</p>
                  <p>+91 80 35001543</p>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <h5 style={{ color: "#FFD700", fontSize: "1.2rem" }}>
                    <FaEnvelope style={{ marginRight: "10px" }} />
                    Email
                  </h5>
                  <p>
                    <a
                      href="mailto:careerinfo@eshatechgroup.com"
                      style={{ color: "#FFD700", textDecoration: "none" }}
                    >
                      careerinfo@eshatechgroup.com
                    </a>
                  </p>
                  <p>
                    <a
                      href="mailto:sales@eshatechgroup.com"
                      style={{ color: "#FFD700", textDecoration: "none" }}
                    >
                      sales@eshatechgroup.com
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          {/* Map Section */}
          <Row>
            <Col>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.2274188741208!2d77.64922911577553!3d13.02118479082336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112bfca27b21%3A0x2c3b82b3a90dd91c!2sDhruthZuci%20Tech%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1588062678971!5m2!1sen!2sin"
                width="100%"
                height="400"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                style={{
                  borderRadius: "20px",
                  marginTop: "20px",
                  border: "none",
                }}
              ></iframe>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
