export const CLOUDLANDING_HERO = require("../../Assets/amplify/Global-Reach1.jpg");
export const DBLUE_RECTANGLE = require("../../Assets/images/Rectangle1.png");
export const LBLUE_RECTANGLE = require("../../Assets/images/Rectangle2.png");


export const DIGI1 = require("../../Assets/amplify/deployment.png");
export const DIGI2 = require("../../Assets/amplify/github_integration.png");
export const DIGI3 = require("../../Assets/amplify/automated_scaling.jpg");
export const DIGI4 = require("../../Assets/amplify/security.jpg");
export const DIGI7 = require("../../Assets/amplify/get-started.png");

export const CloudlandingData = {
  heroSection: {
    title: "Simplify Website Hosting Using Amplify",
    backgroundImage: CLOUDLANDING_HERO,
  },
  introductionSection: {
    title:
      "Introduction",
    description:
      "At DhruthZuci, we're thrilled to introduce you to our Amplify-powered hosting solution, designed to revolutionize your website hosting experience. Say goodbye to complexities and hello to simplicity, speed, and scalability.",
  },


  targetCustomersSection: {
    title: "Why is Amplify necessary?",
    description:
      "Our solution is a perfect fit for a wide array of organizations, including:",
    targetCustomers: [
      {
        image: DIGI1,
        text: [
          {
            title: "Seamless Deployment with AWS Amplify ",
            description:
              "With our solution, deploying your static website becomes a seamless process. We leverage the power of AWS Amplify to host your website, ensuring lightning-fast performance and reliability. No more manual configurations or tedious setups—just effortless deployment at your fingertips.",
          },
        ],
      },
      {
              text: [
          {
            title: "Enterprises:",
            description: "Robust security measures for large organizations.",
          },
        ],
        image: DIGI2,

      },
      {
        image: DIGI3,
        text: [
          {
            title: "User-Centric Applications:",
            description:
              "Ideal for apps where user experience and security are paramount.",
          },
        ],
      },
      {
        image: DIGI4,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
    ],
  },
  customerEngagementSection: {
    title: "Conclusion: Elevate Your Online Presence Today",
    description:
      "Congratulations on discovering our Amplify-powered hosting solution. Experience the simplicity, speed, and scalability that sets us apart from the rest. Say hello to a hosting experience that prioritizes your needs and empowers you to succeed online.",


    backgroundImage: DBLUE_RECTANGLE,
  },
  deliveryMechanismsSection: {
    title: "The Team behind Digital Grain Identifier ",
    description: "Smooth Transition and Ongoing Support. Rest assured, we are with you every step of the way. Our transition plan, training, and post-implementation support guarantee a seamless shift to your enhanced AWS environment.",
    // image: CLOUDLANDING6,

  },
  AWSValueProposition: {
    title: "AWS Value Proposition",
    description: "Experience the unparalleled advantages of integrating with Amazon Web Services (AWS) for your project. Achieve cost-effectiveness through flexible cost management, resource optimization, and enhanced efficiency. Ensure seamless scalability to meet evolving demands, supporting future growth and maintaining optimal system performance. Benefit from AWS's reliable and redundant infrastructure, minimizing downtime and ensuring uninterrupted system access. Prioritize data security with AWS's secure environment, adhering to industry standards. Simplify deployment and maintenance processes, enhancing overall system manageability. Elevate your project with AWS and unlock a new level of performance and efficiency",

  },

  WhoCanBenefit: {
    title: "Who Can Benefit",
    description: "Discover our advanced web-based system revolutionizing rice variety identification through image classification. Ideal for agricultural researchers, seed producers, farmers, extension services, technology providers, biodiversity conservation organizations, and educational institutions. Our solution offers precise and efficient solutions for rice cultivation, breeding, and crop diversity management. Experience tools tailored for expedited research, seed quality assurance, empowered crop management, educational support, and technological innovation. Uncover the significance of rice variety identification and the application of machine learning in agriculture with our practical teaching tool. Elevate your practices and contributions to the agricultural industry with our state-of-the-art system.",

  },

  EngageWithTechnology: {
    title: " Engage with Cutting-edge Technology",
    description: " Explore our Customer Engagement and Delivery Mechanisms, focusing on an innovative web-based system for automated rice variety identification. Leveraging a robust machine learning model, a comprehensive rice seed image dataset, and AWS services ensures unparalleled accuracy and efficiency. With a foundation built on diverse data and optimized deep learning, our user-friendly interface guarantees a seamless experience. Error handling mechanisms enhance user satisfaction. The integration with AWS for backend processing ensures optimal system performance. Elevate your engagement with cutting-edge technology for precise rice variety classification.",

  },

  MilestonesAndFeedback: {
    title: "Milestones and Feedback",
    description: "Our project follows a structured process. Pre-Engagement Assessment ensures thorough understanding, and Milestone-Defined Checkpoints keep the project on track. Post-Engagement Assessment evaluates outcomes, performance, and lessons learned.",

  },

  CostOptimization: {
    title: "Ready to Get Started?",
    description: "Embark on a journey towards elevated website hosting with DhruthZuci Tech Solutions. Experience the future of hosting with our Amplify-powered solution. Contact us today to learn more about how we can transform your online presence and help you achieve your digital goals.",
  },

  getStartedSection: {
    title: "Contact Us",
    description:
      " To unlock the full potential of your website today!  ",
    contactUsLink: "/contactus",
  },
};