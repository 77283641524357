export const CSA = {
  Author:"Suhas A",
  title: "CLOUD SECURITY ASSESMENT",
  title2: "A smart blog on Cloud Migration that works 24/7 for your company",
  content1:
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo",

};

export const CM = {
  Author:"Suhas A",
  title: "CLOUD MIGRATION",
  title2: "A smart blog on Cloud Migration that works 24/7 for your company",
  content1:
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo",

};

export const CB = {
  Author:"Suhas A",
  title: "CONVERSATIONAL CHATBOT",
  title2: "A smart blog on Conversational ChatBot that works 24/7 for your company",
  content1:
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo",

};

export const WM = {
  Author:"Suhas A",
  title: "WORKSPACE MODERNIZATION WITH OFFICE 365",
  title2: "A smart blog on Workspace Modernization with Office 365 that works 24/7 for your company",
  content1:
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo",

};