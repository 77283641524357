import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { servicesData } from "../../Config/config";
import { AWSCLOUD_IMG, AZURE_IMG, GCP_IMG, E2E_IMG } from "../../Assets";
import "./Services.css";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
import RotatingCard from "../../BlogPosts/Rotating";

const Services = () => {
  return (
    <div
      style={{
        background: BACKGROUNDCOLOR,
      }}
    >
      <Container style={{ }}>
        <div
          className="section-title"
          data-aos="fade-up"
          style={{
            textAlign: "center",
            padding: "10px",
            borderRadius: "20px",
            marginTop:"120px",
          }}
        >
          <h1
            style={{
              fontFamily: "impact",
              margin: "0",
            }}
          >
            Our Services
          </h1>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col
              lg={12}
              style={{
                textAlign: "center",
                paddingBottom: "20px",
                fontFamily: "impacted",
              }}
            >
              <h3 style={{ color: "black" }}>
                We offer a wide range of services to meet your IT infrastructure
                needs. Explore our services below.
              </h3>
            </Col>
          </Row>
          <Row>
            {servicesData.map((service, index) => (
            <Col lg={4} key={index} className="mb-4" id="cards">
            <Card
              className="h-100 text-center"
              id="cloud"
              style={{
                boxShadow: "0px 0px 20px rgba(0, 0, 255, 0.2)",
                borderRadius: "20px",
                overflow: "hidden",
              }}
            >
                  <Card.Header
                    align="center"
                    style={{
                      fontFamily: "impact",
                      color: "#fff",
                      fontSize: "20px",
                      background: "#0C1A4A",
                    }}
                  >
                    {service.title}
                  </Card.Header>
                  <Card.Body>
                    <div
                      className="image-zoom"
                      style={{
                        position: "relative",
                        height: "60%",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className="img-fluid mb-4"
                        align="center"
                        src={service.img}
                        alt="Cloud Advisory"
                        style={{ width:"80%"}}
                      />
                    </div>
                    <p
                      className="card-text"
                      style={{
                        paddingTop: "4%",
                        fontFamily: "impacted",
                        textAlign: "justify",
                      }}
                    >
                      {service.description}
                    </p>
                  </Card.Body>
                  <Button
                    href={service.rout}
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "white",
                      border: "0",
                      background: "#ffff",
                    }}
                  >
                    <button
                      className="rounded"
                      style={{
                        padding: "10px",
                        background: "linear-gradient(to top, #387FD3, #7B3EAC)",
                        fontFamily: "impacted",
                        color: "#fff",
                      }}
                    >
                      Learn More
                    </button>
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <div className="container">
          <div
            className="item"
            style={{ paddingLeft: "6%", textAlign: "center" ,marginTop:"40px"}}
          >
            <h1 style={{ fontSize: "25px", fontFamily: "impact" }}>
              Our Trusted Cloud Platforms:
            </h1>
            <img src={AWSCLOUD_IMG} width="17%" id="awsimg" alt="AWS" />
            <img
              src={AZURE_IMG}
              width="17%"
              style={{ paddingRight: "1%" }}
              id="awsimg"
              alt="Azure"
            />
            <img src={GCP_IMG} width="17%" id="awsimg" alt="GCP" />
            <img src={E2E_IMG} width="17%" id="awsimg" alt="E2E Cloud" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Services;