import { PASSWORDLESS1 } from "../PasswordlessAuth/config";

export const CLOUDLANDING_HERO = require("../../Assets/images/cloudlanding_hero.png");
export const DBLUE_RECTANGLE = require("../../Assets/images/Rectangle1.png");



export const CLOUDLANDING1 = require("../../Assets/images/cloudlanding1.png");
export const CLOUDLANDING2 = require("../../Assets/images/cloudlanding2.png");
export const CLOUDLANDING3 = require("../../Assets/images/cloudlanding3.png");
export const CLOUDLANDING4 = require("../../Assets/images/cloudlanding4.png");
export const CLOUDLANDING5 = require("../../Assets/images/cloudlanding5.png");
export const CLOUDLANDING6 = require("../../Assets/images/cloudlanding6.png");

export const CloudlandingData = {
  heroSection: {
    title: "Unlock the Power of AWS Control Tower",
    backgroundImage: CLOUDLANDING_HERO,
  },
  introductionSection: {
    title:
      "Are you ready to charge your AWS cloud environment for peak efficiency, security and compliance?",
    description:
      "You're in the right place! Welcome to our dedicated page on AWS Control Tower, where we unveil the extraordinary capabilities of this transformational solution.",
  },


  targetCustomersSection: {
    title: "Why Choose AWS Control Tower?",
    description:
      "Our solution is a perfect fit for a wide array of organizations, including:",
    targetCustomers: [
      {
        image: CLOUDLANDING1,
        text: [
          {
            title: "Startups and Small Businesses:",
            description:
              "Explore the Benefits of AWS Control Tower Discover how AWS Control Tower can revolutionise your AWS landscape. Bid farewell to the complexities of account setup and configuration, and say hello to a centralised, well-architected, and ultra-secure multi-account AWS environment. Whether you're a dynamic startup or a well-established enterprise, AWS Control Tower is designed with you in mind.",
          },
        ],
      },
      {
              text: [
          {
            title: "Enterprises:",
            description: "Robust security measures for large organizations.",
          },
        ],
        image: CLOUDLANDING2,

      },
      {
        image: CLOUDLANDING3,
        text: [
          {
            title: "User-Centric Applications:",
            description:
              "Ideal for apps where user experience and security are paramount.",
          },
        ],
      },
      {
        image: CLOUDLANDING5,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
      {
        image: CLOUDLANDING6,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
    ],
  },
  customerEngagementSection: {
    title: "Meet Our Team",
    description:
      "Meet the experts behind Your AWS Control Tower journey Get to know the dedicated team driving your AWS Control Tower journey, including our seasoned project manager and technical consultants. We are committed to ensuring the success of your AWS environment.",


    backgroundImage: DBLUE_RECTANGLE,
  },
  deliveryMechanismsSection: {
    title: "Seamless Handover and Support",
    description: "Smooth Transition and Ongoing Support. Rest assured, we are with you every step of the way. Our transition plan, training and post-implementation support guarantee a seamless shift to your enhanced AWS environment.",
    image: CLOUDLANDING6,

  },

  UnlockPotentialSection: {
    title: "Unlock Your AWS Potential",
    description: "Unleash the full potential of AWS with AWS Control Tower.With AWS Control Tower and our expert guidance, the future of your AWS cloud environment is not only efficient and secure but also cost-effective. Discover a new dimension of cloud potential.",

  },

  WelcomeToFutureSection: {
    title: "Welcome to the Future of AWS Management",
    description: "Embrace the future of AWS Management.Step into the future of AWS management.Get ready to elevate your cloud infrastructure to new heights with AWS Control Tower.",

  },
  getStartedSection: {
    title: "Ready to Get Started?",
    description:
      " to begin your AWS Control Tower implementation project let's embark on this journey together. Reach out to us to discuss your AWS Control Tower implementation project.",
    contactUsLink: "/contactus",
  },
};