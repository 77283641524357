import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaDesktop, FaCloud, FaUserShield, FaExpand, FaDollarSign, FaWrench, FaUserTie, FaShieldAlt, FaRobot, FaSeedling, FaSync, FaLeaf } from "react-icons/fa";
import { CloudlandingData } from "../Pages/DiGI/config";

const SmbWorkspace = () => {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };
    const cardStyle = {
        borderRadius: "15px",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
        backgroundColor: "white",
      };
  return (
    <div>
    <section
    className="py-5"
    style={{
      backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      backgroundPosition: "center",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
{/* <Container>    
  <h1
      style={{
        ...centerAlignedText,
        fontSize: "50px",
        marginBottom: "300px",
        marginTop: "250px",
        color: "#fff",
      }}
    >
      {CloudlandingData.heroSection.title}
    </h1></Container> */}

  </section>

    <Container style={{ marginTop: "100px", fontFamily: "Arial, sans-serif" }}>
      {/* Header Section */}

      <Row className="mb-5 text-center">
        <Col>
          <h1 style={{ fontWeight: "bold", color: "#007bff" }}>
          Empower Your SMB with Flexible, Secure Workspace Management
          </h1>
          <p className="lead" style={{ fontSize: "1.2rem", color: "#6c757d" }}>
          “Give your team the freedom to work anytime, anywhere, while you maintain complete control and peace of mind. Get started quickly without the burden of enterprise-level costs.”
          </p>
          <Button variant="primary" size="lg">
            Try It Now
          </Button>
        </Col>
      </Row>

      {/* Solutions Section */}
      <Row className="mb-5 text-center">
        <Col>
          <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
           The Perfect Workspace Solution for Modern SMBs
          </h2>
          <p style={{ fontSize: "1rem", color: "#6c757d" }}>
            {/* From effortless setup to scalable management, we've got you covered. */}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} lg={3} className="mb-4">
          <Card className="shadow-sm border-0">
            <Card.Body>
              <FaDesktop size={40} className="mb-3 text-primary" />
              <Card.Title className="font-weight-bold">
              Quick Setup, Immediate Results:
              </Card.Title>
              <Card.Text>
              Launch your workspace management system within hours, not days. Our intuitive platform means your team can get started with minimal technical expertise, ensuring fast adoption and immediate productivity.”
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={3} className="mb-4">
          <Card className="shadow-sm border-0">
            <Card.Body>
              <FaCloud size={40} className="mb-3 text-success" />
              <Card.Title className="font-weight-bold">
              Flexibility for a Dynamic Workforce:
              </Card.Title>
              <Card.Text>
              Adapt to the new generation’s demand for flexibility without sacrificing control. Enable your team to access their secure virtual desktops from anywhere, giving them the freedom they need to thrive.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={3} className="mb-4">
          <Card className="shadow-sm border-0">
            <Card.Body>
              <FaUserShield size={40} className="mb-3 text-warning" />
              <Card.Title className="font-weight-bold">
              Complete Control Without Complexity:
              </Card.Title>
              <Card.Text>
              With built-in governance tools, business owners can easily enforce policies, manage user access, and ensure compliance—all without the need for complex IT management or high costs.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} lg={3} className="mb-4">
          <Card className="shadow-sm border-0">
            <Card.Body>
              <FaExpand size={40} className="mb-3 text-danger" />
              <Card.Title className="font-weight-bold">
              Seamless Integration and Security:
              </Card.Title>
              <Card.Text>
              Rest easy knowing that data and user access are protected with enterprise-grade security through Microsoft Entra integration. Your sensitive information stays safe, while you maintain full visibility.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Benefits Section */}
      <Container style={{ marginTop: "5rem" }}>
        <Row className="mb-5 text-center">
          <Col>
            <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
            Empower Your Business, Simplify Management
            </h2>
            <p style={{ fontSize: "1rem", color: "#6c757d" }}>
              {/* Discover how Dhruthzuci transforms workspace management for your business. */}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaDollarSign size={40} className="mb-3 text-primary" />
                <Card.Title className="font-weight-bold">
                Streamlined Operations, Lower Costs:
                </Card.Title>
                <Card.Text>
                Enjoy robust workspace management features without needing expensive enterprise licenses. Keep your operational expenses low while optimizing your workforce’s productivity.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaWrench size={40} className="mb-3 text-success" />
                <Card.Title className="font-weight-bold">
                Reduced IT Dependence:
                </Card.Title>
                <Card.Text>
                Our platform is designed to be user-friendly, minimizing the reliance on IT support. Let your team manage their workspaces with ease, while your IT staff focuses on strategic growth.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaUserTie size={40} className="mb-3 text-warning" />
                <Card.Title className="font-weight-bold">
                Enhanced Workforce Satisfaction:
                </Card.Title>
                <Card.Text>
                Today’s employees value flexibility and efficiency. Provide a seamless, user-centric experience that supports their productivity and well-being.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaShieldAlt size={40} className="mb-3 text-danger" />
                <Card.Title className="font-weight-bold">
                Data Security You Can Trust:
                </Card.Title>
                <Card.Text>
                Security and compliance are built into the core of our solution, ensuring your data is protected with multi-factor authentication, encryption, and automated audit trails.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
 
  {/* User Stories Section */}
  <Row className="mb-5 text-center">
        <Col>
          <h2 className="fw-bold">User Stories & Testimonials</h2>
          <p className="text-muted">
            Hear from our happy users about their experiences with Dhruthzuci.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <Card style={cardStyle}>
            <Card.Body>
              <Card.Title>End-User Experience</Card.Title>
              <Card.Text>
                “With Dhruthzuci’s workspace solution, I can easily access my work environment from home or on-site
                without relying on IT support. It’s a game-changer for our team’s flexibility.” — Alex, Project Manager
                at SmartSolutions Co.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="mb-4">
          <Card style={cardStyle}>
            <Card.Body>
              <Card.Title>Business Perspective</Card.Title>
              <Card.Text>
                “Switching to this solution allowed us to reduce operational costs by 40% and remove the complexity of
                managing enterprise licenses. Our team is now more agile and productive.” — Sarah, Operations Head at
                GreenTech Corp.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Call-to-Action Section */}
      <Row className="mt-5 text-center">
        <Col>
          <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
          Take the First Step Towards Smarter Workspace Management
          </h2>
          <p style={{ fontSize: "1rem", color: "#6c757d" }}>
          Join SMBs across the globe who are revolutionizing how they manage their teams. Experience the freedom, security, and control your business deserves—without breaking the bank.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="shadow-sm border-0">
            <Card.Body className="text-center">
              <Card.Title style={{ fontWeight: "bold", color: "#007bff" }}>
                Ready to Transform Your Workspace?
              </Card.Title>
              <Card.Text>
                Submit your details to revolutionize how you manage your workspaces.
              </Card.Text>
              <Button variant="primary" size="lg" block>
              <a
                  style={{ fontFamily: "impacted",color:"#fff" }}
                  href="/contactus"
                >
                Get Started</a>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>  </div>
  );
};

export default SmbWorkspace;
