import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  PASSWORDLESS2,
  PASSWORDLESS3,
  PASSWORDLESS4,
  PASSWORDLESS5,
  solutionsData,
  PASSWORDLESS_CUSTOMER,
  PWD_ARCHI,
  USERCASESTUDY,
  KEYFEATURES
} from "./config";
import "./Astuto.css";

export default function Astuto() {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };
  const backgroundImageStyle = {
    background: `url(${solutionsData.heroSection.backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",  
    height: "100vh", 
  };

  return (
    <div
      style={{
        overflowX: "hidden",
        marginTop: "100px",
      }}
    >
      <section
        className="py-5"
        style={{
          ...backgroundImageStyle,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "50px",
            marginBottom: "50px",
            marginTop: "470px",
            color: "#fff",
          }}
        >
          {solutionsData.heroSection.title}
        </h1>
        
      </section>

      <Container>
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "35px",
            marginBottom: "30px",
            marginTop: "20px",
            fontFamily: "impacted",
          }}
        >
         <b> {solutionsData.introductionSection.title}</b>         
        </h1>
        <p style={{ textAlign: "justify", fontFamily: "impacted", marginBottom:"30px" }}>
              {solutionsData.introductionSection.description}
            </p>
        <Row className="align-items-center justify-content-center">
          <Col md={6} xs={12}>
            <h1
              className="large-font"
              style={{  textAlign:"center", fontFamily:"impacted" }}
            >
              <b>The Cloud Cost Conundrum</b>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              {solutionsData.introductionSection.description}
            </p>
          </Col>
          <Col md={6} xs={12}>
            <img
              src={solutionsData.introductionSection.image}
              alt=""
              style={{ width: "100%", marginBottom: "20px" }}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={12} style={{marginTop: "10px"}}>
          <h1
              className="large-font"
              style={{  textAlign:"center" , fontFamily: "impacted"}}
            >
              <b>{solutionsData.serviceDetailsSection.title}</b>
            </h1>
            <p style={{ fontFamily: "impacted" }}>
              {solutionsData.serviceDetailsSection.description}
            </p>
          </Col>

        </Row>
      </Container>
      <Container>
        <Row className="align-items-center justify-content-center">
        <Col md={6} xs={12}>
            <h1
              className="large-font"
              style={{  textAlign:"center", fontFamily:"impacted" }}
            >
              <b>{solutionsData.serviceDetailsSection.title2}</b>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              {solutionsData.serviceDetailsSection.description2}
            </p>
          </Col>
          <Col md={6} xs={12}>
            <img
              src={PWD_ARCHI}
              alt=""
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          </Col>
        </Row>
      </Container>
      <section
        style={{
          backgroundImage: `url(${solutionsData.useCasesSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Col
            
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1
              className="large-font"
              style={{ fontSize: "26px" }}
            >
              <b>{solutionsData.useCasesSection.title}</b>
            </h1>
            <br />
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "left",
                marginTop:"15px"

              }}
            >
              <b>{solutionsData.useCasesSection.description}</b>
            </p>
            <ul
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "justify",
              }}
            >
              {solutionsData.useCasesSection.useCases.map((useCase, index) => (
                <li key={index}>
                  <b style={{ color: "#FF6700", textAlign: "center" }}>
                    
                    {useCase.title}:
                  </b>{" "}
                  <b>{useCase.description}</b>
                </li>
              ))}
            </ul>
            <img
              src={KEYFEATURES}
              alt=""
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          </Col>
          
        </Container>
      </section>

      <section
        style={{
          backgroundImage: `url(${solutionsData.userCaseStudy.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col md={9} xs={8} style={{ fontFamily: "impacted" }}>
          <h1
            className="large-font"
            style={{ ...centerAlignedText, fontSize: "26px" , marginTop: "20px"}}
          >
            <b>{solutionsData.userCaseStudy.title}</b>
          </h1>
          <p style={centerAlignedText}>
            {" "}
            {solutionsData.userCaseStudy.description}
          </p>
          <Row className="align-items-center justify-content-center">
          <ul
              style={{
                color: "black",
                fontFamily: "impacted",
                textAlign: "justify",
              }}
            >
              {solutionsData.userCaseStudy.useCases.map((useCase, index) => (
                <li key={index}>
                  <b style={{ color: "#FF6700", textAlign: "center" }}> 
                    {useCase.title}:
                  </b>{" "}
                  <b>{useCase.description}</b>
                </li>
              ))}
            </ul>
          </Row>
          <img
              src={USERCASESTUDY}
              alt=""
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
        </Col>
      </section>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
         <Row> 
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>{solutionsData.targetCustomersSection.title}</b>
            </h1>
          </Col>
          <Col md={12} xs={12} style={{ textAlign: "left" }}>
            <br />

            <p>{solutionsData.targetCustomersSection.description}</p>
          </Col>

          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={PASSWORDLESS2} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <p style={{ textAlign: "center", fontFamily: "impacted" }}>
                <b>Join the VittaOps Revolution</b> From multinational corporations to innovative startups, businesses worldwide are harnessing the power of VittaOps to dominate the cloud. Join the VittaOps revolution today and unleash the true potential of your cloud infrastructure.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center ">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px", textAlign:"center" }}
            >
              <p>
                <b>VittaOps: Your Partner in Cloud Mastery</b>At VittaOps, we believe in more than just managing the cloud – we empower businesses to dominate it. With VittaOps by your side, you can navigate the complexities of cloud spending with confidence, unlocking unprecedented value and efficiency. 
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={PASSWORDLESS3} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={PASSWORDLESS4} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px", textAlign:"center" }}
            >
              <p>
                <b>Experience the VittaOps Difference </b> Don't let cloud costs spiral out of control. Experience the VittaOps difference and embark on a journey towards cloud mastery today. 
              </p>
            </Col>
          </Row>

        </Row>
      </Container>




      <Container
        style={{
          marginTop: "80px",
          marginBottom: "50px",
          fontFamily: "impacted",
        }}
      >



        <Col
          md={12}
          style={{
        
            textAlign: "center",
            fontFamily: "impacted",
            marginTop:"60px"
          }}
        >
          <h1>
            <b>{solutionsData.getStartedSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12}>
          {solutionsData.getStartedSection.line1}&nbsp;
          <a
            style={{ fontFamily: "impacted", fontSize: "23px" }}
            href={solutionsData.getStartedSection.contactUsLink}
          >
            <b>Contact us</b>
          </a>
          &nbsp;{solutionsData.getStartedSection.line2} <br/>
          <b>{solutionsData.getStartedSection.line3}</b>
        </Col>
      </Container>
    </div>
  );
}