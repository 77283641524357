export const LOGO = require("./logo6.png");
export const bg = require("./images/bg.jpg");
export const IMG1 = require("./images/block.png");
export const FORM = require("./images/Form.jpg");
export const SUCESS_IMG = require("./images/sucess.png");

export const backgroundImage2 = require("./bg2.png") 
export const linkedin = require("./images/LinkedIn.png") 
export const mail = require("./images/Outlook.png") 
export const call = require("./call.png") 
export const whatsapp = require("./images/WhatsApp.png")
export const welcome = require("./welcome.png")
export const backgroundImage = require("./images/Group623.png") 
// Home Page
//We offer
export const WEOFFER_IMG = require("./images/index2.PNG");
// Infrastructure Services
export const SERVICEIMG1 = require("./images/Infrastructure-Services.png");
//Application and Software Services
export const SERVICEIMG2 = require("./images/sds.jpg");
//Sustainability Services
export const SERVICEIMG3 = require("./images/SustainabilityServices.png");
//Dhruthzuci provides your one-stop shop for cloud solutions
export const DZLOGO = require("./images/dztech@3x-8.png");
//What's the deal with Dhruthzuci?
export const DZT_SOLUTIONS = require("./images/index2.PNG");

// Slider images
//Discover Our Services
export const SLIDER1 = require("./images/services-icons.jpg");
//Join Our Community
export const SLIDER2 = require("./images/cloud-technologies_0.jpg");
//Transform Your Business
export const SLIDER3 = require("./images/image1.jpg");

//clients
export const GROWTHAVEN_IMG = require("./images/GrowthAvenue.jfif");
export const PRESIDIO_IMG = require("./images/GreenPages.jpg");
export const GREENPAGES_IMG = require("./images/Presidio.webp");

// About us
export const ABOUTUS_IMG = require("./images/roundtable.png");
export const Leader = require("./images/img2.png");
//Services page
export const AWSCLOUD_IMG = require("./images/aws11.png");
export const AZURE_IMG = require("./images/azzz.png");
export const GCP_IMG = require("./images/gcccc.png");
export const E2E_IMG = require("./images/E2E Cloud.png");

//Professional services page
export const PROFESSIONALSERVICES_IMG2 = require("./images/cloud_advisory1.png");
export const PROFESSIONALSERVICES_IMG3 = require("./images/ca.webp");
export const PROFESSIONALSERVICES_IMG4 = require("./images/dev-sec-op.png");

//Blockchain services
export const BLOCKCHAINSERVICES_IMG2 = require("./images/blockChain.png");
export const BLOCKCHAINSERVICES_IMG3 = require("./images/blockchain.webp");
export const BLOCKCHAINSERVICES_IMG4 = require("./images/blockchain-1.jpg");

//O365 services
export const O365SERVICES_IMG2 = require("./images/office.png");
export const O365SERVICES_IMG3 = require("./images/365.jpg");

//Full Stack Development services
export const FULLSTACKSERVICES_IMG2 = require("./images/full-stack.png");
export const FULLSTACKSERVICES_IMG3 = require("./images/mobile1.png");
export const FULLSTACKSERVICES_IMG4 = require("./images/web-development.avif");

//About us
export const KARTHIK_IMG = require("./images/karthik1.jpg");
export const MOULI_IMG = require("./images/mouli.png");
export const SUHAS_IMG = require("./images/suhas.png");
export const Goals= require("./images/goals.png");
export const Vision = require("./images/vision.png");

//Events
export const EVENTS_IMG = require("./images/coming_soon.png");

//videos
export const V1 = "https://d5u6tkfodba5c.cloudfront.net/video/VAPT1.mp4";
export const V2 = "https://d5u6tkfodba5c.cloudfront.net/video/CM.mp4";
export const V3 = "https://d5u6tkfodba5c.cloudfront.net/video/Lex.mp4";
export const V4 = "https://d5u6tkfodba5c.cloudfront.net/video/O365.mp4";

//gifs
export const G1 = "https://d5u6tkfodba5c.cloudfront.net/video/vpt.gif";
export const G2 = "https://d5u6tkfodba5c.cloudfront.net/video/CM.gif";
export const G3 = "https://d5u6tkfodba5c.cloudfront.net/video/Lex.gif";
export const G4 = "https://d5u6tkfodba5c.cloudfront.net/video/O365.gif";

//VAPT
export const VAPT = require("./images/dzvpat.png");
export const CM_IMG = require("./images/CM.png");
export const VAPT2 = require("./images/vapt2.png");

//CHATBOT
export const CB_IMG = require("./images/lex.png");
export const WM_IMG = require("./images/365.png");
export const CHATBOT = require("./images/ChatBot.png");

//CloudMigration
export const CLOUDMIGRATION = require("./images/cloud_migration.png");
