

// import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Container, Row, Col, Accordion } from "react-bootstrap";
// import {
//   BLOCKCHAINSERVICES_IMG2,
//   BLOCKCHAINSERVICES_IMG3,
//   BLOCKCHAINSERVICES_IMG4,
//   VAPT,
// } from "../../Assets";
// import { BACKGROUNDCOLOR } from "../../Themes/theme";
// import Table from "./table";
// import { awsValueProposition, engagementModel } from "./config";

// export default function VATmain() {
//   return (
//     <div style={{ background: BACKGROUNDCOLOR, overflowX: "hidden" }}>
//       <Container style={{marginTop:"120px"}}>
//         <Row>
//           <Col >
//             <h1
//               className="xlarge-font"
//               style={{ color: "black", fontFamily: "impacted" }}
//             >
//               <b>
//                 Dhruthzuci Tech - Cloud Security and Vulnerability Assessment:
//                 Tailored for Your Needs
//               </b>
//             </h1>
//             <h1
//               className="large-font"
//               style={{
//                 color: "black",
//                 fontSize: "30px",
//                 fontFamily: "impacted",
//               }}
//             >
//               <b>Experience and Knowledge:</b>
//             </h1>
//             <p style={{ textAlign: "justify" }}>
//               At Dhruthzuci, we offer comprehensive cloud security and
//               vulnerability assessment services designed to ensure the robust
//               protection of your digital assets. Our services are tailored to
//               meet the unique needs of your organization, whether you operate in
//               healthcare, finance, manufacturing, or any other industry. We
//               understand that security is not one-size-fits-all, which is why we
//               take a personalized approach to safeguarding your cloud
//               infrastructure.
//             </p>
//           </Col>
//           {/* <Col md={4}>
//             <img
//               src={BLOCKCHAINSERVICES_IMG2}
//               alt=""
//               style={{ width: "100%" }}
//             />
//           </Col> */}
//         </Row>
//         <Container>
//           <Row>
//             <Col>
//               <h1 style={{ fontFamily: "impacted", textAlign: "center" }}>
//                 <b>Who Can Benefit from Our Services?</b>
//               </h1>

//               <br />
//             </Col>
//             <div>
//               <Table />
//             </div>
//           </Row>
//           <Row>
            
//           </Row>
//           <div>
//             <img
//               className="vpt"
//               src={VAPT}
//               alt="AWS Vulnerability and Penetration Testing"
//               style={{ width: "100%" }}
//             />
//           </div>
//         </Container>
   
//           <Container  >
//             <Row style={{  }}>
//               <Col >
//                 <p style={{ fontSize: "25px", fontFamily: "impacted" }}>
//                   <b>AWS Value Proposition:</b>
//                 </p>
//                 <ul style={{ fontFamily: "impacted" }}>
//                   {awsValueProposition.map((item, index) => (
//                     <li key={index}>
//                       <span style={{ fontSize: "larger" }}>
//                         <u>{item.title}:</u>
//                       </span>
//                       <br /> {item.content}
//                     </li>
//                   ))}
//                 </ul>
//                 <p style={{ fontSize: "25px", fontFamily: "impacted" }}>
//                   <b>Engagement model</b>
//                 </p>
//                 <ul style={{ fontFamily: "impacted" }}>
//                   {engagementModel.map((item, index) => (
//                     <li key={index}>{item}</li>
//                   ))}
//                 </ul>
//                 <p style={{ fontFamily: "impacted" }}>
//                   This engagement model emphasizes a structured, collaborative,
//                   and results-oriented approach to cloud security assessment,
//                   ensuring that your organization's security needs are met
//                   efficiently and effectively over a timeline tailored to your
//                   specific requirements.
//                 </p>
//               </Col>
//             </Row>
//           </Container>
       
//       </Container>
//     </div>
//   );
// }
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  BLOCKCHAINSERVICES_IMG2,
  VAPT,
} from "../../Assets";
import Table from "./table";
import { awsValueProposition, engagementModel } from "./config";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

export default function VATmain() {
  return (
    <div style={{ background: BACKGROUNDCOLOR,marginTop: "120px" }}>
      <Container style={{ marginTop: "120px" }}>
        <Row>
          <Col xs={12} md={8}>
            <h1 className="xlarge-font" style={{ color: "black", fontFamily: "impact" }}>
              <b>Dhruthzuci Tech - Cloud Security and Vulnerability Assessment: Tailored for Your Needs</b>
            </h1>
            <h1 className="large-font" style={{ color: "black", fontSize: "30px", fontFamily: "impact" }}>
              <b>Experience and Knowledge:</b>
            </h1>
            <p style={{ textAlign: "justify" }}>
              At Dhruthzuci, we offer comprehensive cloud security and vulnerability assessment services designed to ensure the robust protection of your digital assets. Our services are tailored to meet the unique needs of your organization, whether you operate in healthcare, finance, manufacturing, or any other industry. We understand that security is not one-size-fits-all, which is why we take a personalized approach to safeguarding your cloud infrastructure.
            </p>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col xs={12}>
              <h1 style={{ fontFamily: "impact", textAlign: "center" }}>
                <b>Who Can Benefit from Our Services?</b>
              </h1>
              <Table />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <img
                className="vpt"
                src={VAPT}
                alt="AWS Vulnerability and Penetration Testing"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12}>
              <p style={{ fontSize: "25px", fontFamily: "impact" }}>
                <b>AWS Value Proposition:</b>
              </p>
              <ul style={{ fontFamily: "impact" }}>
                {awsValueProposition.map((item, index) => (
                  <li key={index}>
                    <span style={{ fontSize: "larger" }}>
                      <u>{item.title}:</u>
                    </span>
                    <br /> {item.content}
                  </li>
                ))}
              </ul>
              <p style={{ fontSize: "25px", fontFamily: "impact" }}>
                <b>Engagement model</b>
              </p>
              <ul style={{ fontFamily: "impact" }}>
                {engagementModel.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
              <p style={{ fontFamily: "impact" }}>
                This engagement model emphasizes a structured, collaborative, and results-oriented approach to cloud security assessment, ensuring that your organization's security needs are met efficiently and effectively over a timeline tailored to your specific requirements.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
