import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import {
  FaDesktop,
  FaCloud,
  FaUserShield,
  FaExpand,
  FaDollarSign,
  FaWrench,
  FaUserTie,
  FaShieldAlt,
} from "react-icons/fa";
import { CloudlandingData } from "../Pages/DiGI/config";

const MWMPage = () => {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };
  const cardStyle = {
    borderRadius: "15px",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
  };
  return (
    <div>
      <section
        className="py-5"
        style={{
          backgroundImage: `url(${CloudlandingData.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Container>    
  <h1
      style={{
        ...centerAlignedText,
        fontSize: "50px",
        marginBottom: "300px",
        marginTop: "250px",
        color: "#fff",
      }}
    >
      {CloudlandingData.heroSection.title}
    </h1></Container> */}
      </section>
      <Container
        style={{ marginTop: "100px", fontFamily: "Arial, sans-serif" }}
      >
        {/* Header Section */}

        <Row className="mb-5 text-center d-flex flex-column align-items-center">
  <Col lg={8} className="mb-4">
    <h1 style={{ fontWeight: "bold", color: "#007bff" }}>
      Workspace Management Made Simple and Affordable
    </h1>
    <p
      className="lead"
      style={{ fontSize: "1.2rem", color: "#6c757d" }}
    >
      Empower your team with seamless, scalable workspace solutions—no
      costly enterprise licenses required.
    </p>
  </Col>

  <Col>
    <div className="d-flex gap-3 justify-content-center">
      <Button
        variant="primary"
        size="lg"
        style={{ fontFamily: "impacted", color: "#fff" }}
        onClick={() => (window.location.href = "/contactus")}
      >
        Try It Now
      </Button>

      <Button
        variant="primary"
        size="lg"
        style={{ fontFamily: "impacted", color: "#fff" }}
        onClick={() =>
          (window.location.href =
            "https://mwm-dev.dhruthzucitech.solutions/authentication/sign-in")
        }
      >
        Login
      </Button>
    </div>
  </Col>
</Row>


        {/* Solutions Section */}
        <Row className="mb-5 text-center">
          <Col>
            <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
              Solutions for Your Workspace Needs
            </h2>
            <p style={{ fontSize: "1rem", color: "#6c757d" }}>
              From effortless setup to scalable management, we've got you
              covered.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaDesktop size={40} className="mb-3 text-primary" />
                <Card.Title className="font-weight-bold">
                  Effortless Workspace Setup
                </Card.Title>
                <Card.Text>
                  Our intuitive platform allows your team to set up, manage, and
                  optimize workspaces without specialized IT support or costly
                  licenses.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaCloud size={40} className="mb-3 text-success" />
                <Card.Title className="font-weight-bold">
                  Access Anywhere, Anytime
                </Card.Title>
                <Card.Text>
                  Secure, remote access to personalized virtual desktops. Work
                  seamlessly on-the-go from any device.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaUserShield size={40} className="mb-3 text-warning" />
                <Card.Title className="font-weight-bold">
                  Flexible User Controls
                </Card.Title>
                <Card.Text>
                  Admins create and manage workspaces effortlessly while users
                  maintain control, boosting productivity.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={3} className="mb-4">
            <Card className="shadow-sm border-0">
              <Card.Body>
                <FaExpand size={40} className="mb-3 text-danger" />
                <Card.Title className="font-weight-bold">
                  Scalable and Cost-Effective
                </Card.Title>
                <Card.Text>
                  Pay for what you need, when you need it. Transparent pricing
                  with no hidden fees.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Benefits Section */}
        <Container style={{ marginTop: "5rem" }}>
          <Row className="mb-5 text-center">
            <Col>
              <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
                Benefits from a Business Perspective
              </h2>
              <p style={{ fontSize: "1rem", color: "#6c757d" }}>
                Discover how Dhruthzuci transforms workspace management for your
                business.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaDollarSign size={40} className="mb-3 text-primary" />
                  <Card.Title className="font-weight-bold">
                    Optimize Operations Without the Cost
                  </Card.Title>
                  <Card.Text>
                    Enjoy functionality without financial burden, saving money
                    while enhancing efficiency.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaWrench size={40} className="mb-3 text-success" />
                  <Card.Title className="font-weight-bold">
                    Reduce IT Overhead
                  </Card.Title>
                  <Card.Text>
                    Simplified management portal minimizes IT intervention,
                    allowing focus on strategic initiatives.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaUserTie size={40} className="mb-3 text-warning" />
                  <Card.Title className="font-weight-bold">
                    Empower Your Workforce
                  </Card.Title>
                  <Card.Text>
                    Enable faster project delivery and improved collaboration
                    with modern workspace solutions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={3} className="mb-4">
              <Card className="shadow-sm border-0">
                <Card.Body>
                  <FaShieldAlt size={40} className="mb-3 text-danger" />
                  <Card.Title className="font-weight-bold">
                    Secure and Compliant
                  </Card.Title>
                  <Card.Text>
                    Enterprise-level security with integration via Microsoft
                    Entra for user authentication and data protection.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* User Stories Section */}
        <Row className="mb-5 text-center">
          <Col>
            <h2 className="fw-bold">User Stories & Testimonials</h2>
            <p className="text-muted">
              Hear from our happy users about their experiences with Dhruthzuci.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Title>End-User Experience</Card.Title>
                <Card.Text>
                  “With Dhruthzuci’s workspace solution, I can easily access my
                  work environment from home or on-site without relying on IT
                  support. It’s a game-changer for our team’s flexibility.” —
                  Alex, Project Manager at SmartSolutions Co.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="mb-4">
            <Card style={cardStyle}>
              <Card.Body>
                <Card.Title>Business Perspective</Card.Title>
                <Card.Text>
                  “Switching to this solution allowed us to reduce operational
                  costs by 40% and remove the complexity of managing enterprise
                  licenses. Our team is now more agile and productive.” — Sarah,
                  Operations Head at GreenTech Corp.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Call-to-Action Section */}
        <Row className="mt-5 text-center">
          <Col>
            <h2 style={{ fontWeight: "bold", color: "#343a40" }}>
              Empowering Businesses of All Sizes
            </h2>
            <p style={{ fontSize: "1rem", color: "#6c757d" }}>
              Scalable, cost-effective workspace management for startups and
              established companies alike.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="shadow-sm border-0">
              <Card.Body className="text-center">
                <Card.Title style={{ fontWeight: "bold", color: "#007bff" }}>
                  Ready to Transform Your Workspace?
                </Card.Title>
                <Card.Text>
                  Submit your details to revolutionize how you manage your
                  workspaces.
                </Card.Text>
                <Button variant="primary" size="lg" block>
                  <a
                    style={{ fontFamily: "impacted", color: "#fff" }}
                    href="/contactus"
                  >
                    Get Started
                  </a>
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>{" "}
    </div>
  );
};

export default MWMPage;
