import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./about.css";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
import { linkedin } from "../../Assets";
import BlogSelect from "./BlogSelect";

const CustomCard = ({ text, color, title, backgroundColor }) => {
  const cardStyle = {
    height: "100%",
    background: backgroundColor,

  };

  return (
    <Card
      bg={color}
      className="card-hover mb-3 mb-md-3 mb-lg-3"
      style={cardStyle}
    >
      <Card.Body className="text-center px-md-3 px-lg-3 my-2">
        <div className="card-icon-border-large border-danger mtn-40">
          <h4
            className="text-danger"
            style={{
              textAlign: "center",
              fontFamily: "impacted",
              marginTop: "10px",
            }}
          >
            {title}
          </h4>
        </div>
        <p
          className="font-normal mb-3 mb-md-3 mb-lg-3"
          style={{
            textAlign: "justify",
            fontFamily: "impacted",
            marginTop: "50px",
          }}
        >
          {text}
        </p>
      </Card.Body>
    </Card>
  );
};

const About = () => {
  return (
    <div
      style={{
        background: BACKGROUNDCOLOR,
      }}
    >
      <Container>
        <h1
          data-aos="fade-up"
          style={{
            fontFamily: "impact",
            textAlign: "center",
            marginBottom: "5%",
            marginTop: "130px",
          }}
        >
          About Us
        </h1>
        <Row className="align-items-center d-flux">
          <Col lg={6} md={12} data-aos="fade-right">
            <div className="image-container text-center">
              <img
                src={require("../../Assets/images/about-us1.png")}
                alt=""
                className="img-fluid"
                style={{ maxWidth: "60%", margin: "0 auto" }}
              />
            </div>
          </Col>
          <Col lg={6} md={12} className="pt-3" data-aos="fade-up">
            <div className="icon-box">
              <i className="bx bx-receipt"></i>
              <p style={{ fontFamily: "impacted", textAlign: "justify" }}>
                <b style={{ fontSize: "18px" }}>"</b>
                <b style={{ fontFamily: "impacted" }}>
                  Dhruthzuci Tech Solutions focuses on building Information
                  Technology & Infrastructure Services. We provide the best IT
                  infrastructure consulting and software solutions. We are a
                  multi-functional IT infrastructure consulting and software
                  solutions development company headquartered in Bengaluru,
                  India. The company specializes in strategy and IT Consulting,
                  cloud adoption, cloud migration, data monetization, DevSecOps,
                  Cloud training, Blockchain, and Cyber Security.
                </b>
                <b style={{ fontSize: "18px" }}>"</b>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center pt-3 justify-content-center">
          <Col lg={12} md={12} data-aos="fade-right">
            <div className="image-container text-center">
              <div style={{ maxWidth: "100%", marginTop: "40px" }}>
                <h2>Who Are We?..</h2>
                <p
                  style={{
                    textAlign: "justify",
                    marginTop: "20px",
                    fontFamily: "impacted",
                  }}
                >
                  "
                  <b>
                    A Hybrid cloud & transformation consulting and service
                    provider. Our staff is comprised of industry veterans with
                    hands-on experience with cloud transformations from the
                    inside. Our Cloud Advisory Services start by understanding
                    your business goals and then move forward to define a
                    strategy to embrace cloud. This will typically start with a
                    cloud readiness assessment focused on the people, processes,
                    and workloads under consideration."
                  </b>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "120px" }}>
          {" "}
          {/* Center the cards */}
          <Col sm={10} md={5} className="mb-3 mb-sm-0 text-center">
            <CustomCard
              text="Our mission is to consistently exceed our customer’s expectations, providing them with world-class reliable technology solutions while constantly improving our products and services. We aim to be the partner of choice for small and medium-size businesses and government bodies by implementing innovative, secure, reliable, and cost-effective solutions."
              title="Our Mission"
              backgroundColor="#c8caf6" // Set the background color for this card
            />
          </Col>
          <Col sm={10} md={5} className="mb-3 mb-sm-0 text-center">
            <CustomCard
              text="We will remain a professionally managed, quality-focused organization committed to excellent customer satisfaction. We shall incorporate a culture of care, trust, and continuous learning while meeting the expectations of employees and society at large.                                                                                                    "
              title="Our Vision"
              backgroundColor="#afcbfc" // Set the background color for this card
            />
          </Col>
        </Row>
        <br/>
        <br/>
        <br/>
        <Row className="align-items-center justify-content-center">
          <Col lg={12} md={12} data-aos="fade-right">
            <div className="image-container text-center">
              <img
                src={require("../../Assets/images/roadmap1.png")}
                className="img-fluid"
                alt=""
                style={{ maxWidth: "60%", margin: "0 auto" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div
        data-aos="fade-up"
        style={{
          fontFamily: "impact",
          textAlign: "center", // Center-align the content
          marginTop: "20px",
        }}
      >
        <h2>Begin your cloud journey with Us</h2>
        <a
          href="/CareerForm"
          className="join-link"
          style={{ alignContent: "center" }}
        >
          <img
            src={require("../../Assets/images/join.png")}
            style={{ maxWidth: "60%", height: "auto" }}
            alt="Join"
          />
        </a>
      </div>
    </div>
  );
};
export default About;