import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { Container, Row, Col } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
function CloudAdvisory() {
  return (
    <div style={{ background: BACKGROUNDCOLOR, overflowX: "hidden" }}>
      <div style={{ overflowX: "hidden" }}>
        <Container style={{ marginTop: "160px" }}>
          <Row>
            <Col md={8} className="column-66">
              <h1
                className="xlarge-font"
                style={{ color: "black", fontFamily: "impacted" }}
              >
                <b>
                  Dhruthzuci Tech - Your Trusted Partner for Cloud Advisory
                  Services
                </b>
              </h1>
              <br />
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                Your one-stop source for skilled Cloud Advisory services. We
                acknowledge that leveraging the potential of the cloud may be a
                complicated path full of strategic decisions and concerns.
                That's why we're here to walk you through the cloud adoption
                process, guiding you through the challenges and allowing you to
                realize the full potential of cloud computing for your business.
              </p>
            </Col>
            <Col md={4} className="column-33">
              <img
                src={require("../../Assets/images/consulting.png")}
                alt=""
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1
                className="large-font"
                style={{
                  color: "black",
                  fontSize: "30px",
                  fontFamily: "impacted",
                }}
              >
                <b>
                  Boost the Potential of Your Business with Our Cloud Advisory
                  Services
                </b>
              </h1>
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "24px" }}></span>
                <p>
                  With our comprehensive Cloud Advisory services, businesses can
                  realize the full potential of the cloud. We specialize in
                  designing bespoke cloud strategies that connect exactly with
                  your company's strategic goals and conquer your unique
                  challenges, whether you're new to the cloud or already
                  cloud-native. Our services cover critical components of cloud
                  strategy, such as:{" "}
                </p>
              </p>
              <ol type="1">
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Unleash Agility</u>:&nbsp;
                  </span>
                  Make use of the cloud's unprecedented agility to achieve rapid
                  application development, seamless deployment, and easy
                  scalability of your apps and services. Stay ahead of the
                  competition by addressing the company's dynamic demands with
                  ease of use.
                </li>
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Productivity</u>:&nbsp;
                  </span>
                  Our experts will assist you in leveraging cloud technologies
                  and applications to boost productivity, collaboration, and
                  creativity within your organization, allowing your staff to
                  work more strategically and efficiently. to work smarter and
                  more efficiently.
                </li>
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Flexibility</u>:&nbsp;               
                  </span>
                  We consider your company requirements and advise on the best
                  cloud platform and infrastructure options that will allow you
                  to adapt and expand with your changing needs.
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <Container>
          <Row>
            <Col>
              <h1
                className="large-font"
                style={{
                  color: "black",
                  fontSize: "30px",
                  fontFamily: "impacted",
                }}
              >
                <b>Solution Design</b>
              </h1>
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                Our team collaborates closely with you to design comprehensive
                cloud solutions tailored to your specific needs. We consider all
                layers of the solution stack, including applications, platforms,
                and infrastructure, ensuring a seamless and optimized cloud
                architecture.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1
                className="large-font"
                style={{
                  color: "black",
                  fontSize: "30px",
                  fontFamily: "impacted",
                }}
              >
                <b>Cloud Adoption Strategy</b>
              </h1>
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                Embarking on a successful cloud adoption journey requires a
                well-defined strategy. We provide guidance on key elements of
                your cloud adoption strategy, including:
              </p>
              <ol type="1">
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Technology</u>:&nbsp;
                  </span>
                  We help you assess and choose the right cloud technologies,
                  tools, and services that align with your business goals,
                  enabling you to leverage the cloud's capabilities to their
                  fullest extent.
                </li>
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Process</u>:&nbsp;
                  </span>
                  We analyze your existing processes and workflows, identifying
                  areas for improvement and providing recommendations on how to
                  optimize and streamline them within the cloud environment.
                </li>
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Organization</u>:&nbsp;
                  </span>
                  We guide you in structuring your organization to embrace cloud
                  computing, ensuring that roles, responsibilities, and skill
                  sets are aligned to support a successful cloud transformation.
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <Container>
          <Row>
            {/* <Col md={4}>
              <img
                src={require('../../Assets/images/cloud-adoption.webp')}
                alt=""
                style={{ maxWidth: "100%", borderRadius: "10%" ,fontFamily:"impacted"}}
              />
            </Col> */}
            <Col className=" pt-2 pt-lg-0">
              <h1
                className="large-font"
                style={{
                  color: "black",
                  fontSize: "30px",
                  fontFamily: "impacted",
                }}
              >
                <b>Review of Architecture</b>
              </h1>
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                Our Cloud Advisory services include a thorough architecture
                evaluation to guarantee that your cloud infrastructure is
                optimized for operational excellence, security, compliance, and
                cost effectiveness. We address critical issues such as:
              </p>
              <ol type="1">
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Operational Excellence</u>:&nbsp;
                  </span>
                  We assess your cloud infrastructure and make recommendations
                  to improve operational efficiency, scalability, and
                  resilience, allowing you to meet your business goals.
                </li>
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Security & Compliance</u>:&nbsp;
                  </span>
                  We assess your current procedures and workflows, identifying
                  areas for improvement and making advice on how to optimize and
                  streamline them in the cloud environment.
                </li>
                <li style={{ textAlign: "justify", fontFamily: "impacted" }}>
                  <span style={{ fontSize: "larger" }}>
                    <u>Cost and performance optimization</u>:&nbsp;
                  </span>
                  We help you structure your business to adopt cloud computing
                  by ensuring that roles, responsibilities, and skill sets are
                  matched to support a successful cloud transformation.
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ overflowX: "hidden" }}>
        <Container>
          <Row>
            <Col md={8}>
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                You can get a competitive advantage by working with us and
                aligning your cloud strategy with your company objectives.
              </p>
              <ol
                type="i"
                style={{ textAlign: "justify", fontFamily: "impacted" }}
              >
                <li>
                  <span style={{ fontSize: "larger", fontFamily: "impacted" }}>
                    <u></u>
                  </span>
                  Leverage cloud-native technology to increase agility and
                  efficiency, allowing for faster reaction to market needs.
                </li>
                <li>
                  <span style={{ fontSize: "larger", fontFamily: "impacted" }}>
                    <u></u>
                  </span>
                  Gain flexibility by utilizing scalable cloud resources that
                  can adapt to changing business needs.
                </li>
                <li>
                  <span style={{ fontSize: "larger", fontFamily: "impacted" }}>
                    <u></u>
                  </span>
                  Create a thorough adoption strategy that takes into account
                  technology, procedures, and organizational factors.
                </li>
                <li>
                  <span style={{ fontSize: "larger" }}>
                    <u></u>
                  </span>
                  Optimize your cloud infrastructure to ensure operational
                  excellence, security, compliance, and cost savings.
                </li>
                <li>
                  <span style={{ fontSize: "larger" }}>
                    <u></u>
                  </span>
                  Put in place strong security and compliance frameworks to
                  protect your data and meet legal standards.
                </li>
                <li>
                  <span style={{ fontSize: "larger" }}>
                    <u></u>
                  </span>
                  Increase performance and cost efficiency by optimizing
                  performance and tracking resource utilization.
                </li>
              </ol>
              {/* Additional content */}
            </Col>
            <Col md={4}>
              <img
                src={require("../../Assets/images/cloud-structure.png")}
                alt=""
                style={{ maxWidth: "100%", borderRadius: "5%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                Dhruthzuci Tech is dedicated to assisting our clients in
                harnessing the power of the cloud and achieving their business
                objectives. With our Cloud Advisory services, you can
                confidently negotiate the complexity of cloud computing, knowing
                that you have a reliable partner on your side.
              </p>
              <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <b>
                    <a href="/contactus">Contact us today</a>
                  </b>
                </span>
                &nbsp;to learn more about how our Cloud Advisory services may help
                your company thrive in the digital age. We can unlock the actual
                potential of cloud computing and catapult your firm to new
                heights of success if we work together.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="footer"></div>
    </div>
  );
}

export default CloudAdvisory;
