
import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { VAPT1, VAPT2, VAPT3, VAPT4, VAPT5, VAPT6, VAPT7, VAPT_HERO } from "./config";
import Table from "./table";
import { awsValueProposition, engagementModel } from "./config";
import FormCom from "../../Components/Form/Form";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

export default function VAPTnew() {

  const targetRef = useRef(null);

  const handleButtonClick = () => {
    const ContainerOffset = targetRef.current.offsetTop - 120;
    window.scrollTo({
      top: ContainerOffset,
      behavior: "smooth",
    });

  };
  return (
    <div
      style={{
        background: BACKGROUNDCOLOR,
        overflowX: "hidden",
        marginTop: "80px",
      }}
    >
<section
        className="py-5 position-relative"
        style={{
          backgroundImage: `url(${VAPT_HERO})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="outline-light"
          size="lg"
          style={{
            opacity: 0.8,
            marginBottom: "20px",
            marginLeft: "300px",
            fontSize: "1.5rem", 
          }}
                    onClick={handleButtonClick}

        >
          Let's Get Started →
        </Button>
      </section>
      <Container ref={targetRef} id="scroll-target">
        <Row style={{marginBottom:"30px"}}>
          <Col md={12} style={{fontFamily: "impacted"}} ref={targetRef} id="scroll-target">
            <p>
              Vulnerability Assessment and Penetration Testing (VAPT) is a
              crucial cybersecurity practice that helps organizations identify
              and mitigate security weaknesses in their systems and applications
            </p>
          </Col>
          <Col md={4} xs={12} >
            <img src={VAPT1} alt="" style={{ width: "100%" }} />
          </Col>
          <Col md={4} xs={12} style={{fontFamily: "impacted"}}>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
              }}
            >
              <b>Key Benefits of VAPT:</b>
            </h1>
            <ul>
              <li>Identification of Vulnerabilities</li>
              <li>Risk Reduction</li>
              <li>Compliance</li>
              <li>Security Improvement</li>
              <li>Protection of Customer Trust</li>
              <li>Cost Savings</li>
              <li>Incident Prevention</li>
              <li>Continuous Monitoring</li>
              <li>Security Awareness</li>
              <li>Customization</li>
              <li>Third-Party Assessment</li>
            </ul>
          </Col>
          <Col md={3} xs={12}>
            <img src={VAPT2} alt="" style={{ width: "100%" }} />
          </Col>
        </Row>

        <section
          className="py-5"
          style={{
            backgroundImage: `url(${VAPT3})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            height: "35vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "60px"
          }}
        >
          <div style={{ textAlign: "justify", color: "white" }}></div>
        </section>

        <Row>
          <Col md={12}>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Why Would Your Organization Need VAPT?</b>
            </h1>
            <p style={{ fontFamily: "impacted",  }}>
              Vulnerabilities exist in all levels of a computing system
              on-premise and cloud regardless of the organization's size.
              There's a big misconception that small and medium-sized businesses
              are spared by cyber attackers. But this is far from reality. As
              the security of small businesses is usually relaxed, attackers
              incline towards them. Many times, organizations say they don't
              require vulnerability risk assessments because they're such a
              small organization. But this false belief could prove very costly
              for a business, big or small – SME or MNC.
            </p>
          </Col>
          <Row style={{marginTop:"50px"}}>
          <Col md={7} xs={12} style={{fontFamily: "impacted"}}>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
              }}
            >
              <b>Who Can Get Benefited?</b>
            </h1>
            <ul>
              <li>Organizations and Businesses</li>
              <li>IT Security Teams</li>
              <li>Software Developers</li>
              <li>Government Agencies</li>
              <li>Service Providers</li>
              <li>Individuals</li>
              <li>Compliance and Regulatory Bodies</li>
              <li>Risk Management Teams</li>
              <li>Ethical Hackers and Security Researchers</li>
              <li>Consultants and Security Firms</li>
              <li>Educational Institutions</li>
              <li>Nonprofit Organizations</li>
            </ul>
          </Col>
          <Col md={5} xs={12}>
            <img src={VAPT4} alt="" style={{ width: "100%" }} />
          </Col>
          </Row>
        </Row>

        <Col md={12} style={{fontFamily: "impacted", marginTop:"30px"}}>
          <h1
            className="large-font"
            style={{ color: "black", fontSize: "30px"}}
          >
            <b>Engagement Model</b>
          </h1>
          <ul>
            <li>
              A phased approach ensures a thorough and systematic assessment of
              your cloud security.
            </li>
            <li>
              Flexible timeline (6 to 12 weeks) allows for customization based
              on the complexity of your cloud environment.
            </li>
            <li>
              Collaboration and communication throughout the engagement keep you
              informed and involved in the process.
            </li>
            <li>
              Actionable recommendations provide a clear path to strengthening
              your cloud security posture.
            </li>
            <li>
              Ongoing support options are available to maintain and enhance your
              security over time.
            </li>
          </ul>
        </Col>
        <Row style={{ marginTop: "50px", marginBottom: "50px" }}>
  <Col md={12} xs={12} style={{ display: "flex", alignItems: "center", fontFamily: "impacted" }}>
    <Col md={7} xs={12}>
      <h1 className="large-font" style={{ color: "black", fontSize: "30px" }}>
        <b>VAPT Services</b>
      </h1>
      <p>
        The broad definition of VAPT means the various services it describes are often confused and used interchangeably. Before commissioning any form of VAPT security testing, organisations should be aware of the services an assessment could include.
      </p>
    </Col>
    <Col md={5} xs={12}>
      <img src={VAPT7} alt="" style={{ width: "60%", marginLeft:"50px" }} />
    </Col>
  </Col>
</Row>

       
        <Col md={12}>
          <p style={{ fontFamily: "impacted", textAlign: "justify"}}>
            At Dhruthzuci, we offer comprehensive cloud security and
            vulnerability assessment services designed to ensure the robust
            protection of your digital assets. Our services are tailored to meet
            the unique needs of your organization, whether you operate in
            healthcare, finance, manufacturing, or any other industry. We
            understand that security is not one-size-fits-all, which is why we
            take a personalized approach to safeguarding your cloud
            infrastructure.
          </p>
        </Col>

        <Col xs={12} style={{ padding: "20px 0" }}>
          <img src={VAPT5} alt="" style={{ width: "100%" }} />
        </Col>

        <Col xs={12} style={{ padding: "20px 0" }}>
          <img src={VAPT6} alt="" style={{ width: "100%" }} />
        </Col>
      </Container>

      
    </div>
  );
}