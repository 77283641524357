import React, { useState } from "react";
import { Button, Card, Container, Row, Col, Image, Accordion } from "react-bootstrap";
import EventsHero from "../../Components/EventsHero/EventsHero";

export default function FitnessEvent() {
  const [showMore, setShowMore] = useState(false);

  const handleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div style={{ marginTop: "0" }}>
      <EventsHero />
      <Row>
        <Col md={9} style={{ marginTop: "200px", marginBottom: "200px", marginLeft: "0", marginRight: "0" }}>
          <h1
            style={{
              fontFamily: "impacted",
              fontSize: "40px",
              marginBottom: "50px",
              marginLeft: "200px", // Adjust the left margin as needed
            }}
          >
            <u>Fitness</u>
          </h1>
        </Col>
      </Row>


    </div>
  );
}