import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {

  IAC1,
  IAC2,
  IAC3,
  IAC4,
  IAC_ARCHI,
  IaC_Data,
  IAC_DESIGN,
  IAC_DISASTER,
  IAC_INTRO,
  IAC_LAST,
  IAC_OPTIMIZED,
  IAC_PATH,
  IAC_TECHNICAL,
} from "./config";
import "./Iac.css"

export default function IaC() {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };

  return (
    <div
      style={{
        background: "#fff",
        overflowX: "hidden",
        marginTop: "78px",
      }}
    >

      <section
        className="py-5"
        style={{
          backgroundImage: `url(${IaC_Data.heroSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "50px",
            marginBottom: "50px",
            marginTop: "450px",
            color: "#fff",
          }}
        >
          {IaC_Data.heroSection.title}
        </h1>
      </section>

      <Container>
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "35px",
            marginBottom: "50px",
            marginTop: "20px",
            fontFamily: "impact",
          }}
        >
          {IaC_Data.introductionSection.title}
        </h1>
<Row className="align-items-center justify-content-center">
  <Col>
    <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
      {IaC_Data.introductionSection.description}
    </p>

    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "30px",
      }}
    >
      <img src={IAC_INTRO} alt="" style={{ width: "60%", height: "auto" }} />
    </div>
  </Col>
</Row>

      </Container>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>{IaC_Data.targetCustomersSection.title}</b>
            </h1>
          </Col>

          <Row className="align-items-center justify-content-center">

 
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Embark on a transformative journey with us to explore the vast potential of AWS CloudFormation. Bid farewell to manual resource configuration and embrace the elegance of IaC. Whether you're a startup streamlining AWS operations or an enterprise maximizing efficiency, AWS CloudFormation is your solution. 
              </p>
          </Row>

          <Col md={12} style={centerAlignedText}>
          <h1><b>Your Path to Success</b></h1>  
          </Col>

          <Row className="align-items-center justify-content-center">

            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={IAC_PATH} alt="" style={{ width: "70%" }} />
            </Col>
                      <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Navigate through each project milestone, from initial assessment to final handover. Explore our comprehensive timeline and witness the seamless automation and security transformation of your AWS resources.
              </p>
            </Col>
        </Row>

          <Col md={12} style={centerAlignedText}>
            <h1><b>Technical Excellence</b></h1>
          </Col>

          <Row className="align-items-center justify-content-center">

            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" ,textAlign:"justify"}}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Experience the technical prowess of AWS CloudFormation. Our well-tailored templates defining the desired state of resources, explore version control systems, understand template modularity, and witness the magic of parameterization – ensuring your AWS resources align with your vision. 

              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={IAC_TECHNICAL} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>

          <Col md={12} style={centerAlignedText}>
            <h1><b>Optimized Resource Management</b></h1>
          </Col>
          <Row className="align-items-center justify-content-center" style={{marginBottom:"20px"}}>


             <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={IAC_OPTIMIZED} alt="" style={{ width: "70%" }} />
            </Col>            
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Efficiency and cost optimization are at the heart of this project. Explore continuous monitoring, automated scaling, and resource cleanup practices to ensure you make the most of your AWS investments. 
              </p>
            </Col>
            </Row>
          <Col md={12} style={centerAlignedText}>
            <h1><b>Disaster Recovery</b></h1>
          </Col>
          <Row className="align-items-center justify-content-center" style={{marginBottom:"20px"}}>

            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px",textAlign:"justify" }}
            >
              <p style={{ textAlign: "justify", fontFamily: "impacted", fontSize: "16px"  }}>
              Learn how we infuse your infrastructure with disaster recovery mechanisms to enhance resilience. Your AWS environment will be prepared to tackle unexpected disruptions while ensuring uninterrupted business operations. 
              </p>
            </Col>      
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={IAC_DISASTER} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>
        </Row>
      </Container>
      <Container>
      <Col className=" pt-2 pt-lg-0">
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Unleash the Power of AWS CloudFormation </b>
             
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              A well-designed cloud technical architecture is fundamental to
              unlocking the true potential of cloud computing. Our Cloud
              Technical Architecture services encompass:
            </p>
            <ul
              type="1"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            >
              <li>
                <u>Streamlined Infrastructure Mastery</u>: Empower your business with the agility and efficiency of AWS CloudFormation. Our service offers dynamic templates and cutting-edge solutions, redefining how you build, deploy, and manage your AWS infrastructure.{" "}
              </li>
              <li>
                <u>Explore AWS CloudFormation Templates</u>: Revolutionize your AWS journey with our IaC (Infrastructure as Code) templates. Craft an environment that is not just robust but also effortlessly reproducible. Your vision, now in code.
              </li>
              <li>
                <u>Architect Your Success</u>: Visualize the future with our reference architecture diagram. It's more than a blueprint—it's a visual symphony of AWS services, harmonizing for optimal performance. 
              </li>
            </ul>
          </Col>
          </Container>



          <Container>
      <Col className=" pt-2 pt-lg-0">
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Transformative Use Cases </b>
             
            </h1>

            <ul
              type="1"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            >
              <li>
                <u>Mastering IaC Excellence:</u>: Leverage AWS CloudFormation for consistent and reproducible infrastructure.
              </li>
              <li>
                <u>Application Stacks Mastery</u>: Craft and manage application stacks seamlessly, including EC2 instances, RDS databases, S3 buckets, and more.
              </li>
              <li>
                <u>High Availability Design</u>:Create architectures with unparalleled elegance—incorporate auto-scaling, load balancing, and fault-tolerant configurations. 
              </li>
              <li>
                <u>Resource Optimization</u>:Turn resource management into a cost-saving strategy. Optimize AWS spending, scale resources dynamically, and control your budget. 
              </li>
              <li>
                <u>Governance Excellence</u>:Enforce governance policies, ensuring a standardized and secure infrastructure. 
              </li>
            </ul>
          </Col>
          </Container>
          <div className="ml-auto" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
    <img src={IAC_ARCHI} alt="" style={{ width: "68%", height: "70vh" , marginBottom:"30px"}} />
  </div>
          
      <section
        // className="py-5"
        style={{
          backgroundImage: `url(${IaC_Data.customerEngagementSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "37vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Container>
          <Col
            // md={9}
            style={{
              color: "white",
              fontFamily: "impacted",
              textAlign: "center",
            }}
          >
            <h1 className="large-font" style={{fontSize:"26px"}}>
              <b>{IaC_Data.customerEngagementSection.title}</b>
            </h1>
            <br/>
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                marginTop:"15px",
                textAlign: "justify"
              }}
            >
              <b>{IaC_Data.customerEngagementSection.description}</b>
            </p>
          </Col>
        <p
        style={{
          color: "white",
          fontFamily: "impacted",
          marginTop:"15px",
          textAlign: "justify"
        }}>
        <b>Efficiency and Precision:</b> Swiftly deploy and manage AWS resources, bringing unparalleled efficiency to your operations. <br/>

<b>Consistency and Reliability:</b> Weave consistency into your AWS environment, eliminating the risk of configuration errors. <br/>

<b>Scalability and Control:</b> Easily scale your infrastructure up or down, maintaining control over your resources. <br/>

<b>Cost-Saving Strategies:</b> Optimize AWS spending with resource management, automated provisioning, and strategic service usage. <br/>
        </p>

        </Container>
      </section>

      <Container
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          fontFamily: "impacted",
        }}
      >
        <Row style={{marginBottom:"50px"}}>
          <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{IaC_Data.deliveryMechanismsSection.title}</b>
          </h1>
        </Col>
        <Col>
          <p> {IaC_Data.deliveryMechanismsSection.description}</p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "30px"
            }}
          >
            <img src={IAC_DESIGN} alt="" style={{ width: "70%", height: "auto" }}/>
          </div>
        </Col></Row>


<Container style={{
            textAlign: "center",
            }}>
  <Row style={{marginTop: "10px", marginBottom: "30px"}}>
  <Col md="6">
<img src={IAC1} alt=""  style={{ width: "80%", height: "auto" }}/>
</Col>
<Col md="6" className="d-flex justify-content-end" >
<div className="ml-auto">
    <img src={IAC2} alt="" style={{ width: "80%", height: "auto" }} />
  </div>
</Col>
</Row >

<Row style={{ marginBottom: "30px"}}>
  <Col md="6">
<img src={IAC3} alt=""  style={{ width: "80%", height: "auto" }}/>
</Col>

<Col md="6" className="d-flex justify-content-end">
<div className="ml-auto">
    <img src={IAC4} alt="" style={{ width: "80%", height: "auto", marginBottom: "50px"
 }} />
  </div>
</Col>
</Row>

<Row style={{ marginBottom: "30px"}}>
<Col  className="d-flex justify-content-end">
<div className="ml-auto" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "30px",
      }}>
    <img src={IAC_LAST} alt="" style={{ width: "70%", height: "auto" }} />
  </div>
</Col>
</Row>
</Container>

        <Row style={{marginBottom:"50px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{IaC_Data.UnlockPotentialSection.title}</b>
            </h1>
          </Col>
          <Col>
            <p> {IaC_Data.UnlockPotentialSection.description}</p>
          </Col>
        </Row>

        <Row style={{marginBottom:"50px"}}>
          <Col
            md={12}
            style={{
              marginBottom: "20px",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1>
              <b>{IaC_Data.WelcomeToFutureSection.title}</b>
            </h1>
          </Col>
          <Col>
            <p> {IaC_Data.WelcomeToFutureSection.description}</p>
          </Col>
        </Row>

<Row style={{marginBottom:"50px"}}>
  <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{IaC_Data.getStartedSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12}>

          {IaC_Data.getStartedSection.line1}<a
            style={{ fontFamily: "impacted", fontSize: "23px" }}
            href={IaC_Data.getStartedSection.contactUsLink}
          >
            <b>Contact us</b>
          </a>{" "}
          {IaC_Data.getStartedSection.line2}
        </Col>
  </Row>

      </Container>
    </div>
  );
}