import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
import { CareersData } from "../../Config/JDconfig";
import "./careers.css";
import { useNavigate } from "react-router-dom";
import { AWSCLOUD_IMG, AZURE_IMG, GCP_IMG, E2E_IMG } from "../../Assets";
import { AWS_API_KEY } from "../../Config/config";

const Careers = () => {
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(
      "https://938m9p4nqk.execute-api.ap-south-1.amazonaws.com/prod/get?type=get",
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": AWS_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const responseData = JSON.parse(data["body-json"].body);
        if (Array.isArray(responseData)) {
          setCandidates(responseData);
        } else {
          throw new Error("Invalid data format");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  const handleLoginClick = (job) => {
    navigate("/JobDescription", {
      state: {
        role: job.role,
        JobOverview: job.JobOverview,
        RolesAndResponsibilities: job.RolesAndResponsibilities,
        SkillsAndQualifications: job.SkillsAndQualifications,
        EmploymentType: job.EmploymentType,
        WhatWeOffer: job.WhatWeOffer,
      },
    });
  };
  

  return (
    <div
      style={{
        background: BACKGROUNDCOLOR,
      }}
    >
      <Container style={{ marginTop: "120px" }}>
        <div
          className="section-title"
          data-aos="fade-up"
          style={{
            textAlign: "center",
            padding: "10px",
            borderRadius: "20px",
          }}
        >
          <h1
            style={{
              fontFamily: "impact",
              margin: "0",
            }}
          >
            Careers
          </h1>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Row>
            <Col
              lg={12}
              style={{
                textAlign: "center",
                paddingBottom: "20px",
                fontFamily: "impacted",
              }}
            >
              <h3 style={{ color: "black" }}>
                “Be a part of a team where your ideas don’t just get heard—they
                get built”
              </h3>
            </Col>
          </Row>

          {/* Career Cards */}
          <Row>
            {candidates.length > 0 &&
              candidates.map((solution, index) => (
                <Col lg={6} key={index} className="mb-4">
                  <Card
                    className="h-100 career-card"
                    style={{
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                      borderRadius: "10px",
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      border: "1px solid #ccc",
                      padding: "10px",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Card.Header
                      align="center"
                      style={{
                        fontFamily: "impact",
                        color: "#0C1A4A",
                        fontSize: "18px",
                        background: "transparent",
                        borderBottom: "1px solid #ddd",
                        marginBottom: "10px",
                      }}
                    >
                      {solution.role}
                    </Card.Header>
                    <Card.Body>
                      <p
                        className="card-text"
                        style={{ fontFamily: "impacted", color: "#555" }}
                      >
                        {solution.JobOverview}
                      </p>
                    </Card.Body>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ padding: "10px" }}
                    >
                      <button
                        className="rounded apply-button"
                        onClick={() => handleLoginClick(solution)}
                        style={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          border: "none",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        Apply Now
                      </button>
                    </div>
                  </Card>
                </Col>
              ))}
          </Row>

          {/* Trusted Cloud Platforms */}
          <div className="container">
            <div
              className="item"
              style={{
                paddingLeft: "6%",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <br />
              <br />
              <br />
              <h1 style={{ fontSize: "25px", fontFamily: "impact" }}>
                Our Trusted Cloud Platforms:
              </h1>
              <img src={AWSCLOUD_IMG} width="17%" id="awsimg" alt="AWS" />
              <img
                src={AZURE_IMG}
                width="17%"
                style={{ paddingRight: "1%" }}
                id="awsimg"
                alt="Azure"
              />
              <img src={GCP_IMG} width="17%" id="awsimg" alt="GCP" />
              <img src={E2E_IMG} width="17%" id="awsimg" alt="E2E Cloud" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Careers;
