import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./Components/Navbar/Navbar";
import { Routes, Route, Router } from "react-router-dom";
import Services from "./Components/Tabs/Services";
import Solutions from "./Components/Tabs/Solutions";
import About from "./Components/Tabs/About";
import Contact from "./Components/Tabs/Contact";
// import Events from "./Components/Tabs/Events/Events";
import Footer from "./Components/Tabs/footer";
import CloudAdvisory from "./Pages/CloudAdvisory/cloudadvisory";
import BlockChain from "./Pages/BlockChain/Blockchain";
import FullStack from "./Pages/FullStackDevlopment/Fullstack";
import ManagedServices from "./Pages/ManagedServices/Managedservices";
import MicroSoft from "./Pages/MSAutomation/MsAutomation";
import ProfessionalServices from "./Pages/Professional Services/ProfessionalServices";
import "./App.css";
import { FormCom } from "./Components/Form/Form";
import Blogs from "./Components/Tabs/blogs";
import VAT from "./Pages/VAT/VAT";
import { Blogpost } from "./Pages/Blog/blogpost";
import { CloudMigration } from "./Pages/Blog/Cloudmigration";
import { ChatBot } from "./Pages/Blog/Chatbot";
import { Office365 } from "./Pages/Blog/office";
import VATmain from "./Pages/VAT/VATmain";
import Home from "./Components/Tabs/Home/Home";
import { call, linkedin, mail, whatsapp } from "./Assets";
import VAPTnew from "./Pages/VAT/VAPT";
import BlogSelect from "./Components/Tabs/BlogSelect";
import { VaptBlog } from "./Pages/Blog/blogpost";
import Passwordless from "./Pages/PasswordlessAuth/PasswordlessAuth";
// import OTPLogin from "./Components/FitnessLogin/FitnessLogin";
import CloudLanding from "./Pages/CloudLanding/CloudLanding";
import DiGI from "./Pages/DiGI/DiGI";
import IaC from "./Pages/Iac/Iac";
import FitnessEvent from "./Pages/FitnessEvent/FitnessEvent";
import Amplify from "./Pages/Amplify/Amplify";
import PrivacyPolicyPage from "./Pages/Privacypolicy/PrivacyPolicyPage";
import Astuto from "./Pages/Amplify/Astuto/Astuto";
import AccountAudit from "./Pages/AccountAudit/AccountAudit";
import CareerForm from "./Pages/Careers/CareerForm";
import Careers from "./Pages/Careers/careers";
import JobRolePage from "./Pages/Careers/JobDecsription";
import ThankYouPage from "./Pages/Careers/ThnankYouPage";
import JobDescriptionCloudEngineer from "./Pages/Careers/CloudEngineer";
import AdminPanel from "./Pages/Admin/Admin";
import JobStatusPage from "./Pages/Careers/ViewStatus";
import Products from "./Components/Tabs/products";
import MWMPage from "./products/mwm";
import SmbWorkspace from "./products/smb-mwm";


function App() {
  return (
    <div className=" home d-flex flex-column min-vh-100">
      <NavBar />
      {/*
       <Footer/> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contactus" element={<Contact />} />
        {/* <Route path="/events" element={<Events />} /> */}
        <Route path="/cloudAdvisory" element={<CloudAdvisory />} />
        <Route path="/blockChain" element={<BlockChain />} />
        <Route path="/fullStack" element={<FullStack />} />
        <Route path="/managed" element={<ManagedServices />} />
        <Route path="/microsoft" element={<MicroSoft />} />
        <Route
          path="/professionalServices"
          element={<ProfessionalServices />}
        />
        {/* <Route path="/ContactForm" element={<FormCom />} /> */}
        <Route path="/blogs/vapt" element={<VaptBlog />} />
        <Route path="/vat" element={<VATmain />} />
        <Route path="/VAPT" element={<VAPTnew />} />
        <Route path="/blogs/cm" element={<CloudMigration />} />
        <Route path="/blogs/cb" element={<ChatBot />} />
        <Route path="/blogs/office" element={<Office365 />} />
        <Route path="/blogs" element={<BlogSelect />} />
        <Route path="/passwordless" element={<Passwordless />} />
        <Route path="/AccountAudit" element={<AccountAudit />} />
        <Route path="/iac" element={<IaC />} />

        
        <Route path="/products" element={<Products/>} />
        <Route path="/MWMPage" element={<MWMPage/>} />
        <Route path="/SmbWorkspace" element={<SmbWorkspace/>} />
        
        {/* events page */}
        {/* <Route path="/Events" element={<Events />} /> */}
        <Route path="/fitness-event" element={<FitnessEvent />} />
        <Route path="/CareerForm" element={<CareerForm />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/view-status" element={<JobStatusPage />} />
        <Route path="/JobDescription" element={<JobRolePage />} />
        {/* <Route path="/JobDescription/CloudEngineer" element={<JobDescriptionCloudEngineer />} /> */}
        {/* <Route path="/JobDescription/CloudAssociate" element={<JobRolePage />} />
        <Route path="/JobDescription/Designer" element={<JobRolePage />} />
        <Route path="/JobDescription/IntOrEmp" element={<JobRolePage />} /> */}
        <Route path="/thankyou" element={<ThankYouPage />} />
        {/* <Route path="/OTPLogin" element={<OTPLogin />} /> */}
        <Route path="/cloudlanding" element={<CloudLanding />} />
        <Route path="/DiGI" element={<DiGI />} />
        <Route path="/Finops" element={<Astuto />} />
        <Route path="/Amplify" element={<Amplify />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/Admin" element={<AdminPanel />} />

      </Routes>{" "}
      <Footer />
      {/* <div
        style={{
          position: "fixed",
          bottom: "180px",
          right: "10px",
          background: "linear-gradient(to right, #0C1A4A, #000000)",
          padding: "10px",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          transform: "translateY(-50%)",
        }}
      >
        <a
          href="https://www.linkedin.com/company/druthzuchi-tech-solutions/mycompany/"
          style={{ padding: "10px 0" }}
        >
          <img src={linkedin} alt="LinkedIn" style={{ width: "40px" }} />
        </a>
        {/* <a
          href="https://api.whatsapp.com/send?phone=91829637349"
          style={{ padding: "10px 0" }}
        >
          <img src={whatsapp} alt="WhatsApp" style={{ width: "40px" }} />
        </a> */}

        {/* <a href="mailto:info@eshatechgroup.com" style={{padding: "10px 0"}}>
          <img src={mail} alt="Mail" style={{ width: "40px" }} />
        </a>
      </div> */} 
    </div>
  );
}
export default App;
