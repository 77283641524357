export const ACCOUNTAUDIT_HERO = require("../../Assets/images/featured-image-3.jpg");
export const DBLUE_RECTANGLE = require("../../Assets/images/Rectangle1.png");
export const LBLUE_RECTANGLE = require("../../Assets/images/Rectangle2.png");

export const PLACEHOLDER = require("../../Assets/images/placeholder.png");

export const CENTRALIZED_AUDITING = require("../../Assets/images/AWS_OU_Policy.png");
export const AUTOMATED_EFFICIENCY = require("../../Assets/images/automated-efficiency.png");
export const SCALABILITY_FLEXIBILITY = require("../../Assets/images/aws-multi-account.png");
export const RESOURCE_AUDITING = require("../../Assets/images/Resource-auditing.jpg");
export const DETAILED_REPORTING = require("../../Assets/images/detailed-reporting.png");
export const SEAMLESS_INTEGRATION = require("../../Assets/images/seamless-integration.png");
export const REALTIME_NOTIFICATIONS = require("../../Assets/images/realtime-notifications.png");
export const AWS_SERVICES = require("../../Assets/images/aws-services.png");

export const CloudlandingData = {
  heroSection: {
    title: "Automated AWS Account Auditing for Efficiency and Compliance",
    backgroundImage: ACCOUNTAUDIT_HERO,
  },
  introductionSection: {
    title:
      "Overview",
    description:
      "Managing multiple AWS accounts can be complex and time-consuming. Our Account Audit solution simplifies this process, providing an automated and comprehensive auditing tool for organizations. With our solution, you can ensure efficient, secure, and scalable resource management, maintaining compliance and optimizing performance across your AWS environment.",
  },


  targetCustomersSection: {
    title: "Why Choose Our Account Audit Solution?",
    // description:
    //   "Our solution is a perfect fit for a wide array of organizations, including:",
    targetCustomers: [
      {
        image: CENTRALIZED_AUDITING,
        text: [
          {
            title: "Centralized Auditing:",
            description:
              "Our solution offers a centralized approach to auditing multiple AWS accounts, ensuring you have a complete and consistent view of all your resources, configurations, creation dates, and usage statistics.",
          },
        ],
      },
      {
              text: [
          {
            title: "Automated Efficiency:",
            description: "Say goodbye to manual, error-prone audits. Our automated system streamlines the entire process, from resource detail collection to report generation, saving you time and reducing the risk of human error.",
          },
        ],
        image: AUTOMATED_EFFICIENCY,

      },
      {
        image: SCALABILITY_FLEXIBILITY,
        text: [
          {
            title: "Scalability and Flexibility:",
            description:
              "Designed to grow with your organization, our solution scales effortlessly to accommodate any number of AWS accounts, ensuring you remain compliant and secure as your cloud infrastructure expands.",
          },
        ],
      },

    ],
  },
  customerEngagementSection: {
    title: "How It Works:",
    description:
      "Our Account Audit Engine operates from a dedicated management account, auditing multiple AWS accounts simultaneously. It collects resource details, processes the data, and stores it in DynamoDB tables. The information is then consolidated into an Excel sheet and stored in an S3 bucket, with a download link provided via email.",


    backgroundImage: DBLUE_RECTANGLE,
  },
  deliveryMechanismsSection: {
    title: "The Team behind Digital Grain Identifier ",
    description: "Smooth Transition and Ongoing Support. Rest assured, we are with you every step of the way. Our transition plan, training, and post-implementation support guarantee a seamless shift to your enhanced AWS environment.",
    // image: CLOUDLANDING6,

  },
  AWSValueProposition: {
    title: "AWS Value Proposition",
    description: "Experience the unparalleled advantages of integrating with Amazon Web Services (AWS) for your project. Achieve cost-effectiveness through flexible cost management, resource optimization, and enhanced efficiency. Ensure seamless scalability to meet evolving demands, supporting future growth and maintaining optimal system performance. Benefit from AWS's reliable and redundant infrastructure, minimizing downtime and ensuring uninterrupted system access. Prioritize data security with AWS's secure environment, adhering to industry standards. Simplify deployment and maintenance processes, enhancing overall system manageability. Elevate your project with AWS and unlock a new level of performance and efficiency",

  },

  WhoCanBenefit: {
    title: "Who Can Benefit",
    description: "Discover our advanced web-based system revolutionizing rice variety identification through image classification. Ideal for agricultural researchers, seed producers, farmers, extension services, technology providers, biodiversity conservation organizations, and educational institutions. Our solution offers precise and efficient solutions for rice cultivation, breeding, and crop diversity management. Experience tools tailored for expedited research, seed quality assurance, empowered crop management, educational support, and technological innovation. Uncover the significance of rice variety identification and the application of machine learning in agriculture with our practical teaching tool. Elevate your practices and contributions to the agricultural industry with our state-of-the-art system.",

  },

  ServicesUtilized : {
    title: " Services Utilized ",
    description: " Explore our Customer Engagement and Delivery Mechanisms, focusing on an innovative web-based system for automated rice variety identification. Leveraging a robust machine learning model, a comprehensive rice seed image dataset, and AWS services ensures unparalleled accuracy and efficiency. With a foundation built on diverse data and optimized deep learning, our user-friendly interface guarantees a seamless experience. Error handling mechanisms enhance user satisfaction. The integration with AWS for backend processing ensures optimal system performance. Elevate your engagement with cutting-edge technology for precise rice variety classification.",

  },

  MilestonesAndFeedback: {
    title: "Milestones and Feedback",
    description: "Our project follows a structured process. Pre-Engagement Assessment ensures thorough understanding, and Milestone-Defined Checkpoints keep the project on track. Post-Engagement Assessment evaluates outcomes, performance, and lessons learned.",

  },

  CostOptimization: {
    title: "Cost-Optimization Strategies",
    line1: "We employ professional cost-optimization strategies, including leveraging cost-effective AWS pricing options and efficient resource management. ",
    line2: "Unlock the Potential of Rice Variety Identification.",
    line3: "Join us in the future of agriculture with Digital Grain Identifier. Embrace efficiency, precision, and automation in your rice farming, research, and seed production endeavours. With AI/ML-driven rice image classification, you have the power to make informed decisions, enhance productivity, and elevate the quality and quantity of your rice production.",
  },

  getStartedSection: {
    title: "Get Started Today",
    description:
      "Transform your AWS resource management with our Account Audit solution. Experience the benefits of automated, scalable, and secure auditing.",
    contactUsLink: "/contactus",
  },
};