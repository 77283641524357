// import React from "react";
import {
  Container,
  Row,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { KARTHIK_IMG, MOULI_IMG, SUHAS_IMG, VAPT, CM_IMG } from "../../Assets";
import { CSA, CM } from "../../BlogPosts/CONFIG.js";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

export const CloudMigration = () => {
  const contentStyle = {
    fontFamily: "impacted",
    textAlign:"justify",
  };

  return (
    <div style={{ background: BACKGROUNDCOLOR }}>
      <Container className="pb-5" style={{ marginTop: "120px" ,fontFamily:"impacted"}}>
        <header
          className="w3-container w3-center"
          style={{ padding: "0px 10px", top: "20", fontFamily: "impact" }}
          id="home"
        >
          <h1 className="w3-jumbo" style={{ color: "#55ACEE", textAlign:"center", fontFamily: "impacted"}}>
            {CM.title}
          </h1>
        </header>
        <Row>
          <Col >
            <article>
              <div className="post-content">
           
                
                <Container
                  className="w3-content w3-justify w3-text-grey w3-padding-32"
                  id="about"
                >
                  
                

                  <Container fluid id="portfolio">
                    <Row>
                      <Col><p style={contentStyle}>
                    Cloud migration refers to the process of moving
                    applications, data, and IT resources from an on-premises
                    environment or another cloud platform to a cloud computing
                    infrastructure. It involves transferring workloads and
                    systems to a cloud-based environment, which typically offers
                    greater scalability, flexibility, and cost efficiency
                    compared to traditional on-premises infrastructure.
                  </p>
                       
                        <p style={{ fontSize: "25px", ...contentStyle }}>
                          <b>Benefits of Cloud Migration:</b>
                        </p>
                        <ol type="1">
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Scalability</u>: 
                            </span>
                            <br /> Cloud platforms offer the ability to scale
                            resources up or down based on demand, allowing
                            businesses to easily accommodate growth or sudden
                            spikes in traffic.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Cost Efficiency</u>:
                            </span>
                            <br /> Cloud migration can lead to cost savings by
                            eliminating the need for on-premises hardware,
                            reducing maintenance costs, and paying only for the
                            resources used.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Flexibility and Agility</u>:
                            </span>
                            <br /> Cloud environments provide greater
                            flexibility in deploying and managing applications,
                            allowing for faster development cycles and quicker
                            time-to-market.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Reliability and Resilience</u>:
                            </span>
                            <br /> Cloud providers typically offer built-in
                            redundancy, data backup, and disaster recovery
                            options, improving the overall reliability and
                            resilience of the IT infrastructure.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Security</u>:
                            </span>
                            <br /> Cloud providers invest heavily in security
                            measures, including encryption, access controls, and
                            regular audits, providing enhanced security features
                            compared to many on-premises environments.
                          </li>
                        </ol>
                        {/* <p style={{ fontSize: "25px" }}>
                          <b>VAPT on Cloud Infrastructure:</b>
                        </p> */}
                        <p style={contentStyle}>
                          Cloud migration requires careful planning, assessment,
                          and execution to ensure a successful transition. It
                          involves evaluating the existing infrastructure,
                          identifying the right cloud platform and services,
                          developing a migration strategy, and implementing the
                          necessary steps to transfer the applications, data,
                          and infrastructure to the cloud. It's important to
                          consider factors such as data security, compliance
                          requirements, application dependencies, and user
                          impact during the migration process.
                        </p>

                        <Row>
                          <Col md={12}>
                            <img
                              className="vpt"
                              src={CM_IMG}
                              style={{ width: "90%" }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>

                  <p style={{ fontSize: "25px", ...contentStyle }}>
                    <b>Different Stages of Migration Process we follow: </b>
                  </p>
                  <ol type="i">
                    <li style={contentStyle}>
                      Initial Cloud Migration Vision clarification workshop
                    </li>
                    <li style={contentStyle}>Requirement Analysis and Required Planning</li>
                    <li style={contentStyle}>
                      Pre-Migration setups and checks and Migration Setups and
                      checks
                    </li>
                    <li style={contentStyle}>Migration Activities from on-prem to cloud</li>
                    <li style={contentStyle}>Manage the cloud enviorment after Migration</li>
                  </ol>
                  <p style={contentStyle}>
                    We follow different stages for the cloud migration service.
                    This helps us to plan and execute a smooth migration from a
                    on-prem enviornment to cloud and also a cloud to cloud
                    migration. A detail requirement analysis is done once we
                    have a project at our hand. Then detailed steps are prepared
                    for the end to end migration work flow. Once we have the
                    detailed plan and bluprint for migration defined we proceed
                    to execute the steps with a proper timeline and holistic
                    approach.
                  </p>

                  <p style={{ fontSize: "25px", ...contentStyle }}>
                    <b>
                      Post Cloud Migration Services, the services we provide
                      are:{" "}
                    </b>
                  </p>
                  <ol type="i">
                    <li style={contentStyle}>Cloud Infrastructure Services</li>
                    <li style={contentStyle}>Cloud Application Services</li>
                    <li style={contentStyle}>Encrypt data in-transit over VPN connections.</li>
                    <li style={contentStyle}>Cloud Software Services</li>
                    <li style={contentStyle}>Cloud Managed Services</li>
                  </ol>

                  <p style={{ fontSize: "25px", ...contentStyle }}>
                    <b>
                      Different facilities and services once Migration is done:
                    </b>
                  </p>
                  <ol type="i">
                    <li style={contentStyle}>Cloud Assesment Engine</li>
                    <li style={contentStyle}>Cloud Landing Zone</li>
                    <li style={contentStyle}>Cloud Governance Foundation</li>
                    <li style={contentStyle}>Cloud Migration Foundry</li>
                    <li style={contentStyle}>Cloud Financial Management</li>
                    <li style={contentStyle}>Cloud Network design and Connectivity</li>
                    <li style={contentStyle}>Identity Management and Access Control</li>
                    <li style={contentStyle}>Infrastructure as code</li>
                    <li style={contentStyle}>Vulnarability and threat Management</li>
                    <li style={contentStyle}>Disaster Recovery</li>
                    <li style={contentStyle}>Audit and Assessment</li>
                    <li style={contentStyle}>Service Onboarding</li>
                    <li style={contentStyle}>change management</li>
                    <li style={contentStyle}>patch management</li>
                  </ol>

                  <p style={{ fontSize: "25px", ...contentStyle }}>
                    <b>Multiple Cloud Platforms are supported</b>
                  </p>

                  <p style={{ textAlign: "justify", ...contentStyle }}>
                    We support multiple cloud platforms like AWS, Azure, Google
                    Cloud E2E and others. As we provide multiple cloud platform
                    migration we can choose the best services in each cloud
                    provider and plan ou migrations according to that. This
                    gives us a benifit to provide the best cloud services to our
                    customers with a best price and service.
                  </p>
                </Container>

   
               
              </div>
            </article>
          </Col>

       
        </Row>
      </Container>
    </div>
  );
};
