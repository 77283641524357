import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  PASSWORDLESS2,
  PASSWORDLESS3,
  PASSWORDLESS4,
  PASSWORDLESS5,
  PWD_ARCHI,
  solutionsData,
  PASSWORDLESS_CUSTOMER
} from "./config";
import "./PasswordlessAuth.css";
export default function Passwordless() {
  const centerAlignedText = {
    textAlign: "center",
    fontFamily: "impacted",
    fontSize: "18px",
  };
  const backgroundImageStyle = {
    background: `url(${solutionsData.heroSection.backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",  
    height: "100vh", 
  };

  return (
    <div
      style={{
        overflowX: "hidden",
        marginTop: "100px",
      }}
    >
      <section
        className="py-5"
        style={{
          ...backgroundImageStyle,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "50px",
            marginBottom: "50px",
            marginTop: "470px",
            color: "#fff",
          }}
        >
          {solutionsData.heroSection.title}
        </h1>
      </section>

      <Container>
        <h1
          style={{
            ...centerAlignedText,
            fontSize: "35px",
            marginBottom: "50px",
            marginTop: "20px",
            fontFamily: "impacted",
          }}
        >
         <b> {solutionsData.introductionSection.title}</b>
        </h1>
        <Row className="align-items-center justify-content-center">
          <Col md={6} xs={12}>
            <h1
              className="large-font"
              style={{  textAlign:"center", fontFamily:"impacted" }}
            >
              <b>Introduction</b>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              {solutionsData.introductionSection.description}
            </p>
          </Col>
          <Col md={6} xs={12}>
            <img
              src={solutionsData.introductionSection.image}
              alt=""
              style={{ width: "100%", marginBottom: "20px" }}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={12} style={{marginTop: "10px"}}>
          <h1
              className="large-font"
              style={{  textAlign:"center" , fontFamily: "impacted"}}
            >
              <b>{solutionsData.serviceDetailsSection.title}</b>
            </h1>
            <p style={{ fontFamily: "impacted" }}>
              {solutionsData.serviceDetailsSection.description}
            </p>
          </Col>
          <Col md={12} xs={12}>
            <ul style={{ color: "black", fontFamily: "impacted" }}>
              {solutionsData.serviceDetailsSection.features.map(
                (useCase, index) => (
                  <li key={index}>
                    <b>{useCase.title}:</b> {useCase.description}
                  </li>
                )
              )}
            </ul>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col md={6} xs={12}>
            <img
              src={PWD_ARCHI}
              alt=""
              style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            />
          </Col>
        </Row>
      </Container>
      <section
        style={{
          backgroundImage: `url(${solutionsData.useCasesSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "30vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Col
            
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "impacted",
            }}
          >
            <h1
              className="large-font"
              style={{ fontSize: "26px" }}
            >
              <b>{solutionsData.useCasesSection.title}</b>
            </h1>
            <br />
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "left",
                marginTop:"15px"

              }}
            >
              <b>{solutionsData.useCasesSection.description}</b>
            </p>
            <ul
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "justify",
              }}
            >
              {solutionsData.useCasesSection.useCases.map((useCase, index) => (
                <li key={index}>
                  <b style={{ color: "#FF6700", textAlign: "center" }}>
                    
                    {useCase.title}:
                  </b>{" "}
                  <b>{useCase.description}</b>
                </li>
              ))}
            </ul>
          </Col>
        </Container>
      </section>

      <section
        style={{
          backgroundImage: `url(${solutionsData.awsValuePropositionSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col md={9} xs={8} style={{ fontFamily: "impacted" }}>
          <h1
            className="large-font"
            style={{ ...centerAlignedText, fontSize: "26px" , marginTop: "20px"}}
          >
            <b>{solutionsData.awsValuePropositionSection.title}</b>
          </h1>
          <p style={centerAlignedText}>
            {" "}
            {solutionsData.awsValuePropositionSection.description}
          </p>
          <Row className="align-items-center justify-content-center">
            {solutionsData.awsValuePropositionSection.features.map(
              (feature, index) => (
                <Col key={index} md={3} xs={9}>
                  {/* <div
                    style={{
                      position: "relative",
                      justifyContent: "space-between",
                    }}
                  > */}
                    <img src={feature.image} alt="" style={{ width: "100%" , height: "auto"}} />
                  {/* </div> */}
                </Col>
              )
            )}
          </Row>
        </Col>
      </section>

      <Container>
        <Row
          className="align-items-center justify-content-center"
          style={{ marginTop: "40px", ...centerAlignedText }}
        >
         <Row> 
          <Col md={12} style={centerAlignedText}>
            <h1 className="large-font">
              <b>{solutionsData.targetCustomersSection.title}</b>
            </h1>
          </Col>
          <Col md={12} xs={12} style={{ textAlign: "left" }}>
            <b>Target Customers</b>
            <br />

            <p>{solutionsData.targetCustomersSection.description}</p>
          </Col>

          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={PASSWORDLESS2} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <p style={{ textAlign: "center", fontFamily: "impacted" }}>
                <b>Startups and Small Businesses:</b> Secure and user-friendly
                authentication for smaller enterprises.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center ">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px", textAlign:"center" }}
            >
              <p>
                <b>Enterprises:</b> Robust security measures for large
                organizations. 
              </p>
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={PASSWORDLESS3} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={PASSWORDLESS4} alt="" style={{ width: "70%" }} />
            </Col>
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px", textAlign:"center" }}
            >
              <p>
                <b>User-Centric Applications:</b> Ideal for apps where user
                experience and security are paramount. 
              </p>
            </Col>
          </Row>

          <Row className="align-items-center justify-content-center">
            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px", textAlign:"center" }}
            >
              <p>
                <b>Scalable Applications:</b> Designed to accommodate
                applications with growing user bases. 
              </p>
            </Col>

            <Col
              md={6}
              xs={12}
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <img src={PASSWORDLESS5} alt="" style={{ width: "70%" }} />
            </Col>
          </Row>
        </Row>
      </Container>

      <Container>

        <Col className="text-center">
        <h2
          style={{
            fontFamily:"impacted",
            fontSize: "45px",
            marginBottom: "50px",
            marginTop: "50px",
            color: "black",
          }}
        >
<b>How We Craft Your Passwordless Authentication Experience: </b> </h2>
      <img src={PASSWORDLESS_CUSTOMER} alt="" style={{width: "70%" }} />
      </Col>
    </Container>


      {/* <section
        style={{
          backgroundImage: `url(${solutionsData.customerEngagementSection.backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          height: "35vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Container>
          <Col
            // md={9}
            style={{
              color: "white",
              fontFamily: "impacted",
              textAlign: "center",
            }}
          >
            <h1 className="large-font" style={{fontSize:"26px"}}>
              <b>{solutionsData.customerEngagementSection.title}</b>
            </h1>
            <br />
            
            <p
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "left",
                marginTop:"15px"
              }}
            >
              <b>{solutionsData.customerEngagementSection.description}</b>
            </p>


            <ul
              style={{
                color: "white",
                fontFamily: "impacted",
                textAlign: "justify",
              }}
            >
              {solutionsData.customerEngagementSection.features.map(
                (useCase, index) => (
                  <li key={index}>
                    <b style={{ color: "#FF6700" }}>{useCase.title}:</b>{" "}
                    <b>{useCase.description}</b>
                  </li>
                )
              )}
            </ul>
          </Col>
        </Container>
      </section> */}

      <Container
        style={{
          marginTop: "80px",
          marginBottom: "50px",
          fontFamily: "impacted",
        }}
      >
        <Col
          md={12}
          style={{
            marginBottom: "20px",
            textAlign: "center",
            fontFamily: "impacted",
          }}
        >
          <h1>
            <b>{solutionsData.deliveryMechanismsSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12} >
          <b>{solutionsData.deliveryMechanismsSection.description}</b>
          <ul style={{ fontFamily: "impacted" }}>
            {solutionsData.deliveryMechanismsSection.deliveryApproaches.map(
              (approach, index) => (
                <li key={index}>
                  <b>{approach.title}:</b> {approach.description}
                </li>
              )
            )}
          </ul>
        </Col>

        <Col
          md={12}
          style={{
        
            textAlign: "center",
            fontFamily: "impacted",
            marginTop:"60px"
          }}
        >
          <h1>
            <b>{solutionsData.getStartedSection.title}</b>
          </h1>
        </Col>
        <Col md={12} xs={12}>
          {solutionsData.getStartedSection.line1}&nbsp;
          <a
            style={{ fontFamily: "impacted", fontSize: "23px" }}
            href={solutionsData.getStartedSection.contactUsLink}
          >
            <b>Contact us</b>
          </a>
          &nbsp;{solutionsData.getStartedSection.line2}
        </Col>
      </Container>
    </div>
  );
}