import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import './css/style.css'; // Make sure to adjust the path to your custom styles
import { Container, Row, Col } from "react-bootstrap";

function ManagedServices() {
  return (
    <div style={{ backgroundColor: "#f4f9f8", overflowX: "hidden" }}>
      <div id="header"></div>
      <br />
      <br />
      <Container style={{ marginTop: "160px" }}>
        <Row>
          <Col md={8}>
            <h1 className="xlarge-font" style={{ color: "black" }}>
              <b>
                Managed Services by Dhruthzuci Tech: Unlocking the Full
                Potential of Cloud Management
              </b>
            </h1>
            <br />
            <p>
              Your trusted provider of comprehensive Managed Services for the
              cloud. We specialize in end-to-end cloud management, offering a
              range of services designed to optimize your IT infrastructure,
              ensure seamless operations, and drive business growth. With our
              expertise in cloud management, we are here to meet all your
              organization's needs.
            </p>
          </Col>
          <Col md={4}>
            <img
              src="image/managed_services.jpg"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px" }}
            >
              <b>Cloud Managed Services</b>
            </h1>
            <p style={{ textAlign: "justify" }}>
              <span style={{ fontSize: "24px" }}></span>Our Cloud Managed
              Services cover every aspect of cloud management, providing you
              with a complete solution for a smooth and efficient cloud
              operation. We handle all the complexities, allowing you to focus
              on your core business. Our services include:
            </p>
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col md={4}>
            <img
              src="image/ms.webp"
              alt=""
              style={{
                maxWidth: "100%",
                borderRadius: "10%",
                marginTop: "12%",
              }}
            />
          </Col>
          <Col md={8}>
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px" }}
            >
              <b>Infrastructure Managed Services</b>
            </h1>
            <p>
              Our Infrastructure Managed Services cover the essential components
              of your cloud infrastructure, including:
            </p>
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{ color: "black", fontSize: "30px" }}
            >
              <b>Application Managed Services</b>
            </h1>
            <p style={{ textAlign: "justify" }}>
              We provide comprehensive Application Managed Services to ensure
              the smooth operation and performance of your cloud-based
              applications. Our services include:
            </p>
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col md={8}>
            <img
              src="image/man-ser.avif"
              alt=""
              style={{
                maxWidth: "100%",
                borderRadius: "10%",
                marginTop: "22%",
              }}
            />
          </Col>
          <Col md={4}>
            <p>
              <span style={{  }}>
                <b>
                  <a href="contact.html">Contact us today</a>{" "}
                </b>
              </span>
            </p>
          </Col>
        </Row>
      </Container>
      <div id="footer"></div>
    </div>
  );
}

export default ManagedServices;
