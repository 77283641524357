export const EVENTS_BACKGROUND = require("../../../Assets/images/EventsHero.png");
//BUTTONS
export const EVENTS_CONFERENCE = require("../../../Assets/images/events_conference.png");
export const EVENTS_FITNESS = require("../../../Assets/images/events_FIT.png");
export const HACKATHON_WORKSHOP = require("../../../Assets/images/events_hackathon.png");
export const EVENTS_HIRING = require("../../../Assets/images/events_hiring.png");
export const EVENTS_ENTERTAINMENT = require("../../../Assets/images/events_entertainment.png");
export const EVENTS_MEETUPS = require("../../../Assets/images/events_meetup.png");

export const EVENTS_NEW = require("../../../Assets/images/events_new.png");
export const EVENTS_BFLYER = require("../../../Assets/images/events_Bigflyer.png");
export const EVENTS_SFLYER = require("../../../Assets/images/events_smallflyer.png");