import React from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { WM_IMG } from "../../Assets";
import { WM } from "../../BlogPosts/CONFIG.js";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

export const Office365 = () => {
  const contentStyle = {
    fontFamily: "impacted",
    textAlign:"justify",
  };

  return (
    <div style={{ background: BACKGROUNDCOLOR }}>
      <Container  style={{ marginTop: "120px" }}>
        <header
          className="w3-container w3-center"
          style={{ padding: "0px 10px", top: "20" }}
          id="home"
        >
          <h1 className="w3-jumbo" style={{ color: "#55ACEE",textAlign:"center", fontFamily: "impacted" }} >
            {WM.title}
          </h1>
        </header>
        <Row>
          <Col  className="mb-4">
            <article>
              <div className="post-content" style={contentStyle}>
               <Container className="w3-content w3-justify w3-text-grey w3-padding-32" id="about">
                  

                  <Container fluid id="portfolio">
                    <Row>
                      <Col>
                      <p>
                    At Dhruthzuci Tech, we understand that effective application development is crucial for your business success. That's why we leverage the power of Office 365 and its suite of powerful tools to create tailor-made solutions that meet your unique requirements.
                  </p>
                  <p>
                    By combining the capabilities of Office 365 with our expertise, we provide a single management platform that spans your enterprise, resulting in quick & easy implementation, Data Integration, Multiplatformity, Lower Costs, and consumption-based reporting for your end users.
                  </p>
                  <p>
                    Building apps at speed is part of our move to democratize the power of platforms. It extends our Microsoft teams' capabilities and experiences. By simplifying the experience using a single interactive dashboard across projects, delivery time has been reduced by 20%, team members have gained time back, and there’s been a 75% reduction in security-related incidents. It frees up time for our rare architects and developers to focus on more complex business solutions that drive strategic.
                  </p>
                        <p style={{ fontSize: "25px", ...contentStyle }}>
                          <b>A few applications that we are using in Office:</b>
                        </p>
                        <ul>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>SharePoint Online and Power Apps</u>:
                            </span>
                            <br /> With SharePoint Online and Power Apps, we provide you with a robust and collaborative platform for creating web-based applications. SharePoint Online acts as the foundation, allowing you to organize and store your data securely. By leveraging Power Apps, we enable you to design and deploy custom applications without extensive coding. You can build user-friendly interfaces, connect to various data sources, and streamline your business processes effortlessly.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Microsoft Dataverse</u>:
                            </span>
                            <br /> Microsoft Dataverse, formerly known as Common Data Service (CDS), is a cloud-based data storage and management platform provided by Microsoft. It serves as a secure and scalable repository for storing and organizing business data across various applications and services within the Microsoft Power Platform, such as Power Apps and Power Automate. With Dataverse, organizations can create custom data models, define relationships between entities, and build rich business applications using a low-code or no-code approach. It offers features like data validation, business rules, and data integration capabilities, making it easier to create unified and intelligent solutions. Dataverse also provides robust security and governance controls, ensuring data confidentiality and compliance with industry standards. Overall, Microsoft Dataverse empowers businesses to build data-centric applications and unlock the full potential of their data within the Microsoft ecosystem.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Power Automate</u>:
                            </span>
                            <br /> Our utilization of Power Automate takes your application development to the next level. With Power Automate, we automate repetitive tasks and processes across multiple systems, saving you valuable time and increasing operational efficiency. Workflows and approvals are streamlined, ensuring seamless collaboration between teams and eliminating bottlenecks.
                          </li>
                          <li style={contentStyle}>
                            <span style={{ fontSize: "larger" }}>
                              <u>Teams, MS Forms, and Planner</u>:
                            </span>
                            <br /> Collaboration lies at the heart of successful project management. That's why we leverage Microsoft Teams, MS Forms, and Planner as part of our application development strategy. Teams provides a centralized hub for communication and collaboration, allowing team members to work together seamlessly. You can integrate custom apps directly within Teams, ensuring a unified experience. MS Forms enables you to gather valuable feedback and data through interactive surveys and forms. Gain insights from your customers, employees, or partners to enhance your business processes further. Planner simplifies project management, enabling you to organize tasks, assign responsibilities, and track progress. With Planner, you can streamline project delivery and ensure nothing falls through the cracks.
                          </li>
                        </ul>

                        <Row>
                          <Col md={12}>
                            <img
                              className="vpt"
                              src={WM_IMG}
                              style={{ width: "90%" }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>

                  <p style={{ fontSize: "30px", ...contentStyle }}>
                    <b>Advantages of choosing Dhruthzuci Tech</b>
                  </p>

                  <p style={{ textAlign: "justify", ...contentStyle }}>
                    By choosing Dhruthzuci Tech and our Office 365-based application development approach, you can expect numerous benefits. You'll experience increased productivity, improved collaboration, streamlined processes, and enhanced decision-making through data-driven insights. We have a proven track record of successfully delivering Office 365-powered applications to our clients.
                  </p>
                </Container>
              </div>
            </article>
          </Col>

        </Row>
      </Container>
    </div>
  );
};
