import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { O365SERVICES_IMG2, O365SERVICES_IMG3 } from "../../Assets";

import { Container, Row, Col } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

function MicroSoft() {
  return (
    <div style={{ overflowX: "hidden", background: BACKGROUNDCOLOR }}>
      <div id="header"></div>
      <Container style={{ marginTop: "160px" }}>
        <Row>
          <Col md={8}>
            <h1
              className="xlarge-font"
              style={{ color: "black", fontFamily: "impacted" }}
            >
              <b>
                Seamless Microsoft Office 365 Integration with Dhruthzuci Tech
              </b>
            </h1>
            <br />
            <br />
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              Your reliable partner for Microsoft Office 365 integration
              services. We understand the importance of having a seamless and
              efficient productivity suite that empowers your business to
              collaborate, communicate, and thrive in the modern workplace. With
              our expertise in Microsoft Office 365, we help businesses unlock
              the full potential of this powerful platform.
            </p>
          </Col>
          <Col md={4}>
            <img src={O365SERVICES_IMG2} alt="" style={{ maxWidth: "100%" }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Microsoft Office 365 Integration Services</b>
            </h1>
            <p style={{ fontFamily: "impacted" }}>
              <span style={{ fontFamily: "impacted" }}></span>
              At Dhruthzuci Tech, we offer comprehensive integration services
              for Microsoft Office 365, ensuring that your organization
              maximizes the benefits of this robust suite of tools. Our services
              include:
            </p>
            <ol
              type="1"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            />

            <ol
              type="1"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            >
              <li style={{ fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Email and Calendar Integration</u>:
                </span>{" "}
                We seamlessly integrate your existing email and calendar systems
                with Microsoft Outlook and Exchange Online, providing a unified
                and synchronized experience across devices.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Data Migration</u>:
                </span>{" "}
                Our experts assist with migrating your data, including emails,
                contacts, and files, from legacy systems to Office 365, ensuring
                a smooth transition and minimal disruption to your business
                operations.
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Collaboration and Communication Tools</u>:
                </span>{" "}
                We enable seamless integration of Office 365 collaboration
                tools, such as Microsoft Teams and SharePoint, allowing your
                teams to collaborate, share files, and communicate effectively
                in real-time.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Document Management and Storage</u>:
                </span>{" "}
                We help you leverage the full capabilities of Office 365's
                document management and storage solutions, such as OneDrive and
                SharePoint, ensuring secure and efficient file storage, access,
                and sharing.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Cloud Productivity Applications</u>:
                </span>{" "}
                We integrate and configure the various productivity applications
                within Office 365, including Word, Excel, PowerPoint, and more,
                enabling your employees to work with familiar tools and enhance
                productivity.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Identity and Access Management</u>:
                </span>{" "}
                We implement robust identity and access management solutions,
                such as Azure Active Directory, to ensure secure authentication
                and authorization for Office 365 services, protecting your data
                and resources.{" "}
              </li>
              {/* Continue adding list items */}
              {/* Adjust content placement and styling as needed */}
            </ol>
          </Col>
        </Row>
        {/* Continue adding content */}
        {/* Adjust content placement and styling as needed */}
        <Row>
          <Col md={4}>
            <img
              src={O365SERVICES_IMG3}
              alt=""
              style={{
                maxWidth: "100%",
                float: "right",
                marginTop: "20%",
                borderRadius: "10%",
                fontFamily: "impacted",
                justifyContent:"center"
              }}
            />
          </Col>
          <Col md={8}>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>Benefits of Office 365 Integration </b>
            </h1>
            <p style={{ fontFamily: "impacted" }}>
              By leveraging our Office 365 integration services, your
              organization can enjoy numerous benefits, including:
            </p>
            <ol
              type="1"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            />

            <ol
              type="1"
              style={{ textAlign: "justify", fontFamily: "impacted" }}
            >
              <li style={{ fontFamily: "impacted" }}>
                <span style={{ fontSize: "larger" }}>
                  <u>Enhanced Productivity</u>:
                </span>{" "}
                Streamlined workflows, real-time collaboration, and access to a
                suite of powerful productivity tools empower your employees to
                work efficiently and deliver exceptional results.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Seamless Communication</u>:
                </span>{" "}
                With integrated email, calendar, and communication tools, your
                teams can stay connected, share information, and collaborate
                effortlessly, regardless of their location.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Scalability and Flexibility</u>:
                </span>{" "}
                Office 365 offers flexible licensing options, allowing you to
                scale your subscription as your business grows. Our integration
                services ensure a scalable and adaptable infrastructure that
                meets your evolving needs.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>ECentralized Data Management</u>:
                </span>{" "}
                With centralized document management and storage solutions, you
                can efficiently organize, secure, and access your critical
                business files, fostering collaboration and ensuring data
                integrity.{" "}
              </li>
              <li>
                <span style={{ fontSize: "larger" }}>
                  <u>Enhanced Security and Compliance</u>:
                </span>{" "}
                Office 365 provides robust security features and compliance
                capabilities. We ensure that your integration is configured to
                meet your specific security and compliance requirements,
                protecting your sensitive data.{" "}
              </li>
            </ol>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1
              className="large-font"
              style={{
                color: "black",
                fontSize: "30px",
                fontFamily: "impacted",
              }}
            >
              <b>
                Unlock the Power of Microsoft Office 365 with Dhruthzuci Tech
              </b>
            </h1>
            <p style={{ textAlign: "justify", fontFamily: "impacted" }}>
              Transform your workplace productivity and streamline your business
              operations with our Microsoft Office 365 integration services.
              <span style={{ fontSize: "larger" }}>
                <b>
                  <a
                    style={{
                      fontFamily: "impacted",
                     
                      fontFamily: "impacted",
                    }}
                    href="/contactus"
                  >
                    Contact us today
                  </a>{" "}
                </b>
              </span>{" "}
              to schedule a consultation and explore how our expertise can help
              you leverage the full potential of Office 365. Let us be your
              trusted partner in implementing a seamless and efficient Office
              365 solution that drives productivity, collaboration, and business
              success.{" "}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default MicroSoft;
